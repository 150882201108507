// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const customization = useSelector((state) => state.customization);
    const dispatch = useDispatch();
    // alert(JSON.stringify(customization?.userInfo));
    useEffect(() => {
        setUserState();
    }, []);
    const setUserState = async () => {
        // TODO setting user Info from local storage
        const userInfo = await localStorage.getItem('userInfo');
        const parsedUserInfo = JSON.parse(userInfo);
        dispatch({ type: 'userInfo', userInfo: { ...parsedUserInfo } });
    };
    const navItems = menuItem[customization?.userInfo?.accountType ?? 'items'].map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
