import React from 'react';
import { useEffect, useState } from 'react';

// material-ui
import { Button, FormControl, Grid, InputLabel, MenuItem, Modal, Rating, Select, TextField, Typography } from '@mui/material';

// project imports
import TripItemCard from 'ui-component/cards/TripItem';
import { Box } from '@mui/system';
import { useNavigate, useParams } from 'react-router';
import BookInfoItem from 'ui-component/cards/BookInfoItem';
import { DriverTripService } from 'services/driver/trip_service';
import { ToastContainer, toast } from 'react-toastify';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: 4,
    boxShadow: 24,
    p: 4
};
const StartTrip = ({ data, handleClose }) => {
    const [starting, setStarting] = useState(false);
    const filteredSeats = data?.seats.filter((v) => v.status != 'available');
    const startTrip = async () => {
        if (filteredSeats.length < 1) {
            toast.error('You can start trip with 0 passengers');
            return;
        }
        setStarting(true);
        const strt = await DriverTripService.startTrip({
            schedule: data?._id,
            seats: filteredSeats.map((v) => v._id)
        });
        setStarting(false);
        if (!strt.success) {
            toast.error(strt.message);
            return;
        }
        toast.success(strt.message);
        handleClose();
    };
    return (
        <div>
            <ToastContainer />
            <Box sx={style}>
                <Typography id="keep-mounted-modal-title" variant="h3" component="h2" align="center">
                    Are you sure you want to start trip with {data?.seats.filter((v) => v.status != 'available').length} Seats Booked?
                </Typography>
                <Grid my={5} container spacing={2}>
                    <Grid item xs={6} align={'center'}>
                        <Button
                            style={{ borderRadius: 20 }}
                            disableElevation
                            disabled={starting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="secondary"
                            onClick={startTrip}
                        >
                            {starting ? 'Starting Trip' : 'Start'}
                        </Button>
                    </Grid>
                    <Grid item xs={6} align={'center'}>
                        <Button
                            style={{ borderRadius: 20 }}
                            disableElevation
                            // disabled={isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="error"
                            onClick={handleClose}
                        >
                            No
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

export default StartTrip;
