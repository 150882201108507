import React from 'react';
import { useState, useRef } from 'react';
import './drag_drop.css';
import uploadIcon from '../../assets/images/upload_icon.svg';

const DragDrop = ({ onChange }) => {
    const [dragActive, setDragActive] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    // ref
    const inputRef = useRef(null);

    // handle drag events
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            // handleFiles(e.dataTransfer.files);
            let d = { target: e.dataTransfer };
            setSelectedFile(d);
            return onChange(d);
        }
    };

    // triggers when file is selected with click
    const handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            // handleFiles(e.target.files);
        }
        setSelectedFile(e);
        return onChange(e);
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };

    return (
        // <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
        <div id="form-file-upload" onDragEnter={handleDrag}>
            <input ref={inputRef} type="file" id="input-file-upload" multiple={false} onChange={handleChange} />
            <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? 'drag-active' : ''}>
                <div>
                    <p>
                        <div>
                            <img src={uploadIcon} height={80} alt="" />
                        </div>
                        <b>{selectedFile ? selectedFile.target.files[0]?.name : 'Drag and drop your file here or'}</b>
                    </p>
                    <button className="upload-button" onClick={onButtonClick}>
                        <b>
                            <font color="blue">Upload a file</font>
                        </b>
                    </button>
                </div>
            </label>
            {dragActive && (
                <div
                    id="drag-file-element"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                ></div>
            )}
        </div>
    );
};

export default DragDrop;
