import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Divider, Grid, Stack, TextField, Typography, useMediaQuery } from '@mui/material';

// project imports
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Configs } from 'constants/configs';
import { AuthService } from 'services/auth_service';

// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const VerifyIdentity = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const search = window.location.search;
    const query = new URLSearchParams(search);
    const redirect = query.get('redirect');
    const [verifiData, setVerifyData] = useState({
        vnin: '',
        dateOfBirth: '' //??
    });
    const [submitting, setSubmitting] = useState(false);

    const handleVerify = async () => {
        if (verifiData.nin == '' || verifiData.dateOfBirth == '') {
            toast.error('Fields cannot be empty');
            return;
        }
        setSubmitting(true);
        const verResponse = await AuthService.verifyIdentity({ ...verifiData, dateOfBirth: Configs.dateConvert(verifiData.dateOfBirth) });
        setSubmitting(false);
        if (!verResponse.success) {
            toast.error(verResponse.message);
            return;
        }
        toast.success(verResponse.message);

        navigate(redirect ? '/' + redirect : '/set-profile-pic');
    };
    return (
        <div>
            <ToastContainer />
            <Grid item xs={12} md={12}>
                <Grid
                    direction="column"
                    container
                    spacing={2}
                    alignItems="center"
                    style={{ height: '100vh' }}
                    justifyContent="center"
                    // sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}
                >
                    <Grid>
                        <Typography color={theme.palette.secondary.main} gutterBottom align="center" variant={matchDownSM ? 'h3' : 'h2'}>
                            Please enter your NIN
                        </Typography>
                        <Typography color={'gref'} gutterBottom align="center">
                            Generate your virtual Nin using your phone
                        </Typography>
                    </Grid>

                    <Grid>
                        <Grid container spacing={2}>
                            <Grid item md={6} mt={5}>
                                <Typography variant="caption" fontSize="12px" textAlign={matchDownSM ? 'center' : 'inherit'}>
                                    Identification Number (Virtual NIN)
                                </Typography>
                                <TextField
                                    type="number"
                                    fullWidth
                                    label=""
                                    id="fullWidth"
                                    value={verifiData?.nin}
                                    onChange={(e) => setVerifyData({ ...verifiData, vnin: `${e?.target?.value}` })}
                                />
                            </Grid>
                            <Grid item md={6} mt={5}>
                                <Typography variant="caption" fontSize="12px" textAlign={matchDownSM ? 'center' : 'inherit'}>
                                    Date of Birth
                                </Typography>
                                <TextField
                                    fullWidth
                                    label=""
                                    id="fullWidth"
                                    type="date"
                                    value={verifiData?.dateOfBirth}
                                    onChange={(e) => setVerifyData({ ...verifiData, dateOfBirth: e?.target?.value })}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid pt={5}>
                        <Button
                            style={{ borderRadius: 10, paddingRight: 100, paddingLeft: 100 }}
                            disableElevation
                            disabled={submitting}
                            onClick={handleVerify}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="secondary"
                        >
                            {submitting ? 'Loading' : 'Next'}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default VerifyIdentity;
