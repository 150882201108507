import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import VerifyAccount from 'views/pages/authentication/authentication3/VerifyAccount';
import VerifyIdentity from 'views/pages/authentication/authentication3/VerifyIdentity';
import SetProfilePic from 'views/pages/authentication/authentication3/SetProfilePic';
import OnboardSummary from 'views/pages/authentication/authentication3/OnboardSummary';
import SetPin from 'views/pages/authentication/authentication3/SetPin';
import SuccessRegistration from 'views/pages/authentication/authentication3/SuccessRegistration';
import AddDriversLicense from 'views/pages/authentication/authentication3/AddDriversLicense';
import SetCarDetails from 'views/pages/authentication/authentication3/SetCarDetails';
import UploadCarImage from 'views/pages/authentication/authentication3/UploadCarImage';
import AdminDashboard from 'views/admin/dashboard';
import Users from 'views/admin/users';
import SingleUser from 'views/admin/users/SingleUser';
import Admins from 'views/admin/admins';
import SingleAdmin from 'views/admin/admins/SingleAdmin';
import DriverRequests from 'views/admin/users/DriverRequests';
import GeneralSettings from 'views/admin/settings/GeneralSettings';
import ZoneSettings from 'views/admin/settings/ZoneSettings';
import SingleZone from 'views/admin/settings/SingleZone';
import SingleState from 'views/admin/settings/SingleState';
import Roles from 'views/admin/settings/Roles';
import Transactions from 'views/admin/transactions';
import Location from 'views/admin/settings/Location';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

// ==============================|| MAIN ROUTING ||============================== //
const prefix = 'admin';
const AdminRoutes = [
    {
        path: '',
        element: <AdminDashboard />,
        prefix
    },
    {
        path: 'dashboard',
        element: <AdminDashboard />,
        prefix
    },
    {
        path: 'users',
        element: <Users />,
        prefix
    },
    {
        path: 'drivers',
        element: <Users />,
        prefix
    },
    {
        path: 'user/:id',
        element: <SingleUser />,
        prefix
    },
    {
        path: 'driver/:id',
        element: <SingleUser />,
        prefix
    },
    {
        path: 'admins',
        element: <Admins />,
        prefix
    },
    {
        path: 'admin/:id',
        element: <SingleAdmin />,
        prefix
    },
    {
        path: 'driver-requests',
        element: <DriverRequests />,
        prefix
    },
    {
        path: 'general-settings',
        element: <GeneralSettings />,
        prefix
    },
    {
        path: 'location-settings',
        element: <ZoneSettings />,
        prefix
    },
    {
        path: 'zone/:id',
        element: <SingleZone />,
        prefix
    },
    {
        path: 'state/:id',
        element: <SingleState />,
        prefix
    },
    {
        path: 'roles-permissions',
        element: <Roles />,
        prefix
    },
    {
        path: 'transactions',
        element: <Transactions />,
        prefix
    },
    {
        path: 'locations',
        element: <Location />,
        prefix
    }
];

export default AdminRoutes;
