import { useEffect, useState } from 'react';

// material-ui
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import ArrowRightLogo from '../../../assets/images/icons/arrow_right.svg';
import TripItemCard from 'ui-component/cards/TripItem';
import DashBoardDetailComponent from '../component/DashBoardDetailComponent';
import { TravellerTripService } from 'services/traveller/trip_service';
import states from '../../../constants/state.json';

// ==============================|| DEFAULT USERDASHBOARD ||============================== //

const UserDashboard = () => {
    const [isLoading, setLoading] = useState(true);
    const sampleTrip = [
        {
            from: 'Kano',
            to: 'Kaduna',
            date: '12 Apr',
            time: '09:00AM',
            customer: 'Shehu Musa',
            rating: 5
        },
        {
            from: 'Jigawa',
            to: 'Kaduna',
            date: '22 Mar',
            time: '06:00AM',
            customer: 'IB Musa',
            rating: 3
        },
        {
            from: 'Kano',
            to: 'Adamawa',
            date: '16 Jan',
            time: '07:30AM',
            customer: 'Abdul Abba',
            rating: 5
        },
        {
            from: 'Jigawa',
            to: 'Gombe',
            date: '09 Feb',
            time: '09:00AM',
            customer: 'Musa Mamman',
            rating: 2
        }
    ];
    const [scheduledTrips, setScheduledTrips] = useState([]);
    const [params, setParams] = useState({ page: 1 });
    const [pagination, setPagination] = useState({
        page: 1,
        pages: 1,
        prev: 0,
        next: 0,
        total: 1
    });
    const [fetching, setFetchnfetching] = useState(false);
    useEffect(() => {
        setLoading(false);
        fetchTrips(params);
    }, []);

    const fetchTrips = async (params) => {
        setFetchnfetching(true);
        const tripResponse = await TravellerTripService.scheduled(params);
        setFetchnfetching(false);
        // alert(JSON.stringify(tripResponse));
        if (!tripResponse.success) {
            return;
        }
        setScheduledTrips(tripResponse.data?.schedules ?? []);
    };
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <DashBoardDetailComponent>
                    <Grid
                        container
                        spacing={gridSpacing}
                        paddingY={2}
                        style={{ minHeight: 200 }}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item lg={2} md={2} sm={6} xs={12}>
                            <Typography color={'white'} gutterBottom variant={'h2'}>
                                Search Trip
                            </Typography>
                            <Typography color={'white'} gutterBottom fontWeight={'normal'}>
                                Find trip by date, location & destination
                            </Typography>
                        </Grid>
                        <Grid item lg={1} md={1} sm={6} xs={12}>
                            <img src={ArrowRightLogo} height={20} alt="" />
                        </Grid>
                        <Grid item lg={2} md={2} sm={6} xs={12}>
                            <Typography color={'white'} gutterBottom variant={'h2'}>
                                Select vehicle
                            </Typography>
                            <Typography color={'white'} gutterBottom fontWeight={'normal'}>
                                Select vehicle that matches your preferences
                            </Typography>
                        </Grid>
                        <Grid item lg={1} md={1} sm={6} xs={12}>
                            <img src={ArrowRightLogo} height={20} alt="" />
                        </Grid>
                        <Grid item lg={2} md={2} sm={6} xs={12}>
                            <Typography color={'white'} gutterBottom variant={'h2'}>
                                Make booking
                            </Typography>
                            <Typography color={'white'} gutterBottom fontWeight={'normal'}>
                                Make payment for seats and get notified a day before vehicle leaves
                            </Typography>
                        </Grid>
                        <Grid item lg={1} md={1} sm={6} xs={12}>
                            <img src={ArrowRightLogo} height={20} alt="" />
                        </Grid>
                        <Grid item lg={2} md={2} sm={6} xs={12}>
                            <Typography color={'white'} gutterBottom variant={'h2'}>
                                Go to Departure Point
                            </Typography>
                            <Typography color={'white'} gutterBottom fontWeight={'normal'}>
                                Make sure to be on time for trip
                            </Typography>
                        </Grid>
                    </Grid>
                </DashBoardDetailComponent>
            </Grid>
            {/* <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} md={8}>
                        <TotalGrowthBarChart isLoading={isLoading} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <PopularCard isLoading={isLoading} />
                    </Grid>
                </Grid>
            </Grid> */}
            <Grid container spacing={2} mt={5} paddingY={2} paddingX={5} alignItems="center" justifyContent="start">
                <Typography color={'Black'} gutterBottom variant={'h2'}>
                    Find Trip
                </Typography>
            </Grid>
            <Grid container spacing={1} paddingX={5} alignItems="center" justifyContent="start">
                <Grid item lg={3} md={3} sm={6} xs={12}>
                    <TextField
                        placeholder="Search for a trip"
                        fullWidth
                        onChange={(e) => setParams({ ...params, search: e.target.value })}
                    />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Button
                        style={{ borderRadius: 20 }}
                        disableElevation
                        disabled={fetching}
                        // fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="secondary"
                        onClick={() => fetchTrips(params)}
                    >
                        {fetching ? 'Loading...' : 'Search'}
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={1} mt={5} paddingY={2} paddingX={5} alignItems="center" justifyContent="start">
                <Grid item lg={1} md={1} sm={12} xs={12}>
                    <Typography color={'Black'} gutterBottom variant={'h2'}>
                        Filter
                    </Typography>
                </Grid>
                <Grid item lg={2} md={6} sm={6} xs={12}>
                    <FormControl fullWidth mt={1}>
                        <InputLabel id="demo-simple-select-label">Departure Point</InputLabel>
                        <Select
                            // value={trip?.startPoint}
                            // onChange={(e) => setTrip({ ...trip, startPoint: e.target.value })}
                            label={'startPoint'}
                        >
                            {/* <MenuItem>State</MenuItem> */}
                            {states.map((val, key) => (
                                <MenuItem value={val?.state} selected>
                                    {val?.state}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {/* <Select value={'First'} label={'First'} fullWidth>
                        <MenuItem>First</MenuItem>
                        <MenuItem>Second</MenuItem>
                        <MenuItem>Third</MenuItem>
                    </Select> */}
                </Grid>
                <Grid item lg={2} md={6} sm={6} xs={12}>
                    <FormControl fullWidth mt={1}>
                        <InputLabel id="demo-simple-select-label">Destination Point</InputLabel>
                        <Select
                            // value={trip?.startPoint}
                            // onChange={(e) => setTrip({ ...trip, startPoint: e.target.value })}
                            label={'startPoint'}
                        >
                            {/* <MenuItem>State</MenuItem> */}
                            {states.map((val, key) => (
                                <MenuItem value={val?.state} selected>
                                    {val?.state}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item lg={1} md={6} sm={6} xs={12}>
                    <TextField placeholder="Search for a trip" fullWidth type="date" />
                </Grid>
                <Grid item lg={1} md={6} sm={6} xs={12}>
                    <Button
                        style={{ borderRadius: 20 }}
                        disableElevation
                        disabled={fetching}
                        // fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="secondary"
                        onClick={() => fetchTrips(params)}
                    >
                        {fetching ? 'Loading...' : 'Search'}
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={2} paddingY={2} paddingX={5} alignItems="center">
                <Typography color={'grey'} gutterBottom>
                    Popular Destinations
                </Typography>
            </Grid>

            <Grid container spacing={2} paddingX={5} alignItems="center">
                {fetching ? (
                    <Grid align={'center'}>
                        <h3>Fetching trips</h3>
                    </Grid>
                ) : (
                    scheduledTrips.map((item, key) => {
                        return (
                            <Grid item lg={3} md={3} sm={6} xs={12}>
                                <TripItemCard data={item} />
                            </Grid>
                        );
                    })
                )}
            </Grid>
        </Grid>
    );
};

export default UserDashboard;
