import { useEffect, useState } from 'react';

// material-ui
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import TripItemCard from 'ui-component/cards/TripItem';
import DashBoardDetailComponent from '../component/DashBoardDetailComponent';
import { DriverTripService } from 'services/driver/trip_service';

// ==============================|| DEFAULT USERDASHBOARD ||============================== //

const DriverTrips = () => {
    const [isLoading, setLoading] = useState(true);

    const [isFetching, setFetching] = useState(false);
    const [scheduledTrips, setScheduledTrips] = useState([]);
    useEffect(() => {
        setLoading(false);
        fetchTrips();
    }, []);

    const fetchTrips = async () => {
        setFetching(true);
        const tripData = await DriverTripService.myTrips({});
        setFetching(false);
        if (!tripData.success) {
            return;
        }
        setScheduledTrips(tripData.data.schedules);
    };
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <DashBoardDetailComponent>
                    <Grid
                        container
                        spacing={gridSpacing}
                        style={{ height: 200 }}
                        paddingY={'2'}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item lg={2} md={2} sm={6} xs={12}>
                            <Typography color={'white'} gutterBottom variant={'h1'}>
                                My Trips
                            </Typography>
                        </Grid>
                    </Grid>
                </DashBoardDetailComponent>
            </Grid>

            {/* <Grid container spacing={2} mt={2} ml={1} paddingY={2} paddingX={5} alignItems="center">
                <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                    Completed Trips
                </Typography>
            </Grid> */}

            <Grid container spacing={2} paddingX={5} alignItems="center">
                {/* {sampleTrip.map((item, key) => {
                    return (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <TripItemCard data={{ ...item, url: '/driver/trip/1' }} />
                        </Grid>
                    );
                })} */}
            </Grid>

            <Grid container spacing={2} mt={2} ml={1} paddingY={2} paddingX={5} alignItems="center">
                <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                    Scheduled Trips
                </Typography>
            </Grid>

            <Grid container spacing={2} paddingX={5} alignItems="center">
                {isFetching ? (
                    <h3>Fetching...</h3>
                ) : (
                    scheduledTrips.map((item, key) => {
                        item.userRole = 'driver';
                        return (
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <TripItemCard data={item} />
                            </Grid>
                        );
                    })
                )}
            </Grid>
        </Grid>
    );
};

export default DriverTrips;
