import { AppBar, Box, IconButton, Toolbar, Typography, Menu, Container, Avatar, Button, Tooltip, MenuItem, Grid } from '@mui/material';
import { IconMenu, IconUser } from '@tabler/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import appLogo from '../../../assets/images/logo_white.svg';

const pages = ['Home', 'Destinations', 'Contact Us'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
const LandingTop = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: '#000000'
                // main: '#00000000'
            }
        }
    });

    return (
        <ThemeProvider theme={darkTheme}>
            <AppBar position="static" color="primary" enableColorOnDark>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
                        <Grid container>
                            {/* <IconUser /> */}
                            <img src={appLogo} alt="" />
                            {/* <Typography
                                variant="h6"
                                noWrap
                                component="a"
                                href="#app-bar-with-responsive-menu"
                                sx={{
                                    mr: 2,
                                    // display: { xs: 'none', md: 'flex' },
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: 'inherit',
                                    textDecoration: 'none'
                                }}
                            >
                                LOGO Hold
                            </Typography> */}
                        </Grid>

                        {/* Menu fo Items */}
                        {/* <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <IconMenu />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' }
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box> */}
                        {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
                        {/* <IconUser /> */}
                        {/* <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none'
                        }}
                    >
                        LOGO Here
                    </Typography> */}
                        <Grid container justifyContent={'center'}>
                            <Grid>
                                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                    {pages.map((page) => (
                                        <Link to={'/'}>
                                            <Typography
                                                color="white"
                                                component="a"
                                                mx={1}
                                                sx={{
                                                    mr: 2,
                                                    // display: { xs: 'none', md: 'flex' },
                                                    // fontFamily: 'monospace',
                                                    // fontWeight: 700,
                                                    letterSpacing: '.1rem',
                                                    // color: 'inherit',
                                                    textDecoration: 'none'
                                                }}
                                            >
                                                {page}
                                            </Typography>
                                        </Link>
                                        // <Link to={'/test'}>
                                        //     <Typography color="white" component="a" mx={1}>
                                        //         {page}
                                        //     </Typography>
                                        // </Link>
                                    ))}
                                </Box>
                            </Grid>
                        </Grid>
                        {/* <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            // <Button key={page} onClick={handleCloseNavMenu} sx={{ my: 2, color: 'white', display: 'block' }}>
                            //     {page}
                            // </Button>
                            <Typography color="white" component="a" mx={1}>
                                {page}
                            </Typography>
                        ))}
                    </Box> */}
                        <Grid container>
                            <Grid justifyContent={'end'} sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                <Grid item>
                                    <Link to={'login'}>
                                        <button className="landingSingInBtn hover">Sign In</button>
                                    </Link>
                                    {/* <Button
                                        style={{ borderRadius: 6, paddingRight: 20, paddingLeft: 20 }}
                                        disableElevation
                                        // disabled={isSubmitting}
                                        // fullWidth
                                        size="large"
                                        type="submit"
                                        color="warning"
                                        variant="outlined"
                                        // onClick={() => setStep(2)}
                                    >
                                        Sign In
                                    </Button> */}
                                </Grid>
                                <Grid item ml={2}>
                                    {/* <Button
                                        style={{ borderRadius: 6, paddingRight: 20, paddingLeft: 20 }}
                                        disableElevation
                                        // disabled={isSubmitting}
                                        // fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Sign Up
                                    </Button> */}
                                    <Link to={'/register'}>
                                        <button className="landingSingupBtn hover">Sign Up</button>
                                    </Link>
                                </Grid>
                            </Grid>

                            <Grid container justifyContent={'end'}>
                                <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleOpenNavMenu}
                                        color="inherit"
                                    >
                                        <IconMenu />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorElNav}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center'
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center'
                                        }}
                                        open={Boolean(anchorElNav)}
                                        onClose={handleCloseNavMenu}
                                        sx={{
                                            display: { xs: 'block', md: 'none' }
                                        }}
                                    >
                                        {pages.map((page) => (
                                            <MenuItem key={page} onClick={handleCloseNavMenu}>
                                                <Typography textAlign="center">{page}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>
                            </Grid>
                        </Grid>
                        {/* <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box> */}
                    </Toolbar>
                </Container>
            </AppBar>
        </ThemeProvider>
    );
};

export default LandingTop;
