import { useEffect, useState } from 'react';

// material-ui
import {
    Button,
    Grid,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Paper,
    Table,
    Modal,
    Box
} from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import TripItemCard from 'ui-component/cards/TripItem';
import DashBoardDetailComponent from '../component/DashBoardDetailComponent';
import WalletContainer from '../component/WalletContainer';
import { height } from '@mui/system';
import addIcon from '../../../assets/images/add_icon.png';
import deductIcon from '../../../assets/images/deduct_icon.png';
import clockIcon from '../../../assets/images/clock_icon.png';
import DriverWithrawalComponent from './component/DriverWithrawalComponent';
import DriverFundingComponent from './component/DriverFundingComponent';
import { useNavigate } from 'react-router';
import { DriverWalletService } from 'services/driver/wallet_service';
import { Configs } from 'constants/configs';
import { useSelector } from 'react-redux';
// ==============================|| DEFAULT USERDASHBOARD ||============================== //

const DriverWallet = () => {
    const [modalAction, setModalAction] = useState('fund');
    const [open, setOpen] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [wallet, setWallet] = useState({});
    const [bank, setBank] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const [bankList, setBankList] = useState([]);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setModalAction('');
        setOpen(false);
    };
    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);

    const [userInfo, setUserInfo] = useState('traveller');
    const customization = useSelector((state) => state.customization);
    useEffect(() => {
        setLoading(false);
        fetchWallet();
        const localUser = localStorage.getItem('userInfo');
        const usr = JSON.parse(localUser);
        // alert(localUser);
        setUserInfo(usr);
        setUserInfo(customization?.userInfo || usr);
    }, []);
    const fetchWallet = async () => {
        setFetching(true);
        const getWallet = await DriverWalletService.info({});
        setFetching(false);
        if (!getWallet.success) {
            // toast.error(createTrip?.message ?? 'Could not create Trip');
            return;
        }
        setWallet(getWallet?.data?.wallet);
        setBank(getWallet?.data?.bank);
        setTransactions(getWallet.data?.transactions ?? []);
        if (!getWallet?.data?.bank) {
            getBanks();
        }
        // toast.success(createTrip?.message ?? 'Trip created');
    };

    const getBanks = async () => {
        const bankResponse = await DriverWalletService.banks({});

        if (!bankResponse.success) {
            return;
        }
        setBankList(bankResponse.data);
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                {modalAction == 'fund' ? (
                    <>
                        <DriverFundingComponent />
                    </>
                ) : (
                    <DriverWithrawalComponent
                        wallet={wallet}
                        bank={bank}
                        bankList={bankList}
                        handleClose={handleClose}
                        fetchWallet={fetchWallet}
                    />
                )}
            </Modal>
            <Grid item xs={12} mt={5}>
                <WalletContainer>
                    <Grid
                        container
                        spacing={gridSpacing}
                        style={{ height: 200 }}
                        paddingY={'2'}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography color={'white'} gutterBottom fontSize={18} align="center">
                                Balance
                            </Typography>
                            <Typography color={'white'} gutterBottom variant={'h1'} align="center">
                                {Configs.currencyFormat(wallet?.balance ?? 0)}
                            </Typography>
                            <Typography color={'white'} gutterBottom fontSize={16} align="center">
                                {customization?.userInfo?.firstName} {customization?.userInfo?.lastName}
                            </Typography>
                        </Grid>
                    </Grid>
                </WalletContainer>
            </Grid>
            <Grid container spacing={5} mt={3} paddingLeft={3} alignItems="center">
                {[
                    // {
                    //     title: 'Fund Wallet',
                    //     icon: addIcon,
                    //     onClick: () => {
                    //         setModalAction('fund');
                    //         handleOpen();
                    //     }
                    // },
                    {
                        title: 'Withdraw',
                        icon: deductIcon,
                        onClick: () => {
                            setModalAction('withdraw');
                            handleOpen();
                        }
                    },
                    { title: 'Transactions', icon: clockIcon, onClick: () => navigate('/driver/transactions') }
                ].map((item, key) => {
                    return (
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <WalletContainer>
                                <Grid
                                    className="hover"
                                    onClick={item?.onClick}
                                    container
                                    spacing={gridSpacing}
                                    style={{ height: 200 }}
                                    paddingY={'2'}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <div align="center">
                                            <img src={item?.icon} height={60} alt="" />
                                        </div>
                                        <Typography color={'white'} gutterBottom fontSize={18} mt={2} align="center">
                                            {item?.title}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </WalletContainer>
                        </Grid>
                    );
                })}
            </Grid>

            {/* <Grid container spacing={2} mt={3} ml={1} paddingY={2} paddingX={4} alignItems="center">
                <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                    Recent transactions
                </Typography>
            </Grid> */}

            <Grid container spacing={2} mt={5} ml={2} paddingX={1} alignItems="center">
                <div className="myContainer">
                    <Typography color={'black'} gutterBottom _variant={'h2'}>
                        <font size={5}>Transactions</font>
                    </Typography>
                    {fetching ? (
                        <Grid align={'center'}>
                            <h3>Fetching Transactions</h3>
                        </Grid>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {/* <TableCell align="left"></TableCell> */}
                                        <TableCell>Reference</TableCell>
                                        <TableCell>Transaction Type</TableCell>
                                        <TableCell>Action</TableCell>
                                        <TableCell align="left">Amount&nbsp;</TableCell>
                                        <TableCell align="left">Date</TableCell>
                                        <TableCell align="left">Status&nbsp;</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {transactions.map((row) => (
                                        <TableRow key={row?.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="left">{row?.reference}</TableCell>
                                            <TableCell align="left">{row?.transactionType}</TableCell>
                                            <TableCell component="th" scope="row">
                                                <font color={row?.action.toString().toLowerCase() == 'credit' ? 'green' : 'red'}>
                                                    {row?.action}
                                                </font>
                                            </TableCell>
                                            <TableCell align="left">{Configs.currencyFormat(row?.amount ?? 0)}</TableCell>
                                            <TableCell align="left">{Configs.dateConvert(row.createdAt)}</TableCell>
                                            <TableCell align="left">{row?.status}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </div>
            </Grid>
        </Grid>
    );
};

export default DriverWallet;
