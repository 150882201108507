import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Divider, Grid, Stack, TextField, Typography, useMediaQuery } from '@mui/material';

// project imports
import OTPInput from 'ui-component/OtpField';
import { useState } from 'react';
import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthService } from 'services/auth_service';

const VerifyAccount = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [otp, setOtp] = useState('');
    const [userInfo, setUserInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [resending, setResending] = useState(false);
    const navigate = useNavigate();
    const search = window.location.search;
    const query = new URLSearchParams(search);
    const redirect = query.get('redirect');

    useEffect(() => {
        const user = localStorage.getItem('userInfo');
        if (user) {
            // alert(user);
            setUserInfo(JSON.parse(user));
        }
    }, []);

    const handleVerify = async () => {
        setLoading(true);
        const verifyResponse = await AuthService.verifyEmail({ otp });
        setLoading(false);
        if (!verifyResponse.success) {
            toast.error(verifyResponse?.message);
            return;
        }
        const resultData = verifyResponse?.data;
        const userInfo = {
            ...resultData,
            // firstName: 'Test',
            // lastName: 'user',
            accountType: resultData?.role == 'passenger' ? 'traveller' : resultData?.role, // values.email == 'admin@mail.com' ? 'admin' : accountType,
            token: verifyResponse?.token
        };
        // localStorage.setItem('authToken', verifyResponse?.token);
        // localStorage.setItem('userInfo', JSON.stringify(userInfo));

        // dispatch({ type: 'userInfo', userInfo });
        navigate(redirect ? '/' + redirect : '/verify-identity');
    };

    const handleResend = async () => {
        toast('Sending OTP');
        const verifyResponse = await AuthService.resendOtp({});

        if (!verifyResponse.success) {
            toast.error(verifyResponse?.message);
            return;
        }
        toast.success(verifyResponse?.message);
    };
    return (
        <div>
            <ToastContainer />
            <Grid item xs={12} md={6}>
                <Grid
                    direction="column"
                    container
                    spacing={2}
                    alignItems="center"
                    style={{ height: '100vh' }}
                    justifyContent="center"
                    // sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}
                >
                    <Grid>
                        <Typography color={theme.palette.secondary.main} gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
                            Verify Your Account
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography variant="caption" fontSize="16px" textAlign={matchDownSM ? 'center' : 'inherit'}>
                            Enter code sent to {userInfo?.email}
                        </Typography>
                    </Grid>
                    <Grid mt={5} container justifyContent={'center'}>
                        {/* <TextField fullWidth label="fullWidth" id="fullWidth" /> */}
                        <OTPInput
                            onChange={(value, index) => {
                                // alert(value);
                                setOtp(value);
                            }}
                        />
                    </Grid>
                    <Grid pt={5}>
                        <Button
                            style={{ borderRadius: 10, paddingRight: 100, paddingLeft: 100 }}
                            disableElevation
                            disabled={loading}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="secondary"
                            onClick={handleVerify}
                        >
                            {loading ? 'Verifying..' : 'Next'}
                        </Button>
                    </Grid>
                    <Grid mt={2}>
                        <Typography
                            onClick={handleResend}
                            color={'primary'}
                            className="hover"
                            // component={Link}
                            // to="/pages/register/register3"
                            variant="subtitle1"
                            sx={{ textDecoration: 'none' }}
                        >
                            Resend OTP
                        </Typography>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Grid item container direction="column" alignItems="center" xs={12}>
                            <Typography component={Link} to="/pages/register/register3" variant="subtitle1" sx={{ textDecoration: 'none' }}>
                                Resend OTP
                            </Typography>
                        </Grid>
                    </Grid> */}
                </Grid>
            </Grid>
        </div>
    );
};

export default VerifyAccount;
