// const { verify } = require("jsonwebtoken");

const Configs = {
    decodedToken: (token) => {
        var data = {};
        // verify(token, "dashwood", async (err, decoded) => {
        //   if (!err) {
        //     data = decoded.paylod;
        //   }
        // });
        return data;
    },
    dateConvert: (data) => {
        var d = new Date(data);
        return (
            d.toLocaleString('en-us', { day: '2-digit' }) +
            '-' +
            d.toLocaleString('en-us', { month: '2-digit' }) +
            '-' +
            d.toLocaleString('en-us', { year: 'numeric' })
        );
    },
    tripDate: (data) => {
        var d = new Date(data);
        var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];

        return (
            d.toLocaleString('en-us', { day: '2-digit' }) + ' ' + d.toLocaleString('en-us', { month: 'short' }) + ', ' + days[d.getDay()]
            // .toLocaleString('en-us', { day: '2-digit' })
        );
    },
    currencyFormat: (num) => {
        return '₦' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },

    dateHourDifference: (logDate) => {
        const curren = new Date();
        return (Date.parse(curren) - Date.parse(logDate)) / 3600000; //1000 / 60 / 60
    },

    currencyFormat: (num) => {
        return '₦' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
};
export { Configs };
// export default Configs
