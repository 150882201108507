// assets
import { IconKey, IconSettings, IconLogout } from '@tabler/icons';

// constant
const icons = {
    IconKey,
    IconSettings,
    IconLogout
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const adminOther = {
    id: 'pages',
    title: 'Others',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'settings',
            title: 'Settings',
            type: 'collapse',
            // url: '/admin/settings',
            icon: icons.IconSettings,
            breadcrumbs: false,
            children: [
                {
                    id: 'general',
                    title: 'General',
                    type: 'item',
                    url: '/admin/general-settings',
                    breadcrumbs: false
                },
                {
                    id: 'location-settings',
                    title: 'Location',
                    type: 'item',
                    url: '/admin/locations',
                    breadcrumbs: false
                },
                {
                    id: 'roles-permissions',
                    title: 'Roles and Permission',
                    type: 'item',
                    url: '/admin/roles-permissions',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'logout',
            title: 'Logout',
            type: 'item',
            url: '/login',
            icon: icons.IconLogout,
            breadcrumbs: false
        }
    ]
};

export default adminOther;
