import { useEffect, useState } from 'react';

// material-ui
import {
    Button,
    Grid,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Paper,
    Table,
    Modal,
    Box,
    Pagination
} from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
// ==============================|| DEFAULT USERDASHBOARD ||============================== //
import { Stack } from '@mui/system';
import { Link } from 'react-router-dom';
import { AdminSettingsService } from 'services/admin/settings_service';
import { ToastContainer, toast } from 'react-toastify';
const Location = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: 4,
        boxShadow: 24,
        p: 2
    };
    const [modalAction, setModalAction] = useState('fund');
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setModalAction('');
        setOpen(false);
    };

    const [isLoading, setLoading] = useState(true);
    const [isFetching, setFetching] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);
    const [locations, setLocations] = useState([]);
    const [locationData, setLocationData] = useState({
        name: ''
    });

    useEffect(() => {
        setLoading(false);
        getLocations();
    }, []);

    const getLocations = async () => {
        setFetching(true);
        const locationResponse = await AdminSettingsService.getAllLocations({});
        setFetching(false);
        if (!locationResponse.success) {
            return;
        }
        setLocations(locationResponse.data);
        setPagination(locationResponse.pagination);
    };
    const newLocation = async () => {
        setSubmitting(true);
        const locationResponse = await AdminSettingsService.newLocation(locationData);
        setSubmitting(false);
        if (!locationResponse.success) {
            toast.error(locationResponse.message);
            return;
        }
        toast.success(locationResponse.message);
        handleClose();
        getLocations();
    };
    return (
        <Grid container spacing={gridSpacing}>
            <ToastContainer />
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                        Create Location
                    </Typography>

                    <Grid>
                        <Grid mt={5}>
                            <TextField
                                fullWidth
                                label="Name"
                                id="fullWidth"
                                type="text"
                                value={locationData.name}
                                onChange={(e) => setLocationData({ ...locationData, name: e.target.value })}
                            />
                        </Grid>
                        <Grid pt={5} align="center">
                            <Button
                                style={{ borderRadius: 20, paddingRight: 100, paddingLeft: 100 }}
                                disableElevation
                                disabled={isSubmitting}
                                // fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                onClick={newLocation}
                            >
                                {isSubmitting ? 'Creating...' : 'Create Location'}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Grid container spacing={2} mt={5} ml={2} paddingX={1} alignItems="center" justifyContent={'start'}>
                <Grid align="left" container justifyContent="end" alignItems={'center'} my={2} mx={2}>
                    <Grid item xs={8}>
                        <TextField placeholder="Search Location" />
                    </Grid>
                    <Grid item xs={4} align={'right'}>
                        <Typography color={'blue'} gutterBottom onClick={handleOpen} className={'hover'}>
                            Create Location
                        </Typography>
                    </Grid>
                </Grid>
                <div className="myContainer">
                    {/* <Typography color={'black'} gutterBottom _variant={'h2'}>
                        <font size={5}>Locations</font>
                    </Typography> */}
                    {isFetching ? (
                        <Grid align={'center'}>Fetching Locations</Grid>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell align="left">Status</TableCell>
                                        {/* <TableCell align="left">Action</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {locations.map((row, key) => {
                                        // const currStatus = row?.status.toString().toLowerCase();
                                        return (
                                            <TableRow key={key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell align="left">{row?.name}</TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Grid container spacing={1}>
                                                        <Grid item>
                                                            <Typography color={row?.active ? 'green' : 'red'} fontSize={16} gutterBottom>
                                                                <font size={6}>&#8226;</font>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography color={'black'} gutterBottom>
                                                                {row?.active ? 'Active' : 'Non-Active'}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </div>
                <Grid container mt={3} align={'right'} justifyContent={'end'}>
                    <Stack spacing={2}>
                        <Pagination count={3} color="secondary" />
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Location;
