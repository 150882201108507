import axios from 'axios';
import { util } from 'constants/api.constant';
import { baseUrl } from 'constants/constants';

const token = localStorage.getItem('token');
const prefix = 'driver/wallets';
let DriverWalletService = {
    info: async (data) => {
        try {
            const resp = await axios.get(baseUrl + `/${prefix}/me`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successufl',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    transactions: async (data) => {
        try {
            const resp = await axios.get(baseUrl + `/${prefix}/transactions`, {
                params: data,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successufl',
                data: resp?.data?.data,
                pagination: resp?.data?.pagination,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    withdraw: async (data) => {
        try {
            const resp = await axios.post(baseUrl + `/${prefix}/withdraw`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successufl',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    banks: async (data) => {
        try {
            const resp = await axios.get(baseUrl + `/${prefix}/banks`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successufl',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    accountEnquiry: async (data) => {
        try {
            const resp = await axios.post(baseUrl + `/${prefix}/banks/validate-account`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successufl',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    saveAccount: async (data) => {
        try {
            const resp = await axios.post(baseUrl + `/${prefix}/banks/save-account`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successufl',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    }
};

export { DriverWalletService };
