import React from 'react';
import { useEffect, useState } from 'react';

// material-ui
import { Button, Grid, Rating, TextField, Typography } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import MainCard from './MainCard';
import samplePic from '../../assets/images/sample_pic.png';
import { useNavigate } from 'react-router';
import { Configs } from 'constants/configs';
const TripItemCard = ({ data }) => {
    const navigate = useNavigate();
    const fullUrl = data.url ?? `/${data?.userRole ?? 'traveller'}/trip/${data?._id}`;
    return (
        <div className="tripItem">
            <Grid onClick={() => navigate(fullUrl)}>
                <Typography color={'black'} gutterBottom _variant={'h2'}>
                    <font size={5}>
                        {data?.fromLocation?.name ?? ''} &rarr; {data?.toLocation?.name ?? ''}
                    </font>
                </Typography>
                <Typography color={'grey'} gutterBottom fontSize={12} fontWeight={'normal'}>
                    Date
                </Typography>
                <Typography color={'black'} gutterBottom fontSize={16} fontWeight={'normal'}>
                    {Configs.tripDate(data?.dateTime).split(',')[0]},{' '}
                    <font color={'grey'}>{Configs.tripDate(data?.dateTime).split(',')[1]}</font>
                </Typography>
                <Typography color={'grey'} gutterBottom fontSize={12} fontWeight={'normal'}>
                    {data?.time ?? '09:00AM'}
                </Typography>
                {/* <Grid container direction={'row'} paddingTop={1} alignItems="center" justifyContent="start" spacing={1}>
                    <Grid item>
                        <img src={samplePic} alt="" className="tripItemPic" />
                    </Grid>
                    <Grid item>
                        <Grid>
                            <Typography
                                variant="caption"
                                color={'black'}
                                fontSize="14px"
                                fontWeight={'bold'}
                                // paddingLeft={3}
                                // textAlign={matchDownSM ? 'center' : 'inherit'}
                            >
                                {data?.customer ?? 'Shehu Musa'}
                            </Typography>
                        </Grid>
                        <Grid>
                            <Rating
                                // name="simple-controlled"
                                disabled={false}
                                value={data?.rating ?? 4}
                                onChange={(event, newValue) => {
                                    // setValue(newValue);
                                }}
                                name="size-small"
                                defaultValue={2}
                                size="small"
                                readOnly
                            />
                        </Grid>
                    </Grid>
                </Grid> */}
            </Grid>
        </div>
    );
};

export default TripItemCard;
