import { useEffect, useState } from 'react';

// material-ui
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import samplePic from '../../../assets/images/sample_pic.png';
import { useSelector } from 'react-redux';
import { TravellerAccountService } from 'services/traveller/account_service';
import { Configs } from 'constants/configs';
import { Link } from 'react-router-dom';

// ==============================|| DEFAULT USERDASHBOARD ||============================== //

const UserProfile = () => {
    const [isLoading, setLoading] = useState(true);
    const [fetching, setFetching] = useState(false);
    const customization = useSelector((state) => state.customization);

    const [profile, setProfile] = useState({});
    const [trips, setTrips] = useState([]);
    const [schedules, setSchedules] = useState([]);
    const [requests, setRequests] = useState([]);
    useEffect(() => {
        setLoading(false);
        getProfile();
    }, []);

    const getProfile = async () => {
        setFetching(true);
        const profileInfo = await TravellerAccountService.profile({});
        setFetching(false);
        if (!profileInfo.success) {
            return;
        }

        // alert(JSON.stringify(profileInfo?.schedules));
        // setProfile(profileInfo?.data);
        setRequests(profileInfo?.requests ?? []);
        setTrips(profileInfo?.trips ?? []);
    };
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <div className="profileContainer">
                    <Grid container style={{ height: 220 }} alignItems="end" justifyContent="center">
                        {/* <Grid item lg={12} md={12} sm={6} xs={12} style={{ backgroundColor: 'blue' }}>
                            <Typography color={'white'} gutterBottom variant={'h1'} align="center">
                                <font size={6}>
                                    {'Kano'} &nbsp;&nbsp;&nbsp; &rarr; &nbsp;&nbsp;&nbsp;{'Kaduna'}
                                </font>
                            </Typography>
                        </Grid> */}
                        <div className="profileContainerChild">
                            <Grid container direction={'row'} paddingTop={1} alignItems="center" justifyContent="start" spacing={1}>
                                <Grid item>
                                    <img src={customization?.userInfo?.pictureUrl} alt="" className="profilePic" />
                                </Grid>
                                <Grid item>
                                    <Grid>
                                        <Typography
                                            variant="caption"
                                            color={'white'}
                                            fontSize="14px"
                                            fontWeight={'bold'}
                                            // paddingLeft={3}
                                            // textAlign={matchDownSM ? 'center' : 'inherit'}
                                        >
                                            {customization?.userInfo?.firstName} {customization?.userInfo?.lastName}
                                        </Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography
                                            variant="caption"
                                            color={'white'}
                                            fontSize="12px"
                                            fontWeight={'normal'}
                                            // paddingLeft={3}
                                            // textAlign={matchDownSM ? 'center' : 'inherit'}
                                        >
                                            {customization?.userInfo?.email}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </div>
                {/* <DashBoardDetailComponent></DashBoardDetailComponent> */}
            </Grid>

            {/* <Grid container spacing={2} mt={2} ml={1} paddingY={2} paddingX={5}>
                <Typography color={'black'} fontWeight={'bold'} fontSize={18} gutterBottom>
                    Progress
                </Typography>
            </Grid> */}

            <Grid container spacing={2} paddingX={5} pt={3}>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <div className="mySettingsContainer">
                        <Grid container spacing={2} paddingX={5}>
                            <Grid xs={12} mt={2}>
                                <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom align="center">
                                    Adit Profile
                                </Typography>
                                <Typography color={'grey'} fontWeight={'normal'} fontSize={14} gutterBottom align="center">
                                    Business owner with multiple branches around the country
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                    Full Name
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography color={'grey'} fontWeight={'normal'} fontSize={14} gutterBottom>
                                    {customization?.userInfo?.firstName} {customization?.userInfo?.lastName}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                    Phone
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography color={'grey'} fontWeight={'normal'} fontSize={14} gutterBottom>
                                    {customization?.userInfo?.phoneNumber}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                    Location
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography color={'grey'} fontWeight={'normal'} fontSize={14} gutterBottom>
                                    {customization?.userInfo?.state}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <div className="mySettingsContainer">
                        <Grid container spacing={2} paddingX={5}>
                            <Grid xs={12} mt={2}>
                                <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom align="center">
                                    Requested Trips
                                </Typography>
                                <Typography color={'grey'} fontWeight={'normal'} fontSize={14} gutterBottom align="center">
                                    Explore available trips just for you based on your location
                                </Typography>
                            </Grid>
                            {/* {schedules.length} */}
                            {fetching ? (
                                <Grid container align="center">
                                    <h3> Fetching Info</h3>
                                </Grid>
                            ) : (
                                requests.slice(0, 3).map((requestItem, key) => (
                                    <>
                                        <Grid item xs={10}>
                                            <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                                {requestItem?.schedule?.fromLocation?.name} &nbsp;<font size={4}>&rarr;</font> &nbsp;
                                                {requestItem?.schedule?.toLocation?.name}
                                            </Typography>
                                            <Typography color={'grey'} fontWeight={'normal'} fontSize={14} gutterBottom>
                                                {Configs.tripDate(requestItem?.schedule?.estimatedDeparture)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} alignItems={'center'}>
                                            <Link to={`/traveller/trip/${requestItem?.schedule?._id}`}>View</Link>
                                            {/* <Typography color={'blue'} fontWeight={'normal'} fontSize={14} gutterBottom align="right">
                                              View
                                            </Typography> */}
                                        </Grid>
                                    </>
                                ))
                            )}
                        </Grid>
                    </div>
                </Grid>
                {/* Booked Trips */}
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <div className="mySettingsContainer">
                        <Grid container spacing={2} paddingX={5}>
                            <Grid xs={12} mt={2}>
                                <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom align="center">
                                    Booked Trips
                                </Typography>
                                <Typography color={'grey'} fontWeight={'normal'} fontSize={14} gutterBottom align="center">
                                    View trips you have booked ahead
                                </Typography>
                            </Grid>
                            {fetching ? (
                                <Grid container align="center">
                                    <h3> Fetching Info</h3>
                                </Grid>
                            ) : (
                                trips.slice(0, 3).map((tripItem, key) => (
                                    <>
                                        <Grid item xs={10}>
                                            <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                                {tripItem?.schedule?.fromLocation?.name} &nbsp;<font size={4}>&rarr;</font> &nbsp;
                                                {tripItem?.schedule?.toLocation?.name}
                                            </Typography>
                                            <Typography color={'grey'} fontWeight={'normal'} fontSize={14} gutterBottom>
                                                {Configs.tripDate(tripItem?.schedule?.estimatedDeparture)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} alignItems={'center'}>
                                            <Link to={`/traveller/trip/${tripItem?.schedule?._id}`}>View</Link>
                                        </Grid>
                                    </>
                                ))
                            )}
                        </Grid>
                    </div>
                </Grid>
                {/* My trips */}
                {/* <Grid item lg={4} md={4} sm={12} xs={12}>
                    <div className="mySettingsContainer">
                        <Grid container spacing={2} paddingX={5}>
                            <Grid xs={12} mt={2}>
                                <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom align="center">
                                    My Trips
                                </Typography>
                                <Typography color={'grey'} fontWeight={'normal'} fontSize={14} gutterBottom align="center">
                                    View trips completed and in progress
                                </Typography>
                            </Grid>
                            {[1, 2, 3].map((index, key) => (
                                <>
                                    <Grid item xs={10}>
                                        <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                            Kaduna &nbsp;<font size={4}>&rarr;</font> &nbsp; Benin
                                        </Typography>
                                        <Typography color={'grey'} fontWeight={'normal'} fontSize={14} gutterBottom>
                                            April 23rd
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} alignItems={'center'}>
                                        <Typography color={'blue'} fontWeight={'normal'} fontSize={14} gutterBottom align="right">
                                            View
                                        </Typography>
                                    </Grid>
                                </>
                            ))}
                        </Grid>
                    </div>
                </Grid> */}
            </Grid>
        </Grid>
    );
};

export default UserProfile;
