import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Divider, Grid, Stack, TextField, Typography, useMediaQuery } from '@mui/material';

// project imports
import LogoBg from '../../../../assets/images/logo_bg.png';
import '../../../../assets/css/customStyle.css';
import { useRef } from 'react';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthService } from 'services/auth_service';

// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const SetProfilePic = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const [verifiData, setVerifiData] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const onButtonClick = () => {
        inputRef.current.click();
    };

    const handleUpload = async () => {
        const localUser = localStorage.getItem('userInfo');
        const usr = JSON.parse(localUser);
        if (!verifiData) {
            toast.error('File cannot be empty');
            return;
        }

        const formData = new FormData();
        formData.append('file', verifiData);

        setSubmitting(true);
        const verResponse = await AuthService.setProfilePic(formData);
        setSubmitting(false);
        if (!verResponse.success) {
            toast.error(verResponse.message);
            return;
        }
        // alert(localUser);
        localStorage.setItem('userInfo', JSON.stringify({ ...usr, pictureUrl: verResponse?.data }));

        toast.success(verResponse.message);
        navigate('/onboard-summary');
    };
    return (
        <div>
            <ToastContainer />
            <Grid item xs={12} md={12}>
                <Grid
                    direction="column"
                    container
                    spacing={2}
                    alignItems="center"
                    style={{ height: '100vh' }}
                    justifyContent="center"
                    // sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}
                >
                    <Grid>
                        <Typography color={theme.palette.secondary.main} gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
                            Upload profile picture
                        </Typography>
                    </Grid>

                    <Grid mt={5}>
                        <Grid onClick={onButtonClick} className="hover">
                            <img src={verifiData ? URL.createObjectURL(verifiData) : LogoBg} alt="" className="progilePic" />
                        </Grid>
                        <input
                            type="file"
                            ref={inputRef}
                            style={{ display: 'none' }}
                            accept="image/*"
                            onChange={(e) => setVerifiData(e.target.files[0])}
                        />
                    </Grid>
                    <Grid pt={5}>
                        <Button
                            style={{ borderRadius: 10, paddingRight: 100, paddingLeft: 100 }}
                            disableElevation
                            onClick={handleUpload}
                            disabled={submitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="secondary"
                        >
                            {submitting ? 'Uploading' : 'Next'}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default SetProfilePic;
