import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PageNotFound from 'views/PageNotFound';

export default function ProtectedRoute({ prefix, children }) {
    const customization = useSelector((state) => state.customization);
    // alert(JSON.stringify(customization?.userInfo));
    const authToken = customization?.userInfo?.token || localStorage.getItem('token') != null;
    const userInfo = customization?.userInfo || JSON.parse(localStorage.getItem('userInfo'));

    //TODO Check for prefix
    // Check if the authToken exists and the user is logged in
    const isAuthenticated = authToken;

    return isAuthenticated ? userInfo.accountType == prefix ? children : <PageNotFound /> : <Navigate to="/login" replace={true} />;
}
