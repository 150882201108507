import { useEffect, useState } from 'react';

// material-ui
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import TripItemCard from 'ui-component/cards/TripItem';
import DashBoardDetailComponent from '../component/DashBoardDetailComponent';
import { TravellerTripService } from 'services/traveller/trip_service';

// ==============================|| DEFAULT USERDASHBOARD ||============================== //

const UserTrips = () => {
    const [isLoading, setLoading] = useState(true);
    const sampleTrip = [
        {
            from: 'Kano',
            to: 'Kaduna',
            date: '12 Apr',
            time: '09:00AM',
            customer: 'Shehu Musa',
            rating: 5
        },
        {
            from: 'Jigawa',
            to: 'Kaduna',
            date: '22 Mar',
            time: '06:00AM',
            customer: 'IB Musa',
            rating: 3
        },
        {
            from: 'Kano',
            to: 'Adamawa',
            date: '16 Jan',
            time: '07:30AM',
            customer: 'Abdul Abba',
            rating: 5
        },
        {
            from: 'Jigawa',
            to: 'Gombe',
            date: '09 Feb',
            time: '09:00AM',
            customer: 'Musa Mamman',
            rating: 2
        }
    ];
    const [scheduledTrips, setScheduledTrips] = useState([]);
    const [fetching, setFetfetching] = useState(false);
    useEffect(() => {
        setLoading(false);
        fetchScheduled();
    }, []);

    const fetchScheduled = async () => {
        setFetfetching(true);
        const tripResponse = await TravellerTripService.requests({});
        setFetfetching(false);
        if (!tripResponse.success) {
            return;
        }
        setScheduledTrips(tripResponse.data);
    };
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <DashBoardDetailComponent>
                    <Grid
                        container
                        spacing={gridSpacing}
                        style={{ height: 200 }}
                        paddingY={'2'}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item lg={2} md={2} sm={6} xs={12}>
                            <Typography color={'white'} gutterBottom variant={'h1'}>
                                My Trips
                            </Typography>
                        </Grid>
                    </Grid>
                </DashBoardDetailComponent>
            </Grid>

            <Grid container spacing={2} mt={2} ml={1} paddingY={2} paddingX={5} alignItems="center">
                <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                    Completed Trips
                </Typography>
            </Grid>

            <Grid container spacing={2} paddingX={5} alignItems="center">
                {/* {sampleTrip.map((item, key) => {
                    return (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <TripItemCard data={item} />
                        </Grid>
                    );
                })} */}
            </Grid>

            <Grid container spacing={2} mt={2} ml={1} paddingY={2} paddingX={5} alignItems="center">
                <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                    Booked Trips
                </Typography>
            </Grid>

            {fetching ? (
                <Grid container align={'center'} mx={5}>
                    <h3>Fetching Booked trips</h3>
                </Grid>
            ) : scheduledTrips.length == 0 ? (
                <Grid container align={'center'} mx={5}>
                    <h3>No trips available</h3>
                </Grid>
            ) : (
                <Grid container spacing={2} paddingX={5} alignItems="center">
                    {scheduledTrips.map((item, key) => {
                        return (
                            <Grid item lg={3} md={3} sm={6} xs={12}>
                                <TripItemCard data={{ ...item.schedule, url: '/traveller/trip/' + item?.schedule?._id }} />
                            </Grid>
                        );
                    })}
                </Grid>
            )}
        </Grid>
    );
};

export default UserTrips;
