import { useEffect, useState } from 'react';

// material-ui
import {
    Button,
    Grid,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Paper,
    Table,
    Modal,
    Box,
    Pagination,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
// ==============================|| DEFAULT USERDASHBOARD ||============================== //
import { Stack } from '@mui/system';
import { Link } from 'react-router-dom';
import { AdminAccountsService } from 'services/admin/admin_account_service';
import { ToastContainer, toast } from 'react-toastify';
const Admins = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: 4,
        boxShadow: 24,
        p: 2
    };
    const [modalAction, setModalAction] = useState('fund');
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setModalAction('');
        setOpen(false);
    };

    const [isLoading, setLoading] = useState(true);
    const [isCreating, setCreating] = useState(false);
    const [adminPayload, setPayload] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        role: 'admin',
        password: ''
    });

    const [isFetching, setFetching] = useState(true);
    const [admins, setAdmins] = useState([]);
    const [params, setParams] = useState({
        page: 1
    });
    const [pagination, setPagination] = useState({
        page: 1,
        pages: 1,
        prev: 0,
        next: 0,
        total: 1
    });

    useEffect(() => {
        setLoading(false);
        getAdmins();
    }, []);

    const getAdmins = async (params) => {
        setFetching(true);
        const userResponse = await AdminAccountsService.getAll(params);
        setFetching(false);
        if (!userResponse.success) {
            return;
        }
        setAdmins(userResponse.data);
        setPagination(userResponse.pagination);
        handleClose();
    };
    const handleCreate = async () => {
        if (
            adminPayload.firstName == '' ||
            adminPayload.lastName == '' ||
            adminPayload.email == '' ||
            adminPayload.phoneNumber == '' ||
            adminPayload.password == ''
        ) {
            toast.error('Fields cannot be empty');
            return;
        }
        setCreating(true);
        const userResponse = await AdminAccountsService.create(adminPayload);
        setCreating(false);
        if (!userResponse.success) {
            toast.error(userResponse.message);
            return;
        }
        toast.success(userResponse.message);
        getAdmins();
    };
    return (
        <Grid container spacing={gridSpacing}>
            <ToastContainer />
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                        Create Admin
                    </Typography>

                    <Grid container spacing={1}>
                        <Grid mt={5} item md={6}>
                            <TextField
                                fullWidth
                                label="Name"
                                id="fullWidth"
                                type="text"
                                value={adminPayload.firstName}
                                onChange={(e) => setPayload({ ...adminPayload, firstName: e.target.value })}
                            />
                        </Grid>
                        <Grid mt={5} item md={6}>
                            <TextField
                                fullWidth
                                label="Last Name"
                                id="fullWidth"
                                type="text"
                                value={adminPayload.lastName}
                                onChange={(e) => setPayload({ ...adminPayload, lastName: e.target.value })}
                            />
                        </Grid>
                        <Grid mt={5} item md={6}>
                            <TextField
                                fullWidth
                                label="Email"
                                id="fullWidth"
                                type="text"
                                value={adminPayload.email}
                                onChange={(e) => setPayload({ ...adminPayload, email: e.target.value })}
                            />
                        </Grid>
                        <Grid mt={5} item md={6}>
                            <TextField
                                fullWidth
                                label="Phone"
                                id="fullWidth"
                                type="text"
                                value={adminPayload.phoneNumber}
                                onChange={(e) => setPayload({ ...adminPayload, phoneNumber: e.target.value })}
                            />
                        </Grid>
                        <Grid mt={5} item md={6}>
                            <TextField
                                fullWidth
                                label="Password"
                                id="fullWidth"
                                type="password"
                                value={adminPayload.password}
                                onChange={(e) => setPayload({ ...adminPayload, password: e.target.value })}
                            />
                        </Grid>
                        <Grid mt={5} item md={6}>
                            <FormControl fullWidth mt={1}>
                                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                <Select
                                    value={adminPayload.role}
                                    onChange={(e) => setPayload({ ...adminPayload, role: e.target.value })}
                                    fullWidth
                                >
                                    <MenuItem value="admin" selected>
                                        Admin
                                    </MenuItem>
                                    {/* <MenuItem>Super</MenuItem> */}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid pt={5} mt={2} align="center" item md={12}>
                            <Button
                                style={{ borderRadius: 20, paddingRight: 100, paddingLeft: 100 }}
                                disableElevation
                                disabled={isCreating}
                                // fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                onClick={handleCreate}
                            >
                                {isCreating ? 'Creating Admin' : ' Create Admin'}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Grid container spacing={2} mt={5} ml={2} paddingX={1} alignItems="center" justifyContent={'start'}>
                <Grid align="left" container justifyContent="end" alignItems={'center'} my={2} mx={2}>
                    <Grid item xs={8}>
                        <TextField placeholder="Search Admin" />
                    </Grid>
                    <Grid item xs={4} align={'right'}>
                        <Typography color={'blue'} gutterBottom onClick={handleOpen} className={'hover'}>
                            Create Admin
                        </Typography>
                    </Grid>
                </Grid>
                <div className="myContainer">
                    {isFetching ? (
                        <Grid align={'center'}>Fetching Admins</Grid>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Image</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell align="left">Phone</TableCell>
                                        <TableCell align="left">Status</TableCell>
                                        {/* <TableCell align="left">Action</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {admins.map((row, key) => {
                                        // const currStatus = row?.status.toString().toLowerCase();
                                        return (
                                            <TableRow key={key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell align="left">
                                                    <img src={row?.user?.pictureUrl} alt="" className="tripItemPic" />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Link to={'/admin/admin/' + row?._id}>
                                                        {row?.user?.firstName} {row?.user?.lastName}
                                                    </Link>
                                                </TableCell>
                                                <TableCell align="left">{row?.user?.email}</TableCell>
                                                <TableCell align="left">{row?.user?.phoneNumber}</TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Grid container spacing={1}>
                                                        <Grid item>
                                                            <Typography color={'green'} fontSize={16} gutterBottom>
                                                                <font size={6}>&#8226;</font>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography color={'black'} gutterBottom>
                                                                {row?.status}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </div>
                <Grid container mt={3} align={'right'} justifyContent={'end'}>
                    <Stack spacing={2}>
                        <Pagination
                            count={pagination.pages}
                            color="secondary"
                            page={pagination.page}
                            onChange={async (e, v) => {
                                const thisParam = { ...params, page: v };
                                setParams(thisParam);
                                getAdmins(thisParam);
                            }}
                        />
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Admins;
