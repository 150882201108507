import React from 'react';
import { useState } from 'react';
// material-ui
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Paper,
    Table,
    Modal,
    Box
} from '@mui/material';
import { useEffect } from 'react';
import OTPInput from 'ui-component/OtpField';
import SuccessIcon from '../../../../assets/images/icons/success_icon.svg';
import { ToastContainer, toast } from 'react-toastify';
import { DriverWalletService } from 'services/driver/wallet_service';

const DriverWithrawalComponent = ({ wallet, bank, bankList, handleClose, fetchWallet }) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: 4,
        boxShadow: 24,
        p: 4
    };
    const [fetchingAccount, setFetchingAccount] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [step, setStep] = useState(1);
    const [accountInfo, setAccountInfo] = useState({});
    const [withdrawPayload, setWthdrawPayload] = useState({
        amount: 0
    });
    useEffect(() => {
        setStep(1);
    }, []);

    const fetchAccountInfo = async (data) => {
        setFetchingAccount(true);
        setAccountInfo({ ...accountInfo, accountName: '' });
        // alert(JSON.stringify(data));
        // return;
        const accResponse = await DriverWalletService.accountEnquiry({
            bankCode: data?.bankCode ?? accountInfo?.bankCode,
            accountNumber: data?.accountNumber ?? accountInfo?.accountNumber
        });
        setFetchingAccount(false);
        if (!accResponse?.success) {
            toast.error(accResponse.message);
            return;
        }
        setAccountInfo({
            ...accountInfo,
            ...accResponse.data,
            bankCode: data?.bankCode ?? accountInfo?.bankCode,
            accountNumber: data?.accountNumber ?? accountInfo?.accountNumber
        });
    };
    const saveAccount = async () => {
        setSubmitting(true);
        alert(JSON.stringify(accountInfo));
        const accResponse = await DriverWalletService.saveAccount({
            bankCode: accountInfo?.bankCode,
            accountNumber: accountInfo?.accountNumber
        });
        setSubmitting(false);
        if (!accResponse?.success) {
            toast.error(accResponse.message);
            return;
        }
        toast.success(accResponse.message);
        fetchWallet();
        setStep(3);
    };
    const withdraw = async () => {
        setSubmitting(true);
        const accResponse = await DriverWalletService.withdraw(withdrawPayload);
        setSubmitting(false);
        if (!accResponse?.success) {
            toast.error(accResponse.message);
            return;
        }
        toast.success(accResponse.message);
        handleClose();
    };
    return (
        <div>
            <ToastContainer />
            {step == 1 ? (
                <Box sx={style}>
                    <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                        Wallet Withdraw
                    </Typography>
                    <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                        Enter Amount to Withdraw
                    </Typography>
                    <Grid>
                        <Grid mt={5}>
                            <TextField
                                fullWidth
                                label="Amount"
                                id="fullWidth"
                                type="number"
                                value={withdrawPayload?.amount}
                                onChange={(e) => setWthdrawPayload({ ...withdrawPayload, amount: e?.target.value })}
                            />
                        </Grid>
                        <Grid pt={5}>
                            <Button
                                style={{ borderRadius: 10, paddingRight: 100, paddingLeft: 100 }}
                                disableElevation
                                // disabled={isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    if (withdrawPayload.amount == '' || withdrawPayload.amount < 1) {
                                        toast.error('Invalid Amount');
                                        return;
                                    }
                                    if (!bank) {
                                        setStep(2);
                                    } else {
                                        // Initiate Transfer
                                        withdraw();
                                    }
                                }}
                            >
                                {submitting ? 'Submitting...' : 'Next'}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            ) : step == 2 ? (
                // Second
                <Box sx={style}>
                    <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                        Account Information
                    </Typography>

                    <Grid>
                        {/* <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                            Account Number
                        </Typography> */}
                        <Grid mt={3}>
                            <TextField
                                fullWidth
                                label="Account Number"
                                id="fullWidth"
                                type="number"
                                value={accountInfo?.accountNumber}
                                onChange={(e) => {
                                    setAccountInfo({ ...accountInfo, accountNumber: e.target.value });
                                    if (e.target.value.length >= 10 && accountInfo?.bankCode) {
                                        fetchAccountInfo({ accountNumber: e.target.value });
                                    }
                                }}
                            />
                        </Grid>
                        {/* <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                            Choose Bank
                        </Typography> */}
                        <Grid mt={3}>
                            <FormControl fullWidth mt={1}>
                                <InputLabel id="demo-simple-select-label">Choose bank</InputLabel>
                                <Select
                                    // value={accountInfo?.bankCode}
                                    onChange={(e) => {
                                        setAccountInfo({ ...accountInfo, bankCode: e.target.value });
                                        if (e.target.value && accountInfo?.accountNumber >= 10) {
                                            fetchAccountInfo({ bankCode: e.target.value });
                                        }
                                    }}
                                    label={'Bank'}
                                >
                                    {bankList.map((val, key) => (
                                        <MenuItem value={val?.code}>{val?.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Typography id="keep-mounted-modal-description" fontSize={14} sx={{ mt: 1 }}>
                            {fetchingAccount ? 'Fetching Account info...' : accountInfo?.accountName ?? ''}
                        </Typography>
                        <Grid pt={5}>
                            <Button
                                style={{ borderRadius: 10, paddingRight: 100, paddingLeft: 100 }}
                                disableElevation
                                // disabled={isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    if (!accountInfo?.accountName || accountInfo?.accountName.length < 1) {
                                        toast.error('Invalid Account info');
                                        return;
                                    }
                                    // setStep(3);
                                    saveAccount();
                                }}
                            >
                                {submitting ? 'Submitting...' : 'Proceed'}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            ) : step == 3 ? (
                // Second
                <Box sx={style}>
                    <Grid direction="column" container justifyContent={'center'} alignItems={'center'}>
                        <Typography id="keep-mounted-modal-title" variant="h6" mb={2} component="h2">
                            Enter transaction pin
                        </Typography>
                        <Grid>
                            <OTPInput length={4} />
                        </Grid>
                        <Grid pt={3}>
                            <Button
                                style={{ borderRadius: 10, paddingRight: 100, paddingLeft: 100 }}
                                disableElevation
                                // disabled={isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                onClick={() => withdraw()}
                            >
                                Proceed
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                // Second
                <Box sx={style}>
                    <Grid justifyContent={'center'} alignItems={'center'} direction="column" container>
                        <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                            Transaction successful!
                        </Typography>
                        <Grid mt={3}>
                            <img src={SuccessIcon} alt="" style={{ height: 120 }} />
                        </Grid>
                        <Typography id="keep-mounted-modal-title" color={'#286bce'} variant="h4" mt={3} component="h2">
                            View Reciept
                        </Typography>
                        {/* <Grid pt={5}>
                        <Button
                            style={{ borderRadius: 20, paddingRight: 100, paddingLeft: 100 }}
                            disableElevation
                            // disabled={isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="secondary"
                            onClick={() => navigate('/dashboard')}
                        >
                            Finish
                        </Button>
                    </Grid> */}
                    </Grid>
                </Box>
            )}
        </div>
    );
};

export default DriverWithrawalComponent;
