import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Divider, Grid, Stack, TextField, Typography, useMediaQuery } from '@mui/material';

// project imports
import DragDrop from 'ui-component/dragdrop';
import { AuthService } from 'services/auth_service';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const AddDriversLicense = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const [verifiData, setVerifiData] = useState({ licenseNumber: '', expireDate: '' });
    const [selectedFile, setSelectedFile] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const handleUpload = async () => {
        const localUser = localStorage.getItem('userInfo');
        const usr = JSON.parse(localUser);
        if (verifiData?.licenseNumber == '' || verifiData?.expireDate == '') {
            toast.error('Fields cannot be empty');
            return;
        }

        if (!selectedFile) {
            toast.error('File not selected');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('licenseNumber', verifiData?.licenseNumber);
        formData.append('expireDate', verifiData?.expireDate);

        setSubmitting(true);
        const verResponse = await AuthService.uploadDriverLicense(formData);
        setSubmitting(false);
        if (!verResponse.success) {
            toast.error(verResponse.message);
            return;
        }
        toast.success(verResponse.message);
        navigate('/set-car-details');
    };
    return (
        <div>
            <ToastContainer />
            <Grid item xs={12} md={12}>
                <Grid
                    direction="column"
                    container
                    spacing={2}
                    alignItems="center"
                    style={{ height: '100vh' }}
                    justifyContent="center"
                    // sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}
                >
                    <Grid item md={2}></Grid>
                    <Grid item md={8}>
                        <Grid>
                            <Typography
                                color={theme.palette.secondary.main}
                                gutterBottom
                                variant={matchDownSM ? 'h3' : 'h2'}
                                align="center"
                            >
                                Please upload a valid driver’s license
                            </Typography>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item md={6} mt={5}>
                                <Typography variant="caption" fontSize="12px" textAlign={matchDownSM ? 'center' : 'inherit'}>
                                    Identification Number
                                </Typography>
                                <TextField
                                    fullWidth
                                    label=""
                                    type="number"
                                    id="fullWidth"
                                    onChange={(e) => setVerifiData({ ...verifiData, licenseNumber: e?.target?.value })}
                                />
                            </Grid>
                            <Grid item md={6} mt={5}>
                                <Typography variant="caption" fontSize="12px" textAlign={matchDownSM ? 'center' : 'inherit'}>
                                    EXP date
                                </Typography>
                                <TextField
                                    fullWidth
                                    label=""
                                    type="date"
                                    id="fullWidth"
                                    onChange={(e) => setVerifiData({ ...verifiData, expireDate: e?.target?.value })}
                                />
                            </Grid>
                            <Grid item md={12} mt={5} align="center">
                                <DragDrop onChange={(e) => setSelectedFile(e?.target?.files[0])}></DragDrop>
                                {/* <img src={uploadIcon} height={80} alt="" />
                                <Grid mt={3}>
                                    <Typography
                                        variant="caption"
                                        color={'black'}
                                        fontSize="14px"
                                        fontWeight={'bold'}
                                        textAlign={'center'}
                                        align="center"
                                    >
                                        Drag & drop files or <font color={theme.palette.secondary.main}>Browse</font>
                                    </Typography>
                                </Grid>
                                <Grid mt={1}>
                                    <Typography variant="caption" fontSize="12px" textAlign={'center'} align="center">
                                        Drag & drop files or Browse
                                    </Typography>
                                </Grid> */}
                            </Grid>
                        </Grid>
                        <Grid pt={5} align="center">
                            <Button
                                style={{ borderRadius: 20, paddingRight: 100, paddingLeft: 100 }}
                                disableElevation
                                disabled={submitting}
                                onClick={handleUpload}
                                // fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                            >
                                {submitting ? 'Loading' : 'Next'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default AddDriversLicense;
