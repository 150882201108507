import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Divider, Grid, Stack, TextField, Typography, useMediaQuery } from '@mui/material';

// project imports
import AuthWrapper1 from '../AuthWrapper1';
import AuthCardWrapper from '../AuthCardWrapper';
import AuthLogin from '../auth-forms/AuthLogin';
import Logo from 'ui-component/Logo';
import AuthFooter from 'ui-component/cards/AuthFooter';
import LoginBg from '../../../../assets/images/login_bg.png';
import LogoBg from '../../../../assets/images/logo_bg.png';
import AppLogo from '../../../../assets/images/app_logo.png';
import OTPInput from 'ui-component/OtpField';
import '../../../../assets/css/customStyle.css';
import MainCard from 'ui-component/cards/MainCard';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';

// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const OnboardSummary = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const [accountType, setAccountType] = useState('traveller');
    const [userInfo, setUserInfo] = useState('traveller');
    const customization = useSelector((state) => state.customization);

    useEffect(() => {
        const localUser = localStorage.getItem('userInfo');
        const usr = JSON.parse(localUser);
        // alert(localUser);
        setUserInfo(usr);
        setAccountType(customization?.userInfo?.accountType || usr?.accountType);
    }, []);
    return (
        <div>
            <Grid container direction="row" justifyContent="flex">
                <Grid md={3}></Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    container
                    spacing={2}
                    alignItems="center"
                    style={{ height: '100vh', width: '100%' }}
                    justifyContent="center"
                >
                    <div style={{ width: '100%' }}>
                        <div className="summaryContainer">
                            <Grid container direction={'row'} alignItems="center" justifyContent="center" spacing={5}>
                                <Grid item>
                                    <img src={userInfo?.pictureUrl != null ? userInfo?.pictureUrl : LogoBg} alt="" className="progilePic" />
                                </Grid>
                                <Grid item>
                                    <Grid>
                                        <Typography
                                            variant="caption"
                                            fontSize="16px"
                                            fontWeight={'bold'}
                                            // textAlign={matchDownSM ? 'center' : 'inherit'}
                                        >
                                            Full Name
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            color={'black'}
                                            fontSize="16px"
                                            paddingLeft={3}
                                            // textAlign={matchDownSM ? 'center' : 'inherit'}
                                        >
                                            {userInfo?.firstName} {userInfo?.lastName}
                                        </Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography
                                            variant="caption"
                                            fontSize="16px"
                                            fontWeight={'bold'}
                                            // textAlign={matchDownSM ? 'center' : 'inherit'}
                                        >
                                            Email
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            color={'black'}
                                            fontSize="16px"
                                            paddingLeft={3}
                                            // textAlign={matchDownSM ? 'center' : 'inherit'}
                                        >
                                            {userInfo?.email}
                                        </Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography
                                            variant="caption"
                                            fontSize="16px"
                                            fontWeight={'bold'}
                                            // textAlign={matchDownSM ? 'center' : 'inherit'}
                                        >
                                            Phone
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            color={'black'}
                                            fontSize="16px"
                                            paddingLeft={3}
                                            // textAlign={matchDownSM ? 'center' : 'inherit'}
                                        >
                                            {userInfo?.phoneNumber}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{ marginTop: 40 }} alignItems="center" justifyContent="center" align="center">
                            <Button
                                style={{ borderRadius: 10, paddingRight: 100, paddingLeft: 100 }}
                                disableElevation
                                // disabled={isSubmitting}
                                // fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    // alert(accountType);
                                    navigate(accountType == 'driver' ? '/add-drivers-license' : '/set-pin');
                                }}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default OnboardSummary;
