import { useEffect, useState } from 'react';

// material-ui
import {
    Button,
    Grid,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Paper,
    Table,
    Modal,
    Box,
    Pagination
} from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
// ==============================|| DEFAULT USERDASHBOARD ||============================== //
import { Stack } from '@mui/system';
import { AdminTransactionService } from 'services/admin/transaction_service';
import { Configs } from 'constants/configs';
const Transactions = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: 4,
        boxShadow: 24,
        p: 2
    };
    const [modalAction, setModalAction] = useState('fund');
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setModalAction('');
        setOpen(false);
    };

    const [isLoading, setLoading] = useState(true);
    const [isFetching, setFetching] = useState(true);
    const [transactions, setTransactions] = useState([]);
    const [params, setParams] = useState({
        page: 1
    });
    const [pagination, setPagination] = useState({
        page: 1,
        pages: 1,
        prev: 0,
        next: 0,
        total: 1
    });

    useEffect(() => {
        setLoading(false);
        getTransactions(params);
    }, []);

    const getTransactions = async (params) => {
        setFetching(true);
        const userResponse = await AdminTransactionService.getAll(params);
        setFetching(false);
        if (!userResponse.success) {
            return;
        }
        setTransactions(userResponse.data);
        setPagination(userResponse.pagination);
    };
    return (
        <Grid container spacing={gridSpacing}>
            <Grid container spacing={2} mt={5} ml={2} paddingX={1} alignItems="center" justifyContent={'start'}>
                <Grid align="left" justifyContent="start" my={2}>
                    <TextField placeholder="Search" />
                </Grid>
                <div className="myContainer">
                    {/* <Typography color={'black'} gutterBottom _variant={'h2'}>
                        <font size={5}>Transactions</font>
                    </Typography> */}
                    {isFetching ? (
                        <Grid align={'center'}>Fetching Transactions</Grid>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Action</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell align="left">Amount</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell align="left">Channel</TableCell>
                                        <TableCell align="left">Date</TableCell>
                                        <TableCell align="left">Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {transactions.map((trx, key) => {
                                        return (
                                            <TableRow key={key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell align="left">{trx?.action}</TableCell>
                                                <TableCell align="left">{trx?.description}</TableCell>
                                                <TableCell align="left">₦{trx?.amount}</TableCell>
                                                <TableCell align="left">{trx?.transactionType}</TableCell>
                                                <TableCell align="left">{trx?.channel}</TableCell>
                                                <TableCell align="left">{Configs.tripDate(trx?.createdAt)}</TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Grid container spacing={1}>
                                                        <Grid item>
                                                            <Typography
                                                                color={
                                                                    trx?.status == 'pending'
                                                                        ? 'orange'
                                                                        : trx?.status == 'success'
                                                                        ? 'green'
                                                                        : 'red'
                                                                }
                                                                fontSize={16}
                                                                gutterBottom
                                                            >
                                                                <font size={6}>&#8226;</font>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography color={'black'} gutterBottom>
                                                                {trx?.status}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </div>
                <Grid container mt={3} align={'right'} justifyContent={'end'}>
                    <Stack spacing={2}>
                        <Pagination
                            count={pagination.pages}
                            color="secondary"
                            page={pagination.page}
                            onChange={async (e, v) => {
                                const thisParam = { ...params, page: v };
                                setParams(thisParam);
                                getTransactions(thisParam);
                            }}
                        />
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Transactions;
