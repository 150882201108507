import React from 'react';
import { useState } from 'react';
// material-ui
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Paper,
    Table,
    Modal,
    Box
} from '@mui/material';
import { useEffect } from 'react';
import WalletContainer from 'views/user/component/WalletContainer';
import PaymentOptionContainer from 'views/user/component/PaymentOptionContainer';
import cardIcon from '../../../../assets/images/card_icon.png';
import bankIcon from '../../../../assets/images/bank_icon.png';
import { TravellerWalletService } from 'services/traveller/wallet_service';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router';

const UserFundingComponent = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: 4,
        boxShadow: 24,
        p: 4
    };

    const [step, setStep] = useState(1);
    const [fundData, setFundData] = useState({});
    const [funding, setFunding] = useState(false);
    const [fundingAmount, setFundingAmount] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        setStep(1);
    }, []);

    const fundWallet = async () => {
        setFunding(true);
        const fundResponse = await TravellerWalletService.fund({ amount: fundingAmount });
        setFunding(false);
        if (!fundResponse.success) {
            toast.error(fundResponse.message);
            return;
        }
        toast.success(fundResponse.message);
        // Redirect fundResponse.data.checkoutUrl

        window.location.href = fundResponse.data?.checkoutUrl;
    };
    return (
        <div>
            <ToastContainer />
            {step == 1 ? (
                <Box sx={style}>
                    <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                        Wallet Fund
                    </Typography>
                    <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                        Enter Amount to Fund
                    </Typography>
                    <Grid>
                        <Grid mt={5}>
                            <TextField
                                fullWidth
                                label="Amount"
                                id="fullWidth"
                                type="number"
                                value={fundingAmount}
                                onChange={(e) => setFundingAmount(e.target.value)}
                            />
                        </Grid>
                        <Grid pt={5}>
                            <Button
                                style={{ borderRadius: 10, paddingRight: 100, paddingLeft: 100 }}
                                disableElevation
                                disabled={funding}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    if (fundingAmount == '' || fundingAmount < 1) {
                                        toast.error('Invalid Amount');
                                        return;
                                    }
                                    fundWallet();
                                    // setStep(2);
                                }}
                            >
                                {funding ? 'Loading please wait...' : 'Next'}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            ) : step == 2 ? (
                // Second
                <Box sx={style}>
                    <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                        Choose Payment Option
                    </Typography>
                    <Grid container spacing={2} mt={3} alignItems="center">
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <PaymentOptionContainer data={{ title: 'Card', icon: cardIcon }} onClick={() => alert('Coming Soon')} />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <PaymentOptionContainer data={{ title: 'Bank Account', icon: bankIcon }} onClick={() => setStep(3)} />
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                // Second
                <Box sx={style}>
                    <Typography id="keep-mounted-modal-title" variant="h3" mb={2} component="h2">
                        Account Info
                    </Typography>
                    <Typography id="keep-mounted-modal-title" variant="h5" component="h2">
                        Account Name: Mister Abdul
                    </Typography>
                    <Typography id="keep-mounted-modal-title" variant="h5" component="h2">
                        Account Name: 0987654321
                    </Typography>
                    <Typography id="keep-mounted-modal-title" variant="h5" component="h2" mb={3}>
                        Bank Name: AB Bank
                    </Typography>
                    <Button
                        style={{ borderRadius: 10, paddingRight: 100, paddingLeft: 100 }}
                        disableElevation
                        // disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="secondary"
                    >
                        I have Made Transfer
                    </Button>
                </Box>
            )}
        </div>
    );
};

export default UserFundingComponent;
