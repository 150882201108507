import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import UserDashboard from 'views/user/dashboard';
import UserTrips from 'views/user/trip';
import UserWallet from 'views/user/wallet';
import UserSingleTrip from 'views/user/trip/SingleTrip';
import UserTransactions from 'views/user/wallet/UserTransactions';
import UserProfile from 'views/user/settings/UserProfile';
import UserSettings from 'views/user/settings/UserSettings';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //
const prefix = 'traveller';
const UserRoutes = [
    // Test
    {
        path: '',
        element: <UserDashboard />, //<DashboardDefault />,
        prefix: ''
    },
    // {
    //     path: 'dashboard',
    //     element: <UserDashboard />,
    //     prefix: ''
    // },
    // Routes
    {
        path: '',
        element: <UserDashboard />,
        prefix
    },
    {
        path: 'my-trips',
        element: <UserTrips />,
        prefix
    },
    {
        path: 'trip/:id',
        element: <UserSingleTrip />,
        prefix
    },
    {
        path: 'wallet',
        element: <UserWallet />,
        prefix
    },
    {
        path: 'transactions',
        element: <UserTransactions />,
        prefix
    },
    {
        path: 'profile',
        element: <UserProfile />,
        prefix
    },
    {
        path: 'settings',
        element: <UserSettings />,
        prefix
    }
];

export default UserRoutes;
