import React from 'react';
import { useState } from 'react';
// material-ui
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Paper,
    Table,
    Modal,
    Box
} from '@mui/material';
import { useEffect } from 'react';
import OTPInput from 'ui-component/OtpField';
import SuccessIcon from '../../../../assets/images/icons/success_icon.svg';

const UserWithrawalComponent = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: 4,
        boxShadow: 24,
        p: 4
    };
    const [step, setStep] = useState(1);

    useEffect(() => {
        setStep(1);
    }, []);
    return (
        <div>
            {step == 1 ? (
                <Box sx={style}>
                    <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                        Wallet Withdraw
                    </Typography>
                    <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                        Enter Amount to Withdraw
                    </Typography>
                    <Grid>
                        <Grid mt={5}>
                            <TextField fullWidth label="Amount" id="fullWidth" type="number" />
                        </Grid>
                        <Grid pt={5}>
                            <Button
                                style={{ borderRadius: 10, paddingRight: 100, paddingLeft: 100 }}
                                disableElevation
                                // disabled={isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                onClick={() => setStep(2)}
                            >
                                Next
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            ) : step == 2 ? (
                // Second
                <Box sx={style}>
                    <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                        Account Information
                    </Typography>

                    <Grid>
                        {/* <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                            Account Number
                        </Typography> */}
                        <Grid mt={3}>
                            <TextField fullWidth label="Account Number" id="fullWidth" type="number" />
                        </Grid>
                        {/* <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                            Choose Bank
                        </Typography> */}
                        <Grid mt={3}>
                            <TextField fullWidth label="Choose Bank" id="fullWidth" type="text" />
                        </Grid>
                        <Typography id="keep-mounted-modal-description" fontSize={14} sx={{ mt: 1 }}>
                            User Info
                        </Typography>
                        <Grid pt={5}>
                            <Button
                                style={{ borderRadius: 10, paddingRight: 100, paddingLeft: 100 }}
                                disableElevation
                                // disabled={isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                onClick={() => setStep(3)}
                            >
                                Proceed
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            ) : step == 3 ? (
                // Second
                <Box sx={style}>
                    <Grid direction="column" container justifyContent={'center'} alignItems={'center'}>
                        <Typography id="keep-mounted-modal-title" variant="h6" mb={2} component="h2">
                            Enter transaction pin
                        </Typography>
                        <Grid>
                            <OTPInput length={4} />
                        </Grid>
                        <Grid pt={3}>
                            <Button
                                style={{ borderRadius: 10, paddingRight: 100, paddingLeft: 100 }}
                                disableElevation
                                // disabled={isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                onClick={() => setStep(4)}
                            >
                                Proceed
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                // Second
                <Box sx={style}>
                    <Grid justifyContent={'center'} alignItems={'center'} direction="column" container>
                        <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                            Transaction successful!
                        </Typography>
                        <Grid mt={3}>
                            <img src={SuccessIcon} alt="" style={{ height: 120 }} />
                        </Grid>
                        <Typography id="keep-mounted-modal-title" color={'#286bce'} variant="h4" mt={3} component="h2">
                            View Reciept
                        </Typography>
                        {/* <Grid pt={5}>
                        <Button
                            style={{ borderRadius: 20, paddingRight: 100, paddingLeft: 100 }}
                            disableElevation
                            // disabled={isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="secondary"
                            onClick={() => navigate('/dashboard')}
                        >
                            Finish
                        </Button>
                    </Grid> */}
                    </Grid>
                </Box>
            )}
        </div>
    );
};

export default UserWithrawalComponent;
