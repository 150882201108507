import { useEffect, useState } from 'react';

// material-ui
import {
    Button,
    Grid,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Paper,
    Table,
    Modal,
    Box
} from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import TripItemCard from 'ui-component/cards/TripItem';
import DashBoardDetailComponent from '../component/DashBoardDetailComponent';
import WalletContainer from '../component/WalletContainer';
import { height } from '@mui/system';
import addIcon from '../../../assets/images/add_icon.png';
import deductIcon from '../../../assets/images/deduct_icon.png';
import clockIcon from '../../../assets/images/clock_icon.png';
import UserWithrawalComponent from './component/UserWithrawalComponent';
import UserFundingComponent from './component/UserFundingComponent';
import { useNavigate } from 'react-router';
import { TravellerWalletService } from 'services/traveller/wallet_service';
import { Configs } from 'constants/configs';
import { useSelector } from 'react-redux';
// ==============================|| DEFAULT USERDASHBOARD ||============================== //

const UserWallet = () => {
    const [modalAction, setModalAction] = useState('fund');
    const customization = useSelector((state) => state.customization);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setModalAction('');
        setOpen(false);
    };
    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);
    const [fetching, setFetching] = useState(false);
    function tableData(type, icon, amount, date) {
        return { icon, type, amount, date };
    }

    const rows = [
        tableData('Credit', 159, 6.0, '2:00pm 19-jan-2024'),
        tableData('Debit', 237, 9.0, '2:00pm 19-jan-2024'),
        tableData('Credit', 262, 16.0, '2:00pm 19-jan-2024'),
        tableData('Credit', 305, 3.7, '2:00pm 19-jan-2024'),
        tableData('Debit', 356, 16.0, '2:00pm 19-jan-2024')
    ];
    const [wallet, setWallet] = useState({});
    const [transactions, setTransactions] = useState([]);
    useEffect(() => {
        setLoading(false);
        getWaller();
    }, []);

    const getWaller = async () => {
        setFetching(true);
        const walletResponse = await TravellerWalletService.info({});
        setFetching(false);

        if (!walletResponse.success) {
        }

        setWallet(walletResponse.data?.wallet);
        setTransactions(walletResponse.data?.transactions);
    };
    return (
        <Grid container spacing={gridSpacing}>
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                {modalAction == 'fund' ? (
                    <>
                        <UserFundingComponent />
                    </>
                ) : (
                    <UserWithrawalComponent />
                )}
            </Modal>
            <Grid item xs={12} mt={5}>
                <WalletContainer>
                    <Grid
                        container
                        spacing={gridSpacing}
                        style={{ height: 200 }}
                        paddingY={'2'}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography color={'white'} gutterBottom fontSize={18} align="center">
                                Balance
                            </Typography>
                            <Typography color={'white'} gutterBottom variant={'h1'} align="center">
                                {Configs.currencyFormat(wallet?.balance ?? 0)}
                            </Typography>
                            <Typography color={'white'} gutterBottom fontSize={16} align="center">
                                {customization?.userInfo?.firstName} {customization?.userInfo?.lastName}
                            </Typography>
                        </Grid>
                    </Grid>
                </WalletContainer>
            </Grid>
            <Grid container spacing={5} mt={3} paddingLeft={3} alignItems="center">
                {[
                    {
                        title: 'Fund Wallet',
                        icon: addIcon,
                        onClick: () => {
                            setModalAction('fund');
                            handleOpen();
                        }
                    },
                    // {
                    //     title: 'Withdraw',
                    //     icon: deductIcon,
                    //     onClick: () => {
                    //         setModalAction('withdraw');
                    //         handleOpen();
                    //     }
                    // },
                    { title: 'Transactions', icon: clockIcon, onClick: () => navigate('/traveller/transactions') }
                ].map((item, key) => {
                    return (
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <WalletContainer>
                                <Grid
                                    className="hover"
                                    onClick={item?.onClick}
                                    container
                                    spacing={gridSpacing}
                                    style={{ height: 200 }}
                                    paddingY={'2'}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <div align="center">
                                            <img src={item?.icon} height={60} alt="" />
                                        </div>
                                        <Typography color={'white'} gutterBottom fontSize={18} mt={2} align="center">
                                            {item?.title}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </WalletContainer>
                        </Grid>
                    );
                })}
            </Grid>

            {/* <Grid container spacing={2} mt={3} ml={1} paddingY={2} paddingX={4} alignItems="center">
                <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                    Recent transactions
                </Typography>
            </Grid> */}

            <Grid container spacing={2} mt={5} ml={2} paddingX={1} alignItems="center">
                <div className="myContainer">
                    <Typography color={'black'} gutterBottom _variant={'h2'}>
                        <font size={5}>Transactions</font>
                    </Typography>
                    {fetching ? (
                        <Grid align={'center'}>
                            <h3>Fetching Transactions</h3>
                        </Grid>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {/* <TableCell align="left"></TableCell> */}
                                        <TableCell>Reference</TableCell>
                                        <TableCell>Transaction Type</TableCell>
                                        <TableCell>Action</TableCell>
                                        <TableCell align="left">Amount&nbsp;</TableCell>
                                        <TableCell align="left">Date</TableCell>
                                        <TableCell align="left">Status&nbsp;</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {transactions.map((row) => (
                                        <TableRow key={row?.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="left">{row?.reference}</TableCell>
                                            <TableCell align="left">{row?.transactionType}</TableCell>
                                            <TableCell component="th" scope="row">
                                                <font color={row?.action.toString().toLowerCase() == 'credit' ? 'green' : 'red'}>
                                                    {row?.action}
                                                </font>
                                            </TableCell>
                                            <TableCell align="left">{Configs.currencyFormat(row?.amount ?? 0)}</TableCell>
                                            <TableCell align="left">{Configs.dateConvert(row.createdAt)}</TableCell>
                                            <TableCell align="left">{row?.status}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </div>
            </Grid>
        </Grid>
    );
};

export default UserWallet;
