import { useEffect, useState } from 'react';

// material-ui
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Switch, TextField, Typography } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import samplePic from '../../../assets/images/sample_pic.png';
import { IconCalendar, IconCalendarEvent, IconEdit, IconMenu, IconSettings, IconUser } from '@tabler/icons';

// ==============================|| DEFAULT USERDASHBOARD ||============================== //

const GeneralSettings = () => {
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
    }, []);

    return (
        <Grid container spacing={gridSpacing}>
            <Grid container spacing={2} paddingX={5} pt={3}>
                <Grid container spacing={1} mt={3} mx={3} direction={'row'} justifyContent={'start'}>
                    <Grid my={0.0001} mr={1}>
                        <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom align="center">
                            <IconSettings />
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography color={'black'} fontWeight={'normal'} fontSize={20} gutterBottom align="left">
                            Settings
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <div className="mySettingsContainer">
                        <Grid item xs={12}>
                            <Grid container spacing={2} paddingX={5}>
                                <Grid xs={12} mt={2} mb={3} mx={4}>
                                    <Grid container spacing={1} direction={'row'} justifyContent={'center'}>
                                        {/* <Grid my={0.0001} mr={1}>
                                            <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom align="center">
                                                <IconUser />
                                            </Typography>
                                        </Grid> */}
                                        <Grid>
                                            <Typography color={'black'} fontWeight={'bold'} fontSize={20} gutterBottom align="left">
                                                General Profile
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography color={'black'} fontWeight={'normal'} fontSize={16} gutterBottom>
                                        Commission
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} align={'right'}>
                                    <Grid container spacing={1} direction={'row'} justifyContent={'end'}>
                                        <Grid>
                                            <Typography color={'black'} fontWeight={'bold'} fontSize={20} gutterBottom align="left">
                                                2%
                                            </Typography>
                                        </Grid>
                                        <Grid my={0.0001} ml={1} align={'right'}>
                                            <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom align="center">
                                                <IconEdit color="blue" />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography color={'black'} fontWeight={'normal'} fontSize={16} gutterBottom>
                                        Suspend all activity on platform
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} align={'right'}>
                                    <Switch value={false} onChange={(v) => console.log(v)} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default GeneralSettings;
