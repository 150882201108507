import axios from 'axios';
import { util } from 'constants/api.constant';
import { baseUrl } from 'constants/constants';

const token = localStorage.getItem('token');
const prefix = '';
let TravellerAccountService = {
    profile: async (data) => {
        try {
            const resp = await axios.get(baseUrl + `/${prefix}profile`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successufl',
                data: resp?.data?.data,
                trips: resp?.data?.trips,
                schedules: resp?.data?.schedules,
                requests: resp?.data?.requests,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    }
};

export { TravellerAccountService };
