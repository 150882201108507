import React from 'react';

// material-ui
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import successIcon from '../../../assets/images/success_icon.png';

import { gridSpacing } from 'store/constant';
const PaymentOptionContainer = ({ data, onClick }) => {
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <div className="paymentOptionContainer">
                    <Grid
                        onClick={() => {
                            return onClick();
                        }}
                        className="hover"
                        container
                        spacing={1}
                        // style={{ height: 100 }}
                        paddingY={'2'}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item lg={2} md={2} sm={2} xs={2}>
                            <img src={data?.icon ?? successIcon} height={30} width={35} alt="" />
                        </Grid>
                        <Grid item lg={10} md={10} sm={10} xs={10}>
                            <Typography gutterBottom fontSize={18} my={2} ml={3} align="left">
                                {data?.title ?? 'Card Payment'}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
};

export default PaymentOptionContainer;
