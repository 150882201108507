import { useEffect, useState } from 'react';

// material-ui
import {
    Button,
    Grid,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Paper,
    Table,
    Modal,
    Box,
    Pagination
} from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
// ==============================|| DEFAULT USERDASHBOARD ||============================== //
import approveIcon from '../../../assets/images/approve_icon.svg';
import rejectIcon from '../../../assets/images/reject_icon.svg';
import { Stack } from '@mui/system';
import { Link } from 'react-router-dom';
import { AdminUserService } from 'services/admin/user_service';
import { ToastContainer, toast } from 'react-toastify';
const Users = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: '2px solid #ffffff',
        borderRadius: 4,
        boxShadow: 24,
        p: 2
    };
    const [modalAction, setModalAction] = useState('');
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setModalAction('');
        setOpen(false);
    };

    const [isLoading, setLoading] = useState(true);
    const [takingAction, setTakingAction] = useState(false);
    const [selectedDriver, setSelectedDriver] = useState({});
    const [isFetching, setFetching] = useState(true);
    const [drivers, setDrivers] = useState([]);
    const [params, setParams] = useState({
        page: 1
    });
    const [pagination, setPagination] = useState({
        page: 1,
        pages: 1,
        prev: 0,
        next: 0,
        total: 1
    });

    useEffect(() => {
        setLoading(false);
        getDrivers();
    }, []);

    const getDrivers = async (params) => {
        setFetching(true);
        const userResponse = await AdminUserService.getAllAdrivers(params);
        setFetching(false);
        if (!userResponse.success) {
            return;
        }
        setDrivers(userResponse.data);
        setPagination(userResponse.pagination);
    };

    const tripAction = async () => {
        setTakingAction(true);
        const userResponse = await AdminUserService.updateStatus(selectedDriver?._id, { status: modalAction ?? 'approved' });
        setTakingAction(false);
        if (!userResponse.success) {
            toast.error(userResponse.message);
            return;
        }
        toast.success(userResponse.message);
        getDrivers(params);
        handleClose();
    };
    return (
        <Grid container spacing={gridSpacing}>
            <ToastContainer />
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <Typography id="keep-mounted-modal-title" variant="h3" component="h2" align="center">
                        Are you sure you want to {'Approve'} {selectedDriver?.user?.firstName} {selectedDriver?.user?.lastName}?
                    </Typography>
                    <Grid my={5} container spacing={2}>
                        <Grid item xs={6} align={'center'}>
                            <Button
                                style={{ borderRadius: 20 }}
                                disableElevation
                                disabled={takingAction}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                onClick={tripAction}
                            >
                                {takingAction ? 'Loading..' : 'Yes'}
                            </Button>
                        </Grid>
                        <Grid item xs={6} align={'center'}>
                            <Button
                                style={{ borderRadius: 20 }}
                                disableElevation
                                // disabled={isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="error"
                                onClick={handleClose}
                            >
                                No
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Grid container spacing={2} mt={5} ml={2} paddingX={1} alignItems="center" justifyContent={'start'}>
                <Grid align="left" justifyContent="start" my={2}>
                    <TextField placeholder="Search" />
                </Grid>
                <div className="myContainer">
                    {/* <Typography color={'black'} gutterBottom _variant={'h2'}>
                        <font size={5}>Transactions</font>
                    </Typography> */}
                    {isFetching ? (
                        <Grid align={'center'}>Fetching Drivers</Grid>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Image</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell align="left">Number of Trips</TableCell>
                                        <TableCell align="left">Phone</TableCell>
                                        <TableCell align="left">Status</TableCell>
                                        <TableCell align="left">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {drivers.map((row, key) => {
                                        const currStatus = row?.status.toString().toLowerCase();
                                        return (
                                            <TableRow key={key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell align="left">
                                                    <img src={row?.user?.pictureUrl} alt="" className="tripItemPic" />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Link to={'/admin/driver/' + row?._id}>
                                                        {row?.user?.firstName} {row?.user?.lastName}
                                                    </Link>
                                                </TableCell>
                                                <TableCell align="left">{row?.user?.email}</TableCell>
                                                <TableCell align="left">{row?.numberOfTrips}</TableCell>
                                                <TableCell align="left">{row?.user?.phoneNumber}</TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Grid container spacing={1}>
                                                        <Grid item>
                                                            <Typography color={'green'} fontSize={16} gutterBottom>
                                                                <font size={6}>&#8226;</font>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography color={'black'} gutterBottom>
                                                                {row?.status}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Grid container>
                                                        {row?.status == 'pending' ? (
                                                            <Grid
                                                                item
                                                                onClick={() => {
                                                                    setModalAction('approved');
                                                                    setSelectedDriver(row);
                                                                    handleOpen();
                                                                }}
                                                            >
                                                                <img
                                                                    className="hover"
                                                                    src={approveIcon}
                                                                    height={30}
                                                                    alt=""
                                                                    style={{ marginRight: 15 }}
                                                                />
                                                            </Grid>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {/* <Grid
                                                            item
                                                            onClick={() => {
                                                                handleOpen();
                                                            }}
                                                        >
                                                            <img className="hover" src={rejectIcon} height={30} alt="" />
                                                        </Grid> */}
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </div>
                <Grid container mt={3} align={'right'} justifyContent={'end'}>
                    <Stack spacing={2}>
                        <Pagination count={3} color="secondary" />
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Users;
