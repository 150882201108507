import { useEffect, useState } from 'react';

// material-ui
import { Button, CardContent, FormControl, Grid, InputLabel, MenuItem, Rating, Select, TextField, Typography } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import WalletContainer from 'views/user/component/WalletContainer';
import { IconStar, IconUser } from '@tabler/icons';
import ReactApexChart from 'react-apexcharts';
import TotalGrowthBarChart from './TotalGrowthBarChart';
import { AdminAccountsService } from 'services/admin/admin_account_service';

// ==============================|| DEFAULT ADMINDASHBOARD ||============================== //

const AdminDashboard = () => {
    const [isLoading, setLoading] = useState(true);
    const [ageChart, setAgeChart] = useState({
        series: [],
        options: {
            chart: {
                type: 'donut'
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }
            ]
        }
    });
    const [genderChart, setGenderChart] = useState({
        series: [],
        options: {
            chart: {
                type: 'donut'
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }
            ]
        }
    });

    const customBarchart = {
        series: [
            // {
            //     name: 'Net Profit',
            //     data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
            // },
            // {
            //     name: 'Revenue',
            //     data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
            // },
            {
                name: 'Monthly Income',
                data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
            }
        ],
        options: {
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct']
            },
            yaxis: {
                title: {
                    text: '$ (thousands)'
                }
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return '$ ' + val + ' thousands';
                    }
                }
            }
        }
    };

    const [fetchingAnaytic, setFetchingAnalytics] = useState(false);
    const [analytics, setAnalytics] = useState({
        monthlyIncome: [],
        userAgeDistribution: [],
        userGenderDistribution: [],
        driverCount: 0,
        travellerCount: 0
    });
    useEffect(() => {
        setLoading(false);
        fetchAnalytics();
    }, []);

    const fetchAnalytics = async () => {
        setFetchingAnalytics(true);
        const analyticData = await AdminAccountsService.analytics({});
        setFetchingAnalytics(false);

        if (!analyticData.success) {
            return;
        }
        setAnalytics(analyticData?.data);
        const extrcUserGenderDistribution = analyticData?.data.userGenderDistribution.map((item, key) => item.count);
        setGenderChart({ ...genderChart, series: extrcUserGenderDistribution });

        const extrcuserAgeDistribution = analyticData?.data.userAgeDistribution.map((item, key) => item.count);
        setAgeChart({ ...ageChart, series: extrcuserAgeDistribution });
        // alert(JSON.stringify(extrc));
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} md={8}>
                        <Grid mb={2}>
                            <MainCard content={false}>
                                <CardContent>
                                    <Typography color={'black'} gutterBottom align="left" fontWeight={'normal'} fontSize={18}>
                                        Monthly Income
                                    </Typography>
                                    <ReactApexChart
                                        options={customBarchart.options}
                                        series={customBarchart.series}
                                        type="bar"
                                        height={350}
                                    />
                                </CardContent>
                            </MainCard>
                        </Grid>
                        {/* <TotalGrowthBarChart isLoading={false} /> */}
                        <MainCard content={false}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item md={6}>
                                        <Typography color={'black'} gutterBottom align="center" fontWeight={'normal'} fontSize={18}>
                                            Age Distribution
                                        </Typography>
                                        <ReactApexChart options={ageChart.options} series={ageChart.series} type="donut" width={380} />
                                    </Grid>
                                    <Grid item md={6}>
                                        <Typography color={'black'} gutterBottom align="center" fontWeight={'normal'} fontSize={18}>
                                            Gender Distribution
                                        </Typography>
                                        <ReactApexChart
                                            options={genderChart.options}
                                            series={genderChart.series}
                                            type="donut"
                                            width={380}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </MainCard>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {/* <PopularCard isLoading={isLoading} /> */}
                        <MainCard content={false}>
                            <CardContent>
                                <Grid spacing={2} paddingX={1} alignItems="center">
                                    <Typography color={'black'} gutterBottom align="center" fontWeight={'bold'} fontSize={15}>
                                        Service charge
                                    </Typography>
                                    <Typography color={'blue'} gutterBottom align="center" fontWeight={'bold'} fontSize={20}>
                                        2%
                                    </Typography>
                                    <Typography color={'grey'} gutterBottom align="center" fontWeight={'normal'} fontSize={15}>
                                        Last changed 2:00pm 02-02-2022
                                    </Typography>
                                </Grid>
                            </CardContent>
                        </MainCard>

                        <Grid mt={3}>
                            <WalletContainer>
                                <Grid container p={3}>
                                    <Grid item xs={12} alignItems={'center'} justifyContent={'center'} pb={3}>
                                        <Typography color={'white'} gutterBottom align="center" fontWeight={'normal'} fontSize={20}>
                                            <IconUser size={25} /> Users
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography color={'white'} gutterBottom align="left" fontWeight={'normal'} fontSize={20}>
                                            Drivers
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} align={'right'}>
                                        <Typography color={'white'} gutterBottom align="right" fontWeight={'normal'} fontSize={20}>
                                            {analytics?.driverCount}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography color={'white'} gutterBottom align="left" fontWeight={'normal'} fontSize={20}>
                                            Travellers
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} align={'right'}>
                                        <Typography color={'white'} gutterBottom align="right" fontWeight={'normal'} fontSize={20}>
                                            {analytics?.travellerCount}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </WalletContainer>
                        </Grid>
                        <Grid mt={3}>
                            <MainCard content={false}>
                                <CardContent>
                                    <Grid container p={1}>
                                        <Grid item xs={12} alignItems={'center'} justifyContent={'center'} pb={3}>
                                            <Typography color={'black'} gutterBottom align="center" fontWeight={'normal'} fontSize={20}>
                                                <IconStar color="orange" fill="orange" size={25} /> App stats
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography color={'black'} gutterBottom align="left" fontWeight={'normal'} fontSize={20}>
                                                Downloads
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} align={'left'}>
                                            <Typography color={'black'} gutterBottom align="left" fontWeight={'normal'} fontSize={20}>
                                                -
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography color={'black'} gutterBottom align="left" fontWeight={'normal'} fontSize={20}>
                                                Reviews
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} align={'left'}>
                                            <Typography color={'black'} gutterBottom align="left" fontWeight={'normal'} fontSize={20}>
                                                -
                                            </Typography>
                                        </Grid>{' '}
                                        <Grid item xs={8}>
                                            <Typography color={'black'} gutterBottom align="left" fontWeight={'normal'} fontSize={20}>
                                                Ratings
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} align={'left'}>
                                            <Rating
                                                disabled={false}
                                                value={4}
                                                onChange={(event, newValue) => {
                                                    // setValue(newValue);
                                                }}
                                                name="size-small"
                                                defaultValue={2}
                                                size="small"
                                                readOnly
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </MainCard>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid container spacing={2} mt={2} paddingY={2} paddingX={5} alignItems="center">
                <Grid item xs={11}>
                    <Typography color={'black'} gutterBottom align="center" fontWeight={'bold'} fontSize={18}>
                        Scheduled Trips
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography color={'blue'} gutterBottom>
                        View All
                    </Typography>
                </Grid>
            </Grid>

            <Grid container spacing={2} paddingX={5} alignItems="center">
                {sampleTrip.map((item, key) => {
                    return (
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <TripItemCard data={item} />
                        </Grid>
                    );
                })}
            </Grid> */}
        </Grid>
    );
};

export default AdminDashboard;
