import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
// import DriverDashboard from 'views/driver/dashboard';
import DriverTrips from 'views/driver/trip';
import DriverSingleTrip from 'views/driver/trip/SingleTrip';
import DriverWallet from 'views/driver/wallet';
// import DriverTransactions from 'views/driver/wallet/UserTransactions';
import DriverProfile from 'views/driver/settings/DriverProfile';
import DriverSettings from 'views/driver/settings';
import DriverScheduleTrip from 'views/driver/trip/ScheduleTrip';
import DriverTravellers from 'views/driver/trip/DriverTravellers';

// dashboard routing
const DriverDashboard = Loadable(lazy(() => import('views/driver/dashboard')));

const DriverTransactions = Loadable(lazy(() => import('views/driver/wallet/DriverTransactions')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //
const prefix = 'driver';
const DriverRoutes = [
    {
        path: '',
        element: <DriverDashboard />,
        prefix
    },
    {
        path: 'dashboard',
        element: <DriverDashboard />,
        prefix
    },
    {
        path: 'my-trips',
        element: <DriverTrips />,
        prefix
    },
    {
        path: 'trip/:id',
        element: <DriverSingleTrip />,
        prefix
    },
    {
        path: 'schedule-trips',
        element: <DriverScheduleTrip />,
        prefix
    },
    {
        path: 'wallet',
        element: <DriverWallet />,
        prefix
    },
    {
        path: 'transactions',
        element: <DriverTransactions />,
        prefix
    },
    {
        path: 'profile',
        element: <DriverProfile />,
        prefix
    },
    {
        path: 'settings',
        element: <DriverSettings />,
        prefix
    },
    {
        path: 'trip/:id/requests',
        element: <DriverTravellers />,
        prefix
    }
];

export default DriverRoutes;
