import { useEffect, useState } from 'react';

// material-ui
import {
    Button,
    Grid,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Paper,
    Table,
    Modal,
    Box,
    Pagination,
    Select,
    MenuItem
} from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
// ==============================|| DEFAULT USERDASHBOARD ||============================== //
import approveIcon from '../../../assets/images/approve_icon.svg';
import rejectIcon from '../../../assets/images/reject_icon.svg';
import samplePic from '../../../assets/images/sample_pic.png';
import { Stack } from '@mui/system';
import { Link } from 'react-router-dom';
import { IconEdit, IconRecycle, IconTrash } from '@tabler/icons';
const SingleState = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: 4,
        boxShadow: 24,
        p: 2
    };
    const [modalAction, setModalAction] = useState('fund');
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setModalAction('');
        setOpen(false);
    };

    const [isLoading, setLoading] = useState(true);
    function tableData(name, officer, type, status, stops, date) {
        return { name, officer, status, type, stops, date };
    }

    const rows = [
        tableData('Stop 1', 'ShehuIbrahim99@gmail.com', 'Credit', 'pending', '4', ''),
        tableData('Stop 2', 'ShehuIbrahim99@gmail.com', 'Debit', 'successful', '4', ''),
        tableData('Stop 3', 'ShehuIbrahim99@gmail.com', 'Credit', 'rejected', '4', ''),
        tableData('Stop 4', 'ShehuIbrahim99@gmail.com', 'Credit', 'successful', '4', ''),
        tableData('Stop 5', 'ShehuIbrahim99@gmail.com', 'Debit', 'pending', '4', '')
    ];
    useEffect(() => {
        setLoading(false);
    }, []);

    return (
        <Grid container spacing={gridSpacing}>
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                        Create State
                    </Typography>

                    <Grid>
                        <Grid mt={5}>
                            <TextField fullWidth label="Name" id="fullWidth" type="text" />
                        </Grid>
                        <Grid mt={5}>
                            <Select value={'Role'} label={'Officer'} fullWidth>
                                <MenuItem value="officer" selected>
                                    Officer
                                </MenuItem>
                                <MenuItem>Super</MenuItem>
                            </Select>
                        </Grid>
                        <Grid pt={5} align="center">
                            <Button
                                style={{ borderRadius: 20, paddingRight: 100, paddingLeft: 100 }}
                                disableElevation
                                // disabled={isSubmitting}
                                // fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                // onClick={() => setStep(2)}
                            >
                                Create State
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Grid container spacing={2} mt={5} ml={2} paddingX={1} alignItems="center" justifyContent={'start'}>
                <Grid align="left" container justifyContent="end" alignItems={'center'} my={2} mx={2}>
                    <Grid item xs={4}>
                        <TextField placeholder="Search Stop" />
                    </Grid>
                    <Grid item xs={4} align="center">
                        <Typography color={'black'} fontSize={20} gutterBottom align="center">
                            Stop
                        </Typography>
                    </Grid>
                    <Grid item xs={4} align={'right'}>
                        <Typography color={'blue'} gutterBottom onClick={handleOpen} className="hover">
                            Create Stop
                        </Typography>
                    </Grid>
                </Grid>
                <div className="myContainer">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell>Image</TableCell> */}
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell align="left">Number of Stops</TableCell>
                                    {/* <TableCell align="left">Status</TableCell> */}
                                    <TableCell align="left">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => {
                                    const currStatus = row.status.toString().toLowerCase();
                                    return (
                                        <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            {/* <TableCell align="left">
                                                <img src={samplePic} alt="" className="tripItemPic" />
                                            </TableCell> */}
                                            <TableCell align="left">
                                                <Link to={'/admin/zone/1'}>{row.name}</Link>
                                            </TableCell>
                                            <TableCell align="left">{row.officer}</TableCell>
                                            <TableCell align="left">{row.stops}</TableCell>
                                            <TableCell component="th" scope="row">
                                                <Link to={'/admin/zone/1'}>
                                                    <IconEdit color="skyblue" className="hover" />
                                                </Link>
                                                &nbsp;&nbsp;
                                                <IconTrash color="red" className="hover" />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <Grid container mt={3} align={'right'} justifyContent={'end'}>
                    <Stack spacing={2}>
                        <Pagination count={10} color="secondary" />
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SingleState;
