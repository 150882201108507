import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import VerifyAccount from 'views/pages/authentication/authentication3/VerifyAccount';
import VerifyIdentity from 'views/pages/authentication/authentication3/VerifyIdentity';
import SetProfilePic from 'views/pages/authentication/authentication3/SetProfilePic';
import OnboardSummary from 'views/pages/authentication/authentication3/OnboardSummary';
import SetPin from 'views/pages/authentication/authentication3/SetPin';
import SuccessRegistration from 'views/pages/authentication/authentication3/SuccessRegistration';
import AddDriversLicense from 'views/pages/authentication/authentication3/AddDriversLicense';
import SetCarDetails from 'views/pages/authentication/authentication3/SetCarDetails';
import UploadCarImage from 'views/pages/authentication/authentication3/UploadCarImage';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

// ==============================|| MAIN ROUTING ||============================== //
const prefix = '';
const AuthRoutes = [
    {
        path: 'login',
        element: <AuthLogin3 />,
        prefix
    },
    {
        path: 'register', //'pages/register/register3',
        element: <AuthRegister3 />,
        prefix
    },
    {
        path: 'verify-account',
        element: <VerifyAccount />,
        prefix
    },
    {
        path: 'verify-identity',
        element: <VerifyIdentity />,
        prefix
    },
    {
        path: 'set-profile-pic',
        element: <SetProfilePic />,
        prefix
    },
    {
        path: 'onboard-summary',
        element: <OnboardSummary />,
        prefix
    },
    {
        path: 'set-pin',
        element: <SetPin />,
        prefix
    },
    {
        path: 'success-registration',
        element: <SuccessRegistration />,
        prefix
    },
    {
        path: 'add-drivers-license',
        element: <AddDriversLicense />,
        prefix
    },
    {
        path: 'set-car-details',
        element: <SetCarDetails />,
        prefix
    },
    {
        path: 'upload-car-image',
        element: <UploadCarImage />,
        prefix
    }
];

export default AuthRoutes;
