import { useEffect, useState } from 'react';

// material-ui
import {
    Button,
    Grid,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Paper,
    Table,
    Modal,
    Box,
    Pagination
} from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
// ==============================|| DEFAULT USERDASHBOARD ||============================== //
import approveIcon from '../../../assets/images/approve_icon_2.svg';
import rejectIcon from '../../../assets/images/reject_icon_2.svg';
import samplePic from '../../../assets/images/sample_pic.png';
import { Stack } from '@mui/system';
import { Link } from 'react-router-dom';
const DriverRequests = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: 4,
        boxShadow: 24,
        p: 2
    };
    const [modalAction, setModalAction] = useState('fund');
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setModalAction('');
        setOpen(false);
    };

    const [isLoading, setLoading] = useState(true);
    function tableData(name, email, type, carModel, phone, date) {
        return { name, email, carModel, type, phone, date };
    }

    const rows = [
        tableData('Shehu Ibrahim', 'ShehuIbrahim99@gmail.com', 'Credit', 'Honda 2982', '000088889999', ''),
        tableData('Shehu Ibrahim', 'ShehuIbrahim99@gmail.com', 'Debit', 'Honda 2982', '000088889999', ''),
        tableData('Shehu Ibrahim', 'ShehuIbrahim99@gmail.com', 'Credit', 'Honda 2982', '000088889999', ''),
        tableData('Shehu Ibrahim', 'ShehuIbrahim99@gmail.com', 'Credit', 'Honda 2982', '000088889999', ''),
        tableData('Shehu Ibrahim', 'ShehuIbrahim99@gmail.com', 'Debit', 'Honda 2982', '000088889999', '')
    ];
    useEffect(() => {
        setLoading(false);
    }, []);

    return (
        <Grid container spacing={gridSpacing}>
            <Grid container spacing={2} mt={5} ml={2} paddingX={1} alignItems="center" justifyContent={'start'}>
                <Grid align="left" justifyContent="start" my={2}>
                    <TextField placeholder="Search" />
                </Grid>
                <div className="myContainer">
                    {/* <Typography color={'black'} gutterBottom _variant={'h2'}>
                        <font size={5}>Transactions</font>
                    </Typography> */}
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Image</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell align="left">Phone</TableCell>
                                    <TableCell align="left">Car Model</TableCell>
                                    <TableCell align="left">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => {
                                    return (
                                        <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="left">
                                                <img src={samplePic} alt="" className="tripItemPic" />
                                            </TableCell>
                                            <TableCell align="left">{row.name}</TableCell>
                                            <TableCell align="left">{row.email}</TableCell>
                                            <TableCell align="left">{row.phone}</TableCell>
                                            <TableCell component="th" scope="row">
                                                {row?.carModel}
                                            </TableCell>
                                            <TableCell>
                                                <img src={approveIcon} height={30} alt="" style={{ marginRight: 15 }} />
                                                <img src={rejectIcon} height={30} alt="" />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <Grid container mt={3} align={'right'} justifyContent={'end'}>
                    <Stack spacing={2}>
                        <Pagination count={10} color="secondary" />
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DriverRequests;
