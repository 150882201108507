// assets
import { IconKey, IconSettings, IconLogout } from '@tabler/icons';

// constant
const icons = {
    IconKey,
    IconSettings,
    IconLogout
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const driverOther = {
    id: 'pages',
    title: 'Others',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'settings',
            title: 'Settings',
            type: 'item',
            url: '/driver/settings',
            icon: icons.IconSettings,
            breadcrumbs: false
        },
        {
            id: 'logout',
            title: 'Logout',
            type: 'item',
            url: '/login',
            icon: icons.IconLogout,
            breadcrumbs: false
        }
    ]
};

export default driverOther;
