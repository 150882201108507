import { useEffect, useState } from 'react';

// material-ui
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Switch, TextField, Typography } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import samplePic from '../../../assets/images/sample_pic.png';
import { IconPencil } from '@tabler/icons';
import { useSelector } from 'react-redux';

// ==============================|| DEFAULT USERDASHBOARD ||============================== //

const DriverSettings = () => {
    const [isLoading, setLoading] = useState(true);
    const [userInfo, setUserInfo] = useState('traveller');
    const customization = useSelector((state) => state.customization);
    useEffect(() => {
        setLoading(false);
        const localUser = localStorage.getItem('userInfo');
        const usr = JSON.parse(localUser);
        // alert(localUser);
        setUserInfo(usr);
        setUserInfo(customization?.userInfo || usr);
    }, []);

    return (
        <Grid container spacing={gridSpacing}>
            <Grid container spacing={2} mt={2} ml={1} paddingTop={2} paddingX={5}>
                <Typography color={'black'} fontWeight={'bold'} fontSize={25} gutterBottom>
                    Settings
                </Typography>
            </Grid>
            <Grid container spacing={2} mt={2} ml={1} paddingBottom={2} paddingX={5}>
                <Typography color={'black'} fontWeight={'bold'} fontSize={18} gutterBottom>
                    Account
                </Typography>
            </Grid>

            <Grid container spacing={2} paddingX={5} pt={3}>
                <Grid item xs={12} my={2}>
                    <div className="mySettingsContainer">
                        <Grid container spacing={2} paddingX={5} pt={3}>
                            <Grid item md={5}>
                                <Typography color={'black'} fontWeight={'bold'} fontSize={20} gutterBottom>
                                    View profile
                                </Typography>
                                <Typography color={'grey'} fontWeight={'normal'} fontSize={14} gutterBottom>
                                    Business owner with multiple branches around the country. Enjoys traveling a lot
                                </Typography>
                            </Grid>
                            <Grid item md={2}>
                                -
                            </Grid>
                            <Grid item md={5}>
                                <Grid container spacing={2} paddingX={5} mt={1}>
                                    <Grid item xs={10} alignItems={'start'}>
                                        <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                            Full Name
                                        </Typography>
                                        <Typography color={'grey'} fontWeight={'normal'} fontSize={14} gutterBottom>
                                            {customization?.userInfo?.firstName} {customization?.userInfo?.lastName}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} alignItems={'center'}>
                                        <Grid item xs={2} alignItems={'right'}>
                                            <IconPencil color="blue" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} paddingX={5} mt={1}>
                                    <Grid item xs={10} alignItems={'start'}>
                                        <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                            Email
                                        </Typography>
                                        <Typography color={'grey'} fontWeight={'normal'} fontSize={14} gutterBottom>
                                            {customization?.userInfo?.email}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} alignItems={'center'}>
                                        <Grid item xs={2} alignItems={'right'}>
                                            <IconPencil color="blue" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} paddingX={5} mt={1}>
                                    <Grid item xs={10} alignItems={'start'}>
                                        <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                            Phone
                                        </Typography>
                                        <Typography color={'grey'} fontWeight={'normal'} fontSize={14} gutterBottom>
                                            {customization?.userInfo?.phoneNumber}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} alignItems={'right'}>
                                        <IconPencil color="blue" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                {/* Advance Settings */}
                <Grid item xs={12} my={2}>
                    <div className="mySettingsContainer">
                        <Grid container spacing={2} paddingX={5} pt={3}>
                            <Grid item md={5}>
                                <Typography color={'black'} fontWeight={'bold'} fontSize={20} gutterBottom>
                                    Advance Details
                                </Typography>
                                <Typography color={'grey'} fontWeight={'normal'} fontSize={14} gutterBottom>
                                    Select or change your address
                                </Typography>
                            </Grid>
                            <Grid item md={2}>
                                -
                            </Grid>
                            <Grid item md={5}>
                                <Grid container spacing={2} paddingX={5} mt={1}>
                                    <Grid item xs={12} alignItems={'start'}>
                                        <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                            State
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} alignItems={'start'}>
                                        <TextField fullWidth placeholder="FCT" disabled={true} value={customization?.userInfo?.state} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} paddingX={5} mt={1}>
                                    <Grid item xs={12} alignItems={'start'}>
                                        <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                            City
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} alignItems={'start'}>
                                        <TextField fullWidth placeholder="ABUJA" disabled={true} value={customization?.userInfo?.city} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} paddingX={5} mt={1}>
                                    <Grid item xs={12} alignItems={'start'}>
                                        <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                            Address
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} alignItems={'start'}>
                                        <TextField
                                            fullWidth
                                            placeholder="Full Address"
                                            disabled={true}
                                            value={customization?.userInfo?.address}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                {/* Notification */}
                <Grid item xs={12} my={2}>
                    <div className="mySettingsContainer">
                        <Grid container spacing={2} paddingX={5} pt={3}>
                            <Grid item md={5}>
                                <Typography color={'black'} fontWeight={'bold'} fontSize={20} gutterBottom>
                                    Notifications
                                </Typography>
                                <Typography color={'grey'} fontWeight={'normal'} fontSize={14} gutterBottom>
                                    Change the notification settings for your account and emails
                                </Typography>
                            </Grid>
                            <Grid item md={2}>
                                -
                            </Grid>
                            <Grid item md={5}>
                                <Grid container spacing={2} paddingX={5} mt={1}>
                                    <Grid item xs={10} alignItems={'start'}>
                                        <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                            Email me when a trip has expired
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} alignItems={'center'}>
                                        <Switch checked={true} onChange={(v) => alert(v)} inputProps={{ 'aria-label': 'controlled' }} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} paddingX={5} mt={1}>
                                    <Grid item xs={10} alignItems={'start'}>
                                        <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                            Email me when a trip is booked
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} alignItems={'center'}>
                                        <Switch checked={true} onChange={(v) => alert(v)} inputProps={{ 'aria-label': 'controlled' }} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} paddingX={5} mt={1}>
                                    <Grid item xs={10} alignItems={'start'}>
                                        <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                            Email me when a trip is completed
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} alignItems={'center'}>
                                        <Switch checked={true} onChange={(v) => alert(v)} inputProps={{ 'aria-label': 'controlled' }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid container justifyContent={'center'} spacing={2} paddingX={5} my={5} pt={3}>
                    <Button
                        style={{ borderRadius: 20 }}
                        disableElevation
                        // disabled={isSubmitting}
                        // fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="secondary"
                        // onClick={handleOpen}
                    >
                        Switch To Car Owner
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DriverSettings;
