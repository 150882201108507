import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
// import Routes from 'routes';
import { Route, Routes, useNavigate } from 'react-router-dom';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import SetProfilePic from 'views/pages/authentication/authentication3/SetProfilePic';
import Dashboard from 'views/dashboard/Default';
import UserDashboard from 'views/user/dashboard';
import MainLayout from 'layout/MainLayout';
import UserRoutes from 'routes/UserRoutes';
import AuthRoutes from 'routes/AuthRoutes';
import PageNotFound from 'views/PageNotFound';
import DriverRoutes from 'routes/DriverRoutes';
import ProtectedRoute from 'utils/ProtectedRoute';
import AdminRoutes from 'routes/AdminRoutes';
import LandingPage from 'views/landing';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const accountType = customization?.userInfo?.accountType;
    const allRoutes = [...UserRoutes, ...DriverRoutes, ...AdminRoutes];
    const authRoutes = AuthRoutes;
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    {/* <MainLayout> */}
                    <Routes>
                        {/* <Route path="/test" element={<UserDashboard />} /> */}
                        <Route path="/" element={<LandingPage />} />
                        <Route
                            path="/test"
                            element={
                                <MainLayout>
                                    <Dashboard />
                                </MainLayout>
                            }
                        />
                        {authRoutes.map((route, key) => (
                            <Route path={'/' + route?.path} element={route?.element} key={key} />
                        ))}
                        <Route element={<MainLayout />}>
                            {allRoutes.map((route, key) => (
                                <Route
                                    path={'/' + route?.prefix + '/' + route?.path}
                                    element={<ProtectedRoute prefix={route?.prefix}>{route?.element}</ProtectedRoute>}
                                    key={key}
                                />
                            ))}
                            <Route path="/user*" element={<div>Page Not FOund</div>} />
                            <Route path="/admin*" element={<div>Page Not FOund</div>} />
                            <Route path="/driver*" element={<div>Page Not FOund</div>} />
                        </Route>
                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                    {/* </MainLayout> */}
                    {/* <Routes /> */}
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
