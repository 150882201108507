import axios from 'axios';
import { util } from 'constants/api.constant';
import { baseUrl } from 'constants/constants';

const token = localStorage.getItem('token');
const prefix = 'driver';
let DriverTripService = {
    createTrip: async (data) => {
        try {
            const resp = await axios.post(baseUrl + `/${prefix}/trips/schedules`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successufl',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    startTrip: async (data) => {
        try {
            const resp = await axios.post(baseUrl + `/${prefix}/trips`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successufl',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    locations: async (data) => {
        try {
            const resp = await axios.get(baseUrl + `/${prefix}/locations`, {
                params: data,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successufl',
                data: resp?.data?.data,
                pagination: resp?.data?.pagination,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    myTrips: async (data) => {
        try {
            const resp = await axios.get(baseUrl + `/${prefix}/trips/schedules/me`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successufl',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    singleTrip: async (id) => {
        try {
            const resp = await axios.get(baseUrl + `/${prefix}/trips/schedules/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successufl',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    requests: async (id) => {
        try {
            const resp = await axios.get(baseUrl + `/${prefix}/trips/schedules/${id}/requests`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successufl',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    tripBySchedule: async (id) => {
        try {
            const resp = await axios.get(baseUrl + `/${prefix}/trips/schedule?scheduleId=${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successufl',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    tripAction: async (data) => {
        try {
            const resp = await axios.put(
                baseUrl + `/${prefix}/trips/schedules/${data?.id}/requests/${data?.requestId}/${data?.action}`,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            );
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successufl',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    updateStatus: async (id, data) => {
        try {
            const resp = await axios.put(baseUrl + `/${prefix}/trips/${id}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successufl',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    }
};

export { DriverTripService };
