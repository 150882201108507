import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Divider, Grid, Stack, TextField, Typography, useMediaQuery } from '@mui/material';

import DragDrop from 'ui-component/dragdrop';

const UploadCarImage = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();

    return (
        <div>
            <Grid item xs={12} md={12}>
                <Grid
                    direction="column"
                    container
                    spacing={2}
                    alignItems="center"
                    style={{ height: '100vh' }}
                    justifyContent="center"
                    // sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}
                >
                    <Grid item md={2}></Grid>
                    <Grid item md={8}>
                        <Grid>
                            <Typography color={theme.palette.secondary.main} gutterBottom fontSize={20} align="center">
                                Please upload a car image
                            </Typography>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item md={12} mt={5} align="center">
                                <DragDrop onChange={(e) => {}}></DragDrop>
                                {/* <img src={uploadIcon} height={80} alt="" />
                                <Grid mt={3}>
                                    <Typography
                                        variant="caption"
                                        color={'black'}
                                        fontSize="14px"
                                        fontWeight={'bold'}
                                        textAlign={'center'}
                                        align="center"
                                    >
                                        Drag & drop files or <font color={theme.palette.secondary.main}>Browse</font>
                                    </Typography>
                                </Grid>
                                <Grid mt={1}>
                                    <Typography variant="caption" fontSize="12px" textAlign={'center'} align="center">
                                        Drag & drop files or Browse
                                    </Typography>
                                </Grid> */}
                            </Grid>
                        </Grid>
                        <Grid pt={5} align="center">
                            <Button
                                style={{ borderRadius: 20, paddingRight: 100, paddingLeft: 100 }}
                                disableElevation
                                // disabled={isSubmitting}
                                onClick={() => navigate('/set-pin')}
                                // fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                            >
                                Next
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default UploadCarImage;
