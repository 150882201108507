import { useEffect, useState } from 'react';

// material-ui
import { Button, FormControl, Grid, InputLabel, MenuItem, Modal, Rating, Select, TextField, Typography } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import TripItemCard from 'ui-component/cards/TripItem';
import DashBoardDetailComponent from '../component/DashBoardDetailComponent';
import { Box } from '@mui/system';
import { useNavigate, useParams } from 'react-router';
import PaymentOptionContainer from '../component/PaymentOptionContainer';
import cardIcon from '../../../assets/images/card_icon.png';
import bankIcon from '../../../assets/images/bank_icon.png';
import samplePic from '../../../assets/images/sample_pic.png';
import BookInfoItem from 'ui-component/cards/BookInfoItem';
import vehicleImage from '../../../assets/images/vehicle.svg';
import endPointImage from '../../../assets/images/end_point.svg';
import seatPriceImage from '../../../assets/images/price_perseat.svg';
import timeImage from '../../../assets/images/time.svg';
import calenderImage from '../../../assets/images/calender.svg';
import startPointImage from '../../../assets/images/start_point.svg';
import locationImage from '../../../assets/images/map_location.svg';
import { TravellerTripService } from 'services/traveller/trip_service';
import { ToastContainer, toast } from 'react-toastify';
import { Configs } from 'constants/configs';

// ==============================|| DEFAULT USERDASHBOARD ||============================== //

const UserSingleTrip = () => {
    const [isLoading, setLoading] = useState(true);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: 4,
        boxShadow: 24,
        p: 4
    };

    const [step, setStep] = useState(1);
    const [open, setOpen] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [requesting, setRequesting] = useState(false);
    const [reviewing, setReviewing] = useState(false);
    const [schedule, setSchedule] = useState(null);
    const [selected, setSelected] = useState([]);
    const [alternatePhone, setAlternatePhone] = useState('');
    const [reviewPayload, setReviewPayload] = useState({
        ratings: 1,
        comment: ''
    });

    const { id } = useParams();
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setLoading(false);
        fetchTrip();
    }, []);

    const fetchTrip = async () => {
        setFetching(true);
        const tripResponse = await TravellerTripService.singleTrip(id);
        setFetching(false);
        if (!tripResponse.success) {
            toast.error(tripResponse.message);
            return;
        }
        setSchedule(tripResponse.data);
    };
    const requestTrip = async () => {
        // alert(JSON.stringify(selected));
        // return;
        if (alternatePhone == '') {
            toast.error('Phone number required');
            return;
        }
        if (selected.length < 1) {
            toast.error('You need to select atleast one seat');
            return;
        }
        setRequesting(true);

        const tripResponse = await TravellerTripService.requestTrip(id, {
            phoneNumber: alternatePhone,
            seats: selected
        });
        setRequesting(false);
        if (!tripResponse.success) {
            toast.error(tripResponse.message);
            return;
        }
        toast.success(tripResponse.message);
        fetchTrip();
    };

    const handleReview = async () => {
        setReviewing(true);
        const tripResponse = await TravellerTripService.addReview(schedule?.trip?._id, reviewPayload);
        setReviewing(false);
        if (!tripResponse.success) {
            toast.error(tripResponse.message);
            return;
        }
        toast.success(tripResponse.message);
        handleClose();
        fetchTrip();
    };
    return (
        <Grid container spacing={gridSpacing}>
            <ToastContainer />
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                {step == 1 ? (
                    <Box sx={style}>
                        <Typography id="keep-mounted-modal-title" variant="h3" component="h2" align="center">
                            Make Booking
                        </Typography>
                        {/* <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }} component="h2" align="center">
                        Alternate Phone Number
                    </Typography> */}
                        <Grid container alignItems={'center'} justifyContent={'center'} my={2} xs={{ m: 2 }} md={{ m: 10 }}>
                            <TextField
                                fullWidth
                                placeholder="Alternate Phone Number"
                                value={alternatePhone}
                                onChange={(e) => setAlternatePhone(e.target.value)}
                            />
                        </Grid>

                        <Typography id="keep-mounted-modal-description" sx={{ mt: 3 }}>
                            Select Your Seat
                        </Typography>
                        <Grid container my={2}>
                            {(schedule?.seats ?? []).map((item, key) => {
                                return (
                                    <Grid
                                        item
                                        lg={2}
                                        md={2}
                                        sm={3}
                                        xs={3}
                                        my={2}
                                        mx={1}
                                        onClick={() => {
                                            // alert(JSON.stringify(item._id));
                                            if (item.status != 'available') {
                                                toast.error('This seat is unavailable');
                                                return;
                                            }
                                            if (selected.includes(item._id)) {
                                                // Remove
                                                let allSelected = selected.filter((thisItem) => {
                                                    // if (item._id != thisItem) return thisItem;
                                                    return item._id != thisItem;
                                                });
                                                // allSelected.splice(key, 1);
                                                // alert(JSON.stringify(allSelected));
                                                setSelected(allSelected);
                                                return;
                                            }
                                            setSelected([...selected, item._id]);
                                        }}
                                    >
                                        <div
                                            className={
                                                key % 2 == 0
                                                    ? `seatOptionActive hover${selected.includes(item._id) ? ' seatOptionSelected' : ''}${
                                                          item.status != 'available' ? ' seatOptionUnavailable' : ''
                                                      }`
                                                    : `seatOption hover${selected.includes(item._id) ? ' seatOptionSelected' : ''}${
                                                          item.status != 'available' ? ' seatOptionUnavailable' : ''
                                                      }`
                                            }
                                        >
                                            {/* <Typography fontWeight={'normal'} component="h2" align="center">
                                                Seat 1
                                            </Typography>{' '} */}
                                            <Typography fontWeight={'normal'} component="h2" align="center">
                                                {item?.description}
                                            </Typography>
                                            <Typography
                                                // id="keep-mounted-modal-title"
                                                fontWeight={'bold'}
                                                // variant="h3"
                                                fontSize={18}
                                                mt={2}
                                                component="h2"
                                                align="center"
                                            >
                                                {Configs.currencyFormat(item?.price ?? 0)}
                                            </Typography>
                                        </div>
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <Grid justifyContent={'end'} container>
                            <Button onClick={handleClose}>Close</Button>
                            <Button
                                style={{ borderRadius: 10 }}
                                disableElevation
                                disabled={requesting}
                                // fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                onClick={requestTrip}
                            >
                                {requesting ? 'Requesting...' : 'Continue'}
                            </Button>
                        </Grid>
                    </Box>
                ) : step == 3 ? (
                    <Box sx={style}>
                        <Typography id="keep-mounted-modal-title" variant="h3" component="h2" align="center">
                            Submit Your Review
                        </Typography>
                        <Grid my={2} container spacing={2} align={'center'} justifyContent={'center'}>
                            <Rating
                                // name="simple-controlled"
                                disabled={false}
                                value={reviewPayload?.ratings}
                                onChange={(event, newValue) => {
                                    setReviewPayload({ ...reviewPayload, ratings: newValue });
                                }}
                                name="size-small"
                                defaultValue={2}
                                size="small"
                                // readOnly
                            />
                        </Grid>
                        <Grid my={5} container spacing={2} align={'center'} justifyContent={'center'}>
                            <TextField
                                fullWidth
                                placeholder="Add your comment"
                                value={reviewPayload?.comment}
                                onChange={(e) => setReviewPayload({ ...reviewPayload, comment: e.target.value })}
                            />
                        </Grid>
                        <Grid justifyContent={'center'} container spacing={2}>
                            <Button onClick={handleClose}>Close</Button>
                            <Button
                                style={{ borderRadius: 10 }}
                                disableElevation
                                disabled={reviewing}
                                // fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                onClick={handleReview}
                            >
                                {reviewing ? 'Submitting Review...' : 'Submit'}
                            </Button>
                        </Grid>
                    </Box>
                ) : (
                    <Box sx={style}>
                        <Typography id="keep-mounted-modal-title" variant="h3" component="h2" align="center">
                            Select Payment Method
                        </Typography>
                        <Grid my={5} container spacing={2}>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <PaymentOptionContainer data={{ title: 'Card', icon: cardIcon }} onClick={() => alert('Coming Soon')} />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <PaymentOptionContainer
                                    data={{ title: 'Account Number', icon: bankIcon }}
                                    onClick={() => alert('Coming Soon')}
                                />
                            </Grid>
                        </Grid>
                        <Grid justifyContent={'end'} container>
                            <Button onClick={handleClose}>Close</Button>
                            <Button
                                style={{ borderRadius: 10 }}
                                disableElevation
                                // disabled={isSubmitting}
                                // fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                onClick={() => setStep(1)}
                            >
                                Make Payment
                            </Button>
                        </Grid>
                    </Box>
                )}
            </Modal>
            <Grid item xs={12}>
                <DashBoardDetailComponent>
                    <Grid
                        container
                        spacing={gridSpacing}
                        style={{ height: 200 }}
                        paddingY={'2'}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item lg={12} md={12} sm={6} xs={12}>
                            <Typography color={'white'} gutterBottom variant={'h1'} align="center">
                                <font size={6}>
                                    {'Kano'} &nbsp;&nbsp;&nbsp; &rarr; &nbsp;&nbsp;&nbsp;{'Kaduna'}
                                </font>
                            </Typography>
                        </Grid>
                    </Grid>
                </DashBoardDetailComponent>
            </Grid>

            <Grid container spacing={2} mt={2} ml={1} paddingY={2} paddingX={5}>
                <Typography color={'black'} fontWeight={'bold'} fontSize={18} gutterBottom>
                    Progress
                </Typography>
            </Grid>

            <Grid container spacing={2} paddingX={5} pt={3}>
                {/* <Grid item lg={4} md={4} sm={12} xs={12}>
                    <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom align="center">
                        Kawo park, Kaduna, Kaduna
                    </Typography>
                    <Typography color={'black'} fontWeight={'bold'} fontSize={16} my={2} gutterBottom align="center">
                        <font size={10}>&darr;</font>
                    </Typography>
                    <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom align="center">
                        Kaduna park, Abuja, FCT
                    </Typography>
                    <Typography color={'black'} fontWeight={'bold'} fontSize={16} my={2} gutterBottom align="center">
                        <font size={10}>&darr;</font>
                    </Typography>
                    <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom align="center">
                        Kaduna park, Abuja, FCT
                    </Typography>
                    <Typography color={'black'} fontWeight={'bold'} fontSize={16} my={2} gutterBottom align="center">
                        <font size={10}>&darr;</font>
                    </Typography>
                    <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom align="center">
                        000 park, Benin, Edo
                    </Typography>
                </Grid> */}
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className="myContainer">
                        {fetching ? (
                            <Grid align="center">
                                <h3>Fetching Info</h3>
                            </Grid>
                        ) : schedule == null ? (
                            <Grid align="center">
                                <h3>Trip Not found</h3>
                            </Grid>
                        ) : (
                            <Grid container spacing={2} paddingX={5}>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <BookInfoItem
                                        data={{
                                            title: 'Vehicle',
                                            description: `${schedule?.vehicle?.name} ${schedule?.vehicle?.carModel}`,
                                            image: vehicleImage
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <BookInfoItem
                                        data={{
                                            title: 'End Point',
                                            description: `${schedule?.endPoint}, ${schedule?.toLocation?.name ?? ''}`,
                                            image: endPointImage
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <BookInfoItem data={{ title: 'Price per seat', description: `--`, image: seatPriceImage }} />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <BookInfoItem
                                        data={{
                                            title: 'Time of departure',
                                            description: `${schedule?.estimatedDeparture.toString().split('T')[1].replaceAll('.000Z', '')}`,
                                            image: timeImage
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <BookInfoItem
                                        data={{
                                            title: 'Date of departure',
                                            description: `${Configs.dateConvert(schedule?.estimatedDeparture)}`,
                                            image: calenderImage
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <BookInfoItem
                                        data={{
                                            title: 'Time of Arrival',
                                            description: `${schedule?.estimatedArrival.toString().split('T')[1].replaceAll('.000Z', '')}`,
                                            image: timeImage
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <BookInfoItem
                                        data={{
                                            title: 'Start point',
                                            description: `${schedule?.startPoint}, ${schedule?.fromLocation?.name ?? ''}`,
                                            image: startPointImage
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <BookInfoItem
                                        data={{
                                            title: 'Date of arrival',
                                            description: `${Configs.dateConvert(schedule?.estimatedArrival)}`,
                                            image: calenderImage
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <BookInfoItem
                                        data={{
                                            title: 'Status',
                                            description: `${schedule?.trip?.status ?? ''}`,
                                            image: vehicleImage
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </div>
                </Grid>
            </Grid>
            {/* <Grid mx={3} container mt={3}>
                <Grid item md={6} sm={12} xs={12}>
                    <div className="myContainer">
                        <Grid container direction={'row'} paddingTop={1} alignItems="center" justifyContent="start" spacing={1}>
                            <Grid item>
                                <img src={samplePic} alt="" className="tripItemPic" />
                            </Grid>
                            <Grid item>
                                <Grid>
                                    <Typography
                                        variant="caption"
                                        color={'black'}
                                        fontSize="14px"
                                        fontWeight={'bold'}
                                        // paddingLeft={3}
                                        // textAlign={matchDownSM ? 'center' : 'inherit'}
                                    >
                                        {'Shehu Musa'}
                                    </Typography>
                                </Grid>
                                <Grid>
                                    <Rating
                                        // name="simple-controlled"
                                        disabled={false}
                                        value={4}
                                        onChange={(event, newValue) => {
                                            // setValue(newValue);
                                        }}
                                        name="size-small"
                                        defaultValue={2}
                                        size="small"
                                        readOnly
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Typography color={'blue'} mt={1} gutterBottom fontSize={12} fontWeight={'normal'}>
                            Review
                        </Typography>
                        <Typography color={'grey'} gutterBottom fontSize={12} mt={3} fontWeight={'normal'}>
                            Acceptance Rate <font color="red">80%</font>
                        </Typography>
                    </div>
                </Grid>
            </Grid> */}
            <Grid container justifyContent={'end'} spacing={2} paddingX={5} my={5} pt={3}>
                {!schedule?.trip && !fetching ? (
                    <Button
                        style={{ borderRadius: 10 }}
                        disableElevation
                        // disabled={isSubmitting}
                        // fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="secondary"
                        onClick={handleOpen}
                    >
                        Proceed to Book
                    </Button>
                ) : schedule?.trip?.status == 'completed' ? (
                    <Button
                        style={{ borderRadius: 10 }}
                        disableElevation
                        // disabled={isSubmitting}
                        // fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            setStep(3);
                            handleOpen();
                        }}
                    >
                        Submit Review
                    </Button>
                ) : (
                    <></>
                )}
            </Grid>
        </Grid>
    );
};

export default UserSingleTrip;
