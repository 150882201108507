// assets
import { IconKey, IconSettings, IconLogout } from '@tabler/icons';

// constant
const icons = {
    IconKey,
    IconSettings,
    IconLogout
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const travellerOther = {
    id: 'pages',
    title: 'Others',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        // {
        //     id: 'authentication',
        //     title: 'Authentication',
        //     type: 'collapse',
        //     icon: icons.IconKey,

        //     children: [
        //         {
        //             id: 'login3',
        //             title: 'Login',
        //             type: 'item',
        //             url: '/pages/login/login3',
        //             target: true
        //         },
        //         {
        //             id: 'register3',
        //             title: 'Register',
        //             type: 'item',
        //             url: '/pages/register/register3',
        //             target: true
        //         }
        //     ]
        // }
        {
            id: 'settings',
            title: 'Settings',
            type: 'item',
            url: '/traveller/settings',
            icon: icons.IconSettings,
            breadcrumbs: false
        },
        {
            id: 'logout',
            title: 'Logout',
            type: 'item',
            url: '/login',
            icon: icons.IconLogout,
            breadcrumbs: false
        }
    ]
};

export default travellerOther;
