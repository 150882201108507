// assets
import { IconDashboard, IconWallet, IconCar, IconCalendar } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconWallet, IconCar, IconCalendar };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
// TODO sidenav items list
const prefix = '/driver';
const driverMenu = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: `${prefix}`,
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'schedule-trips',
            title: 'Schedule Trips',
            type: 'item',
            url: prefix + '/schedule-trips',
            icon: icons.IconCalendar,
            breadcrumbs: false
        },
        {
            id: 'wallet',
            title: 'Wallet',
            type: 'item',
            url: prefix + '/wallet',
            icon: icons.IconWallet,
            breadcrumbs: false
        },
        {
            id: 'my-trips',
            title: 'My Trips',
            type: 'item',
            url: prefix + '/my-trips',
            icon: icons.IconCar,
            breadcrumbs: false
        }
    ]
};

export default driverMenu;
