import React from 'react';

// material-ui
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';

import { gridSpacing } from 'store/constant';
const DashBoardDetailComponent = ({ children }) => {
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <div className="dashboardContainer">{children}</div>
            </Grid>
        </Grid>
    );
};

export default DashBoardDetailComponent;
