import { useEffect, useState } from 'react';

// material-ui
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import samplePic from '../../../assets/images/sample_pic.png';
import { IconCalendar, IconCalendarEvent, IconMenu, IconUser } from '@tabler/icons';
import { AdminAccountsService } from 'services/admin/admin_account_service';
import { useParams } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';

// ==============================|| DEFAULT USERDASHBOARD ||============================== //

const SingleAdmin = () => {
    const [isLoading, setLoading] = useState(true);
    const [isFetching, setFetching] = useState(true);
    const [isUpdating, setUpdating] = useState(false);
    const [admin, setAdmin] = useState(null);
    const [open, setOpen] = useState(false);
    const { id } = useParams();

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: 4,
        boxShadow: 24,
        p: 4
    };

    useEffect(() => {
        setLoading(false);
        getAdmins();
    }, []);
    const getAdmins = async () => {
        setFetching(true);
        const userResponse = await AdminAccountsService.getSingle(id);
        setFetching(false);
        if (!userResponse.success) {
            return;
        }
        setAdmin(userResponse.data);
    };
    const updateStatus = async () => {
        setUpdating(true);
        const userResponse = await AdminAccountsService.update(id, { status: 'blocked' });
        setUpdating(false);
        if (!userResponse.success) {
            toast.error(userResponse.message);
            return;
        }
        toast.success(userResponse.message);
        handleClose();
        getAdmins();
    };
    return (
        <Grid container spacing={gridSpacing}>
            <ToastContainer />
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <Typography id="keep-mounted-modal-title" variant="h3" component="h2" align="center">
                        Are you sure you want to Deactivate?
                    </Typography>
                    <Grid container align={'center'} spacing={2} my={2}>
                        <Grid item md={6}>
                            <Button
                                style={{ borderRadius: 10 }}
                                disableElevation
                                disabled={isUpdating}
                                // fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={updateStatus}
                            >
                                {isUpdating ? 'Updating Status' : 'yes'}
                            </Button>
                        </Grid>
                        <Grid item md={6}>
                            <Button
                                style={{ borderRadius: 10 }}
                                disableElevation
                                // disabled={isSubmitting}
                                // fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="error"
                                onClick={handleClose}
                            >
                                No
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>

            {isFetching ? (
                <Grid align={'center'} container m={3}>
                    <h3>Fetching info.</h3>
                </Grid>
            ) : (
                <Grid container spacing={2} paddingX={5} pt={3}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className="mySettingsContainer">
                            <Grid container>
                                <Grid item xs={3} align={'center'}>
                                    <img src={admin?.user?.pictureUrl ?? 'samplePic'} alt="" className="userProfilePic" />
                                    <Grid mt={2}>
                                        <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom align="center">
                                            Last logged in
                                        </Typography>
                                        <Typography color={'grey'} fontWeight={'normal'} fontSize={16} gutterBottom align="center">
                                            {admin?.updatedAt?.toString().replaceAll('T', ' ').replaceAll('Z', ' ')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={2} paddingX={5}>
                                        <Grid xs={12} mt={2} mx={4}>
                                            <Grid container spacing={1} direction={'row'} justifyContent={'start'}>
                                                <Grid my={0.0001} mr={1}>
                                                    <Typography
                                                        color={'black'}
                                                        fontWeight={'bold'}
                                                        fontSize={16}
                                                        gutterBottom
                                                        align="center"
                                                    >
                                                        <IconUser />
                                                    </Typography>
                                                </Grid>
                                                <Grid>
                                                    <Typography color={'black'} fontWeight={'bold'} fontSize={20} gutterBottom align="left">
                                                        General Profile
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            {/* <Typography color={'grey'} fontWeight={'normal'} fontSize={14} gutterBottom align="center">
                                            Business owner with multiple branches around the country
                                        </Typography> */}
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                                Full Name
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography color={'grey'} fontWeight={'normal'} fontSize={14} gutterBottom>
                                                {admin?.user?.firstName} {admin?.user?.lastName}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                                Phone
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography color={'grey'} fontWeight={'normal'} fontSize={14} gutterBottom>
                                                {admin?.user?.phoneNumber}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                                Location
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography color={'grey'} fontWeight={'normal'} fontSize={14} gutterBottom>
                                                {admin?.user?.state}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                                Status
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography color={'grey'} fontWeight={'normal'} fontSize={14} gutterBottom>
                                                {admin?.status}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom align="left">
                                        Actions
                                    </Typography>
                                    <Grid mt={3}>
                                        <Button
                                            style={{ borderRadius: 10 }}
                                            disableElevation
                                            // disabled={isSubmitting}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="error"
                                            onClick={handleOpen}
                                        >
                                            Deactivate Account
                                        </Button>
                                    </Grid>
                                    <Grid mt={3}>
                                        <Button
                                            style={{ borderRadius: 10 }}
                                            disableElevation
                                            // disabled={isSubmitting}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="warning"
                                            // onClick={() => setStep(2)}
                                        >
                                            Reset password
                                        </Button>
                                    </Grid>
                                    <Typography color={'blue'} fontWeight={'normal'} fontSize={16} gutterBottom align="center" mt={3}>
                                        View all Transactions
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default SingleAdmin;
