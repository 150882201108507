import { useEffect, useState } from 'react';

// material-ui
import {
    Button,
    Grid,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Paper,
    Table,
    Modal,
    Box
} from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import TripItemCard from 'ui-component/cards/TripItem';
import DashBoardDetailComponent from '../component/DashBoardDetailComponent';
import WalletContainer from '../component/WalletContainer';
import { height } from '@mui/system';
import addIcon from '../../../assets/images/add_icon.png';
import deductIcon from '../../../assets/images/deduct_icon.png';
import clockIcon from '../../../assets/images/clock_icon.png';
import UserWithrawalComponent from './component/UserWithrawalComponent';
import UserFundingComponent from './component/UserFundingComponent';
import { TravellerWalletService } from 'services/traveller/wallet_service';
import { Configs } from 'constants/configs';
// ==============================|| DEFAULT USERDASHBOARD ||============================== //

const UserTransactions = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: 4,
        boxShadow: 24,
        p: 2
    };
    const [modalAction, setModalAction] = useState('fund');
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setModalAction('');
        setOpen(false);
    };

    const [isLoading, setLoading] = useState(true);
    function tableData(type, icon, amount, date) {
        return { icon, type, amount, date };
    }

    const rows = [
        tableData('Credit', 159, 6.0, '2:00pm 19-jan-2024'),
        tableData('Debit', 237, 9.0, '2:00pm 19-jan-2024'),
        tableData('Credit', 262, 16.0, '2:00pm 19-jan-2024'),
        tableData('Credit', 305, 3.7, '2:00pm 19-jan-2024'),
        tableData('Debit', 356, 16.0, '2:00pm 19-jan-2024')
    ];
    const [fetching, setFetching] = useState(false);
    const [transactions, setTransactions] = useState([]);
    useEffect(() => {
        setLoading(false);
        getWaller();
    }, []);

    const getWaller = async () => {
        setFetching(true);
        const trxResponse = await TravellerWalletService.transaction({});
        setFetching(false);

        if (!trxResponse.success) {
        }

        setTransactions(trxResponse.data);
    };
    return (
        <Grid container spacing={gridSpacing}>
            <Grid container spacing={2} mt={5} ml={2} paddingX={1} alignItems="center" justifyContent={'center'}>
                <div align="center">
                    <Typography color={'black'} gutterBottom _variant={'h2'} align="center" my={3}>
                        <font size={5}>May</font>
                    </Typography>
                </div>
                <div className="myContainer">
                    <Typography color={'black'} gutterBottom _variant={'h2'}>
                        <font size={5}>Transactions</font>
                    </Typography>
                    {fetching ? (
                        <Grid align={'center'}>
                            <h3>Fetching Transactions</h3>
                        </Grid>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {/* <TableCell align="left"></TableCell> */}
                                        <TableCell>Reference</TableCell>
                                        <TableCell>Transaction Type</TableCell>
                                        <TableCell>Action</TableCell>
                                        <TableCell align="left">Amount&nbsp;</TableCell>
                                        <TableCell align="left">Date</TableCell>
                                        <TableCell align="left">Status&nbsp;</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {transactions.map((row) => (
                                        <TableRow key={row?.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="left">{row?.reference}</TableCell>
                                            <TableCell align="left">{row?.transactionType}</TableCell>
                                            <TableCell component="th" scope="row">
                                                <font color={row?.action.toString().toLowerCase() == 'credit' ? 'green' : 'red'}>
                                                    {row?.action}
                                                </font>
                                            </TableCell>
                                            <TableCell align="left">{Configs.currencyFormat(row?.amount ?? 0)}</TableCell>
                                            <TableCell align="left">{Configs.dateConvert(row.createdAt)}</TableCell>
                                            <TableCell align="left">{row?.status}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </div>
            </Grid>
        </Grid>
    );
};

export default UserTransactions;
