import axios from 'axios';
import { baseUrl } from 'constants/constants';

const token = localStorage.getItem('token');
const prefix = 'admin/admins';
let AdminAccountsService = {
    create: async (data) => {
        try {
            const resp = await axios.post(baseUrl + `/${prefix}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Succesful',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    getAll: async (data) => {
        try {
            const resp = await axios.get(baseUrl + `/${prefix}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Succesful',
                data: resp?.data?.data,
                pagination: resp?.data?.pagination,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    getSingle: async (id) => {
        try {
            const resp = await axios.get(baseUrl + `/${prefix}/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Succesful',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    update: async (id, data) => {
        try {
            const resp = await axios.put(baseUrl + `/${prefix}/${id}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Succesful',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    analytics: async (data) => {
        try {
            const resp = await axios.get(baseUrl + `/${prefix.replaceAll('/admins', '')}/analytics`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Succesful',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    }
};

export { AdminAccountsService };
