import { useEffect, useState } from 'react';

// material-ui
import { Button, FormControl, Grid, InputLabel, MenuItem, Modal, Rating, Select, TextField, Typography } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import TripItemCard from 'ui-component/cards/TripItem';
import DashBoardDetailComponent from '../component/DashBoardDetailComponent';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router';
import PaymentOptionContainer from '../component/PaymentOptionContainer';
import cardIcon from '../../../assets/images/card_icon.png';
import bankIcon from '../../../assets/images/bank_icon.png';
import successIcon from '../../../assets/images/success_icon.png';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { DriverTripService } from 'services/driver/trip_service';
import states from '../../../constants/state.json';

// ==============================|| DEFAULT USERDASHBOARD ||============================== //

const DriverScheduleTrip = () => {
    const [isLoading, setLoading] = useState(true);
    // const account = useSelector((state) => state.cust); //Getting Account from state
    const customization = useSelector((state) => state.customization);
    const dispatch = useDispatch();
    const [trip, setTrip] = useState({
        startPoint: '',
        endPoint: '',
        fromLocation: '',
        toLocation: '',
        estimatedDeparture: '',
        estimatedArrival: '',
        dateTime: '',
        date: '',
        time: '',
        arrivalDate: '',
        arrivalTime: '',
        seats: [
            {
                price: 0,
                description: ''
            }
        ]
    });

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: 4,
        boxShadow: 24,
        p: 4
    };

    const [step, setStep] = useState(1);
    const [open, setOpen] = useState(false);
    const [locations, setLocations] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    };

    // const [seats, setSeats] = useState([
    //     {
    //         description: '',
    //         price: 0
    //     }
    // ]);
    useEffect(() => {
        setLoading(false);
        getLocations();
    }, []);

    const addSeat = () => {
        setTrip({
            ...trip,
            seats: [
                ...trip.seats,
                {
                    description: '',
                    price: 0
                }
            ]
        });
    };

    const getLocations = async () => {
        // 2024-07-15T08:00:00.000Z

        const locationResult = await DriverTripService.locations({ limit: 0 });

        if (!locationResult.success) {
            return;
        }
        setLocations(locationResult?.data ?? []);
    };
    const handleTrip = async () => {
        // 2024-07-15T08:00:00.000Z
        let tripData = trip;
        tripData.dateTime = `${trip.date}T${trip.time}:00.000Z`;
        trip.estimatedDeparture = `${trip.date}T${trip.time}:00.000Z`;
        trip.estimatedArrival = `${trip.arrivalDate}T${trip.arrivalTime}:00.000Z`;
        delete tripData.date;
        delete tripData.time;
        delete tripData.arrivalDate;
        delete tripData.arrivalTime;
        // Call API here
        setSubmitting(true);
        const createTrip = await DriverTripService.createTrip(tripData);
        setSubmitting(false);
        if (!createTrip.success) {
            toast.error(createTrip?.message ?? 'Could not create Trip');
            return;
        }
        toast.success(createTrip?.message ?? 'Trip created');
        setStep(3); //if successful
    };
    return (
        <>
            <ToastContainer />
            <Grid container spacing={gridSpacing}>
                <Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    {step == 1 ? (
                        <Box sx={style}>
                            <Typography id="keep-mounted-modal-title" variant="h3" component="h2" align="center">
                                Make Booking
                            </Typography>
                            {/* <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }} component="h2" align="center">
                    Alternate Phone Number
                </Typography> */}
                            <Grid container alignItems={'center'} justifyContent={'center'} my={2} xs={{ m: 2 }} md={{ m: 10 }}>
                                <TextField fullWidth placeholder="Alternate Phone Number" />
                            </Grid>

                            <Typography id="keep-mounted-modal-description" sx={{ mt: 3 }}>
                                Select Your Seat
                            </Typography>
                            <Grid container my={2}>
                                {[1, 2, 3, 4, 5].map((item, key) => {
                                    return (
                                        <Grid item lg={2} md={2} sm={4} xs={12} my={2} mx={1}>
                                            <div className={key % 2 == 0 ? 'seatOptionActive hover' : 'seatOption hover'}>
                                                <Typography fontWeight={'normal'} component="h2" align="center">
                                                    Seat 1
                                                </Typography>{' '}
                                                <Typography fontWeight={'normal'} component="h2" align="center">
                                                    Front Seat
                                                </Typography>
                                                <Typography
                                                    // id="keep-mounted-modal-title"
                                                    fontWeight={'bold'}
                                                    // variant="h3"
                                                    fontSize={25}
                                                    mt={2}
                                                    component="h2"
                                                    align="center"
                                                >
                                                    N100
                                                </Typography>
                                            </div>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            <Grid justifyContent={'end'} container>
                                <Button onClick={handleClose}>Close</Button>
                                <Button
                                    style={{ borderRadius: 10 }}
                                    disableElevation
                                    // disabled={isSubmitting}
                                    // fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                        setStep(2);
                                    }}
                                >
                                    Continue
                                </Button>
                            </Grid>
                        </Box>
                    ) : (
                        <Box sx={style}>
                            <Typography id="keep-mounted-modal-title" variant="h3" component="h2" align="center">
                                Select Payment Method
                            </Typography>
                            <Grid my={5} container spacing={2}>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <PaymentOptionContainer data={{ title: 'Card', icon: cardIcon }} onClick={() => alert('Coming Soon')} />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <PaymentOptionContainer
                                        data={{ title: 'Account Number', icon: bankIcon }}
                                        onClick={() => alert('Coming Soon')}
                                    />
                                </Grid>
                            </Grid>
                            <Grid justifyContent={'end'} container>
                                <Button onClick={handleClose}>Close</Button>
                                <Button
                                    style={{ borderRadius: 10 }}
                                    disableElevation
                                    // disabled={isSubmitting}
                                    // fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => setStep(1)}
                                >
                                    Make Payment
                                </Button>
                            </Grid>
                        </Box>
                    )}
                </Modal>
                {/* <Grid item xs={12}>
            <DashBoardDetailComponent>
                <Grid
                    container
                    spacing={gridSpacing}
                    style={{ height: 200 }}
                    paddingY={'2'}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item lg={12} md={12} sm={6} xs={12}>
                        <Typography color={'white'} gutterBottom variant={'h1'} align="center">
                            <font size={6}>
                                {'Kano'} &nbsp;&nbsp;&nbsp; &rarr; &nbsp;&nbsp;&nbsp;{'Kaduna'}
                            </font>
                        </Typography>
                    </Grid>
                </Grid>
            </DashBoardDetailComponent>
        </Grid> */}
                {/* + JSON.stringify(customization.userInfo) */}
                <Grid container spacing={2} mt={2} ml={1} paddingY={2} paddingX={5}>
                    <Typography color={'black'} fontWeight={'bold'} fontSize={18} gutterBottom>
                        {step == 1 ? 'Schedule a trip' : step == 2 ? 'Set description and price of seat' : ''}
                    </Typography>
                </Grid>

                <Grid container spacing={2} paddingX={5} pt={3}>
                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        {/* <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom align="center">
                    Kawo park, Kaduna, Kaduna
                </Typography>
                <Typography color={'black'} fontWeight={'bold'} fontSize={16} my={2} gutterBottom align="center">
                    <font size={10}>&darr;</font>
                </Typography> */}
                    </Grid>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <div className="myContainer">
                            {step > 1 ? (
                                <Grid mb={2}>
                                    <Button
                                        style={{ borderRadius: 10 }}
                                        disableElevation
                                        // disabled={isSubmitting}
                                        // fullWidth
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => setStep(step - 1)}
                                    >
                                        &larr; &nbsp; Back &nbsp;
                                    </Button>
                                </Grid>
                            ) : (
                                <></>
                            )}
                            {step == 1 ? (
                                <Grid container spacing={2} paddingX={5}>
                                    <Grid item xs={12} md={6} mb={3}>
                                        <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                            Departure City
                                        </Typography>
                                        <FormControl fullWidth mt={1}>
                                            {/* <InputLabel id="demo-simple-select-label">Dispatch Point</InputLabel> */}
                                            <Select
                                                value={trip?.fromLocation}
                                                onChange={(e) => setTrip({ ...trip, fromLocation: e.target.value })}
                                                label={trip?.fromLocation}
                                            >
                                                {locations.map((val, key) => (
                                                    <MenuItem value={val?._id}>{val?.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6} mb={3}>
                                        <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                            Start Point
                                        </Typography>
                                        <TextField
                                            placeholder="Location/Park"
                                            fullWidth
                                            value={trip?.startPoint}
                                            onChange={(e) => setTrip({ ...trip, startPoint: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} mb={3}>
                                        <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                            Destination City
                                        </Typography>
                                        <FormControl fullWidth mt={1}>
                                            {/* <InputLabel id="demo-simple-select-label">Destination Point</InputLabel> */}
                                            <Select
                                                value={trip?.toLocation}
                                                onChange={(e) => setTrip({ ...trip, toLocation: e.target.value })}
                                                label={trip?.toLocation}
                                            >
                                                {locations.map((val, key) => (
                                                    <MenuItem value={val?._id}>{val?.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6} mb={3}>
                                        <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                            End Point
                                        </Typography>
                                        <TextField
                                            placeholder="Location/Park"
                                            fullWidth
                                            value={trip?.endPoint}
                                            onChange={(e) => setTrip({ ...trip, endPoint: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                            Departure Date
                                        </Typography>
                                        <TextField
                                            type="date"
                                            placeholder="Date"
                                            fullWidth
                                            value={trip?.date}
                                            onChange={(e) => setTrip({ ...trip, date: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                            Departure Time
                                        </Typography>
                                        <TextField
                                            type="time"
                                            placeholder="TIme"
                                            fullWidth
                                            value={trip?.time}
                                            onChange={(e) => setTrip({ ...trip, time: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12} mt={3}>
                                        <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                            Arrival Date
                                        </Typography>
                                        <TextField
                                            type="date"
                                            placeholder="Date"
                                            fullWidth
                                            value={trip?.arrivalDate}
                                            onChange={(e) => setTrip({ ...trip, arrivalDate: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12} mt={3}>
                                        <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                            Arrival Time
                                        </Typography>
                                        <TextField
                                            type="time"
                                            placeholder="TIme"
                                            fullWidth
                                            value={trip?.arrivalTime}
                                            onChange={(e) => setTrip({ ...trip, arrivalTime: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} align="center" mt={2}>
                                        <Button
                                            style={{ borderRadius: 10 }}
                                            disableElevation
                                            // disabled={isSubmitting}
                                            // fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => {
                                                if (
                                                    trip?.startPoint == '' ||
                                                    trip?.endPoint == '' ||
                                                    trip?.date == '' ||
                                                    trip?.time == ''
                                                ) {
                                                    toast.error('Fields cannot be empty');
                                                    return;
                                                }
                                                setStep(2);
                                            }}
                                        >
                                            Proceed &nbsp; &rarr;
                                        </Button>
                                    </Grid>
                                </Grid>
                            ) : step == 2 ? (
                                <>
                                    {trip.seats.map((item, key) => (
                                        <Grid container spacing={2} paddingX={5}>
                                            <Grid item xs={12}>
                                                <Typography color={'black'} fontWeight={'normal'} fontSize={16}>
                                                    Seat {key + 1}
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={5} md={5} sm={12} xs={12}>
                                                <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                                    Description
                                                </Typography>
                                                <TextField
                                                    type="text"
                                                    placeholder=""
                                                    value={item?.description}
                                                    fullWidth
                                                    onChange={(e) => {
                                                        let allSeats = trip.seats;
                                                        allSeats[key].description = e.target.value;
                                                        setTrip({
                                                            ...trip,
                                                            seats: allSeats
                                                        });
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item lg={1} md={1} sm={12} xs={12}></Grid>
                                            <Grid item lg={5} md={5} sm={12} xs={12}>
                                                <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                                                    Price
                                                </Typography>
                                                <TextField
                                                    type="number"
                                                    placeholder=""
                                                    value={item?.price}
                                                    onChange={(e) => {
                                                        let allSeats = trip.seats;
                                                        allSeats[key].price = e.target.value;
                                                        // alert(JSON.stringify(allSeats[key]));
                                                        setTrip({
                                                            ...trip,
                                                            seats: allSeats
                                                        });
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item lg={1} md={1} sm={12} xs={12} alignItems={'center'} align="right">
                                                {/* <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom my={5}>
                                            -
                                        </Typography> */}
                                                <Grid my={5}>
                                                    <Button
                                                        style={{ borderRadius: 20 }}
                                                        disableElevation
                                                        // disabled={isSubmitting}
                                                        // fullWidth
                                                        size="small"
                                                        type="submit"
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => {
                                                            let allSeats = trip.seats;
                                                            allSeats.splice(key, 1);
                                                            setTrip({
                                                                ...trip,
                                                                seats: allSeats
                                                            });
                                                        }}
                                                    >
                                                        -
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                    <Grid>
                                        <Grid item xs={12} align="right" mt={2}>
                                            <Button
                                                style={{ borderRadius: 20 }}
                                                disableElevation
                                                // disabled={isSubmitting}
                                                // fullWidth
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                color="secondary"
                                                onClick={addSeat}
                                            >
                                                +
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} align="center" mt={2}>
                                            <Button
                                                style={{ borderRadius: 10 }}
                                                disableElevation
                                                disabled={submitting}
                                                // fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => {
                                                    if (trip.seats.length == 0) {
                                                        toast.error('You need to add atleast one seat to continue.');
                                                        return;
                                                    }
                                                    handleTrip();
                                                }}
                                            >
                                                {submitting ? 'Loading' : <>Proceed &nbsp; &rarr;</>}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                <Grid>
                                    <Grid mb={5}>
                                        <Typography color={'black'} fontWeight={'bold'} fontSize={16} align="center" gutterBottom>
                                            You have successfully scheduled a trip!
                                        </Typography>
                                    </Grid>
                                    <Grid align="center" mb={5}>
                                        <img src={successIcon} height={'190px'} alt="" />
                                    </Grid>
                                    <Grid align="center" mb={3}>
                                        <Button
                                            style={{ borderRadius: 20 }}
                                            disableElevation
                                            // disabled={isSubmitting}
                                            // fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => setStep(1)}
                                        >
                                            Return Home
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default DriverScheduleTrip;
