import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Divider, Grid, Stack, TextField, Typography, useMediaQuery } from '@mui/material';

// project imports
import DragDrop from 'ui-component/dragdrop';
import { useState } from 'react';
import { AuthService } from 'services/auth_service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// ================================|| AUTH3 - LOGIN ||================================ //

const SetCarDetails = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();

    const [verifiData, setVerifiData] = useState({ carModel: '', name: '', plateNumber: '', color: '#000000' });
    const [selectedFile, setSelectedFile] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const handleUpload = async () => {
        const localUser = localStorage.getItem('userInfo');
        const usr = JSON.parse(localUser);
        if (verifiData?.carModel == '' || verifiData?.name == '' || verifiData?.plateNumber == '' || verifiData?.color == '') {
            toast.error('Fields cannot be empty');
            return;
        }
        if (!selectedFile) {
            toast.error('File not selected');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('carModel', verifiData?.carModel);
        formData.append('name', verifiData?.name);
        formData.append('plateNumber', verifiData?.plateNumber);
        formData.append('color', verifiData?.color);

        setSubmitting(true);
        const verResponse = await AuthService.addVehicle(formData);
        setSubmitting(false);
        if (!verResponse.success) {
            toast.error(verResponse.message);
            return;
        }
        toast.success(verResponse.message);
        navigate('/set-pin'); //('/upload-car-image');
    };
    return (
        <div>
            <ToastContainer />
            <Grid>
                <Grid container md={6} sx={{ mx: { xs: 1, sm: 0, md: 50 }, mb: 0 }}>
                    <Grid direction="column" container spacing={3} mx={5}>
                        <Grid mt={10}>
                            <Typography
                                color={theme.palette.secondary.main}
                                gutterBottom
                                // variant={matchDownSM ? 'h3' : 'h2'}
                                fontSize={18}
                                align="center"
                            >
                                Fill in the details of your car
                            </Typography>
                        </Grid>
                        <Grid mt={1}>
                            <Typography
                                variant="caption"
                                color={'black'}
                                fontSize="14px"
                                fontWeight={'bold'}
                                textAlign={matchDownSM ? 'center' : 'inherit'}
                            >
                                Car Name
                            </Typography>
                            <Grid mt={2}>
                                <TextField
                                    fullWidth
                                    label=""
                                    id="fullWidth"
                                    onChange={(e) => setVerifiData({ ...verifiData, name: e?.target?.value })}
                                />
                            </Grid>
                        </Grid>
                        <Grid mt={1}>
                            <Typography
                                variant="caption"
                                color={'black'}
                                fontSize="14px"
                                fontWeight={'bold'}
                                textAlign={matchDownSM ? 'center' : 'inherit'}
                            >
                                Model
                            </Typography>
                            <Grid mt={2}>
                                <TextField
                                    fullWidth
                                    label=""
                                    id="fullWidth"
                                    onChange={(e) => setVerifiData({ ...verifiData, carModel: e?.target?.value })}
                                />
                            </Grid>
                        </Grid>
                        <Grid mt={1}>
                            <Typography
                                variant="caption"
                                color={'black'}
                                fontSize="14px"
                                fontWeight={'bold'}
                                textAlign={matchDownSM ? 'center' : 'inherit'}
                            >
                                Plate Number
                            </Typography>
                            <Grid mt={2}>
                                <TextField
                                    fullWidth
                                    label=""
                                    id="fullWidth"
                                    onChange={(e) => setVerifiData({ ...verifiData, plateNumber: e?.target?.value })}
                                />
                            </Grid>
                        </Grid>
                        <Grid mt={5} align="left">
                            <Typography variant="caption" color={'black'} fontSize="14px" fontWeight={'bold'} align="left" py={2}>
                                Car Image
                            </Typography>
                            <DragDrop onChange={(e) => setSelectedFile(e?.target?.files[0])} />
                        </Grid>
                        <Grid container pt={5} align="left">
                            <Grid mt={1} xs={12}>
                                <Typography variant="caption" color={'black'} fontSize="14px" fontWeight={'bold'} align="left">
                                    Car Color
                                </Typography>
                            </Grid>
                            <Grid mt={1} xs={12}>
                                <Typography variant="caption" color={'grey'} fontSize="12px" align="left">
                                    Select color that best describes Your car
                                </Typography>
                            </Grid>
                            <Grid item mt={2}>
                                <input
                                    type="color"
                                    value={verifiData?.color != '' ? verifiData?.color : '#000000'}
                                    onChange={(e) => setVerifiData({ ...verifiData, color: e?.target?.value })}
                                    style={{
                                        borderRadius: 5,
                                        width: 60,
                                        marginRight: 5,
                                        // backgroundColor: val,
                                        height: 40,
                                        border: '1px solid grey'
                                    }}
                                />
                            </Grid>
                            {['#F5F5F5', '#FFFFFF', '#001447', '#172803', '#E90E0E', '#D78915', '#E90E0E'].map((val, key) => (
                                <Grid item mt={2}>
                                    <Button
                                        onClick={(e) => setVerifiData({ ...verifiData, color: val })}
                                        style={{
                                            borderRadius: 5,
                                            width: 40,
                                            marginRight: 5,
                                            backgroundColor: val,
                                            height: 40,
                                            border: '1px solid grey'
                                        }}
                                    ></Button>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid pt={5} align="center">
                            <Button
                                style={{ borderRadius: 20, paddingRight: 100, paddingLeft: 100 }}
                                disableElevation
                                disabled={submitting}
                                onClick={handleUpload}
                                // fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                            >
                                {submitting ? 'Loading' : 'Next'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default SetCarDetails;
