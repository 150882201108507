import axios from 'axios';
import { util } from 'constants/api.constant';
import { baseUrl } from 'constants/constants';

const token = localStorage.getItem('token');
const prefix = 'passenger/trips';
let TravellerTripService = {
    trips: async (data) => {
        try {
            const resp = await axios.get(baseUrl + `/${prefix}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successful',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    singleTrip: async (id) => {
        try {
            const resp = await axios.get(baseUrl + `/${prefix}/schedules/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successful',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    scheduled: async (data) => {
        try {
            const resp = await axios.get(baseUrl + `/${prefix}/schedules`, {
                params: data,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successful',
                data: resp?.data?.data,
                pagination: resp?.data?.pagination,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    requestTrip: async (id, data) => {
        try {
            const resp = await axios.post(baseUrl + `/${prefix}/schedules/${id}/requests`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successful',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    requests: async (id) => {
        try {
            const resp = await axios.get(baseUrl + `/${prefix}/requests`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successful',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    tripAction: async (data) => {
        try {
            const resp = await axios.put(
                baseUrl + `/${prefix}/schedules/${data?.id}/requests/${data?.requestId}/${data?.action}`,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            );
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successful',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    addReview: async (id, data) => {
        try {
            const resp = await axios.post(baseUrl + `/${prefix}/${id}/review`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successful',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    }
};

export { TravellerTripService };
