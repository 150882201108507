import React from 'react';
import { useEffect, useState } from 'react';

// material-ui
import { Button, Grid, Rating, TextField, Typography } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import MainCard from './MainCard';
import samplePic from '../../assets/images/sample_pic.png';
import { useNavigate } from 'react-router';
const BookInfoItem = ({ data }) => {
    return (
        <Grid container direction={'row'} paddingTop={1} alignItems="center" justifyContent="start" spacing={1}>
            <Grid item>
                <img src={data?.image ?? samplePic} alt="" className="scheduleItemPic" />
            </Grid>
            <Grid item>
                <Grid>
                    <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom>
                        {data?.title}
                    </Typography>
                </Grid>
                <Grid>
                    <Typography color={'grey'} fontWeight={'normal'} fontSize={14} gutterBottom>
                        {data?.description}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default BookInfoItem;
