import { useEffect, useState } from 'react';

// material-ui
import {
    Button,
    Grid,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Paper,
    Table,
    Modal,
    Box
} from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import WalletContainer from '../component/WalletContainer';
// ==============================|| DEFAULT USERDASHBOARD ||============================== //
import approveIcon from '../../../assets/images/approve_icon.svg';
import rejectIcon from '../../../assets/images/reject_icon.svg';
import samplePic from '../../../assets/images/sample_pic.png';
import { DriverTripService } from 'services/driver/trip_service';
import { useParams } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import { Configs } from 'constants/configs';
const DriverTravellers = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: 4,
        boxShadow: 24,
        p: 4
    };

    const [modalAction, setModalAction] = useState('cancel');
    const [open, setOpen] = useState(false);
    const { id } = useParams();
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setModalAction('');
        setOpen(false);
    };

    const [isLoading, setLoading] = useState(true);

    const [fetching, setFetching] = useState(false);
    const [takingAction, setTakingAction] = useState(false);
    const [selectedTrip, setSelectedTrip] = useState(null);
    const [trip, setTrip] = useState(null);
    const [passengers, setPassengers] = useState([]);
    useEffect(() => {
        setLoading(false);
        getTrip();
        getScheduleRequests();
    }, []);

    const getScheduleRequests = async () => {
        setFetching(true);
        const shceduleInfo = await DriverTripService.requests(id);
        setFetching(false);
        // alert(JSON.stringify(shceduleInfo));
        if (!shceduleInfo.success) {
            return;
        }
        setPassengers(shceduleInfo.data);
    };
    const getTrip = async () => {
        // setFetching(true);
        const shceduleInfo = await DriverTripService.tripBySchedule(id);
        // setFetching(false);
        // alert(JSON.stringify(shceduleInfo));
        if (!shceduleInfo.success) {
            return;
        }
        setTrip(shceduleInfo.data);
    };

    const tripAction = async () => {
        setTakingAction(true);
        const shceduleInfo = await DriverTripService.tripAction({ id, requestId: selectedTrip?._id, action: modalAction });
        setTakingAction(false);
        if (!shceduleInfo.success) {
            toast.error(shceduleInfo?.message);
            return;
        }
        toast.success(shceduleInfo?.message);
        handleClose();
        getScheduleRequests();
    };
    return (
        <Grid container spacing={gridSpacing}>
            <ToastContainer />
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <Typography id="keep-mounted-modal-title" variant="h3" component="h2" align="center">
                        Are you sure you want to {modalAction} this trip?
                    </Typography>
                    <Grid my={5} container spacing={2}>
                        <Grid item xs={6} align={'center'}>
                            <Button
                                style={{ borderRadius: 20 }}
                                disableElevation
                                disabled={takingAction}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                onClick={tripAction}
                            >
                                {takingAction ? 'Loading..' : 'Yes'}
                            </Button>
                        </Grid>
                        <Grid item xs={6} align={'center'}>
                            <Button
                                style={{ borderRadius: 20 }}
                                disableElevation
                                // disabled={isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="error"
                                onClick={handleClose}
                            >
                                No
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Grid container spacing={2} mt={5} ml={2} paddingX={1} alignItems="center" justifyContent={'center'}>
                <div align="center">
                    <Typography color={'black'} gutterBottom fontWeight={'bold'} fontSize={25} align="center" my={3}>
                        Passenger Requests
                    </Typography>
                </div>
                <Grid container mb={4}>
                    <Grid item md={3}></Grid>
                    <Grid item md={6}>
                        <WalletContainer>
                            <Grid padding={2}>
                                <Typography color={'white'} gutterBottom fontWeight={'normal'} fontSize={14} align="center" my={1}>
                                    You will be credited after trip has been completed
                                </Typography>
                                <Grid container>
                                    <Grid item md={6}>
                                        <Typography color={'white'} gutterBottom fontSize={18} align="center" my={1}>
                                            Total Amount
                                        </Typography>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Typography color={'white'} gutterBottom fontSize={18} align="center" my={1}>
                                            {Configs.currencyFormat(trip?.totalFare ?? 0)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </WalletContainer>
                    </Grid>
                </Grid>
                <div className="myContainer">
                    {/* <Typography color={'black'} gutterBottom _variant={'h2'}>
                        <font size={5}>Transactions</font>
                    </Typography> */}
                    {fetching ? (
                        <Grid align="center">
                            <h3>Fetching Passengers</h3>
                        </Grid>
                    ) : passengers.length == 0 ? (
                        <Grid align={'center'}>
                            <h3>No available requests for this trip</h3>
                        </Grid>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Image</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell align="left">Amount&nbsp;</TableCell>
                                        <TableCell align="left">Status</TableCell>
                                        <TableCell align="left">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {passengers.map((row) => {
                                        const currStatus = row?.status.toString().toLowerCase();
                                        return (
                                            <TableRow key={row?.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell align="left">
                                                    <img src={samplePic} alt="" className="tripItemPic" />
                                                </TableCell>
                                                <TableCell align="left">{row?.name}</TableCell>
                                                <TableCell align="left">{row?.phoneNumber}</TableCell>
                                                <TableCell align="left">N{row?.totalAmount}</TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Button
                                                        style={{
                                                            borderRadius: 5,
                                                            padding: 3,
                                                            paddingLeft: 15,
                                                            paddingRight: 15,
                                                            color:
                                                                currStatus == 'pending'
                                                                    ? 'orange'
                                                                    : currStatus == 'successful' || currStatus == 'approved'
                                                                    ? 'green'
                                                                    : 'white'
                                                        }}
                                                        disableElevation
                                                        // fullWidth
                                                        size="large"
                                                        type="submit"
                                                        variant="contained"
                                                        color={
                                                            currStatus == 'successful' || currStatus == 'approved'
                                                                ? 'success'
                                                                : currStatus == 'rejected'
                                                                ? 'error'
                                                                : currStatus == 'pending'
                                                                ? 'warning'
                                                                : 'default'
                                                        }
                                                        // onClick={() => navigate('/driver/travellers')}
                                                    >
                                                        {row?.status}
                                                    </Button>
                                                    {/* <font color={row.type.toString().toLowerCase() == 'credit' ? 'green' : 'red'}>{row.type}</font> */}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            onClick={() => {
                                                                setModalAction('approve');
                                                                setSelectedTrip(row);
                                                                handleOpen();
                                                            }}
                                                        >
                                                            <img
                                                                className="hover"
                                                                src={approveIcon}
                                                                height={30}
                                                                alt=""
                                                                style={{ marginRight: 15 }}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            onClick={() => {
                                                                setSelectedTrip(row);
                                                                setModalAction('reject');
                                                                handleOpen();
                                                            }}
                                                        >
                                                            <img className="hover" src={rejectIcon} height={30} alt="" />
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </div>
            </Grid>
        </Grid>
    );
};

export default DriverTravellers;
