import { baseUrl } from './constants';
import { storage } from './storage';
export const REQUEST_HEADER_AUTH_KEY = 'Authorization';

function Util() {
    this.endPoint = baseUrl;
    this.getHeaders = () => {
        return {
            'Content-Type': 'application/json',
            Accept: 'application/json; charset=utf-8'
        };
    };
    this.getAuthorizedHeaders = async () => {
        const token = storage.get('token');
        return {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        };
    };

    this.getAuthorizedOnboardingHeaders = async () => {
        const token = localStorage.getItem('authToken'); // await storage.get('authToken');
        console.log(token);
        return {
            'Content-Type': 'application/json',
            Authorization: 'PBearer ' + token
        };
    };

    this.getAuthorizedFileHeaders = () => {
        return {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json; charset=utf-8',
            Authorization: `Bearer ${storage.get('token')}`
        };
    };
}
const util = new Util();
export { util };
