import { useEffect, useState } from 'react';

// material-ui
import { Button, FormControl, Grid, InputLabel, MenuItem, Modal, Rating, Select, TextField, Typography } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import TripItemCard from 'ui-component/cards/TripItem';
import DashBoardDetailComponent from '../component/DashBoardDetailComponent';
import { Box } from '@mui/system';
import { useNavigate, useParams } from 'react-router';
import BookInfoItem from 'ui-component/cards/BookInfoItem';
import vehicleImage from '../../../assets/images/vehicle.svg';
import endPointImage from '../../../assets/images/end_point.svg';
import seatPriceImage from '../../../assets/images/price_perseat.svg';
import timeImage from '../../../assets/images/time.svg';
import calenderImage from '../../../assets/images/calender.svg';
import startPointImage from '../../../assets/images/start_point.svg';
import locationImage from '../../../assets/images/map_location.svg';
import { DriverTripService } from 'services/driver/trip_service';
import { Configs } from 'constants/configs';
import StartTrip from './component/start_trip';
import { ToastContainer, toast } from 'react-toastify';

// ==============================|| DEFAULT USERDASHBOARD ||============================== //

const DriverSingleTrip = () => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: 4,
        boxShadow: 24,
        p: 4
    };

    const [step, setStep] = useState(1);
    const [open, setOpen] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [takingAction, setTakingAction] = useState(false);
    const [tripSchedule, setTripSchedule] = useState(null);
    const [modalAction, setModalAction] = useState('');
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    };

    const { id } = useParams();
    useEffect(() => {
        setLoading(false);
        getTrip();
    }, []);

    const getTrip = async () => {
        setFetching(true);
        const shceduleInfo = await DriverTripService.singleTrip(id);
        setFetching(false);
        // alert(JSON.stringify(shceduleInfo));
        if (!shceduleInfo.success) {
            return;
        }
        setTripSchedule(shceduleInfo.data);
    };
    const updateStatus = async () => {
        setTakingAction(true);
        const tripResponse = await DriverTripService.updateStatus(tripSchedule?.trip?._id, {
            status: modalAction == 'complete' ? 'completed' : 'canceled'
        });
        setTakingAction(false);
        // alert(JSON.stringify(tripResponse));
        handleClose();
        if (!tripResponse.success) {
            toast.error(tripResponse?.message);
            return;
        }
        toast.success(tripResponse?.message);
        setTripSchedule(tripResponse.data);
    };
    return (
        <Grid container spacing={gridSpacing}>
            <ToastContainer />
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                {
                    // modalAction == 'complete' ? (
                    //     <Box sx={style}>
                    //         <Typography id="keep-mounted-modal-title" variant="h3" component="h2" align="center">
                    //             Complete
                    //         </Typography>

                    //     </Box>
                    // ) :
                    modalAction == 'cancel' || modalAction == 'complete' ? (
                        <Box sx={style}>
                            <Typography id="keep-mounted-modal-title" variant="h3" component="h2" align="center">
                                Are you sure you want to {modalAction}?
                            </Typography>
                            <Grid my={5} container spacing={2}>
                                <Grid item xs={6} align={'center'}>
                                    <Button
                                        style={{ borderRadius: 20 }}
                                        disableElevation
                                        disabled={takingAction}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        onClick={updateStatus}
                                    >
                                        {takingAction ? 'Submitting...' : 'Yes'}
                                    </Button>
                                </Grid>
                                <Grid item xs={6} align={'center'}>
                                    <Button
                                        style={{ borderRadius: 20 }}
                                        disableElevation
                                        // disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="error"
                                        onClick={handleClose}
                                    >
                                        No
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    ) : modalAction == 'start' ? (
                        <StartTrip data={tripSchedule} handleClose={handleClose} />
                    ) : (
                        // : modalAction == 'complete' ? (
                        //     <Box sx={style}>
                        //         <Typography id="keep-mounted-modal-title" variant="h3" component="h2" align="center">
                        //             Are you sure you want to complete trip?
                        //         </Typography>
                        //     </Box>
                        // )
                        <Box sx={style}></Box>
                    )
                }
            </Modal>
            <Grid item xs={12}>
                <DashBoardDetailComponent>
                    <Grid
                        container
                        spacing={gridSpacing}
                        style={{ height: 200 }}
                        paddingY={'2'}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item lg={12} md={12} sm={6} xs={12}>
                            <Typography color={'white'} gutterBottom variant={'h1'} align="center">
                                <font size={6}>
                                    {'Kano'} &nbsp;&nbsp;&nbsp; &rarr; &nbsp;&nbsp;&nbsp;{'Kaduna'}
                                </font>
                            </Typography>
                        </Grid>
                    </Grid>
                </DashBoardDetailComponent>
            </Grid>

            <Grid container spacing={2} mt={2} ml={1} paddingY={2} paddingX={5}>
                <Typography color={'black'} fontWeight={'bold'} fontSize={18} gutterBottom>
                    Progress
                </Typography>
            </Grid>

            <Grid container spacing={2} paddingX={5} pt={3}>
                {/* <Grid item lg={4} md={4} sm={6} xs={6} mt={5}>
                    <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom align="center">
                        <img src={locationImage} style={{ height: 15 }} alt="" /> Kawo park, Kaduna, Kaduna
                    </Typography>
                    <Typography color={'black'} fontWeight={'bold'} fontSize={16} my={2} gutterBottom align="center">
                        <font size={10}>&darr;</font>
                    </Typography>
                    <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom align="center">
                        <img src={locationImage} style={{ height: 15 }} alt="" /> Kaduna park, Abuja, FCT
                    </Typography>
                    <Typography color={'black'} fontWeight={'bold'} fontSize={16} my={2} gutterBottom align="center">
                        <font size={10}>&darr;</font>
                    </Typography>
                    <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom align="center">
                        <img src={locationImage} style={{ height: 15 }} alt="" /> Kaduna park, Abuja, FCT
                    </Typography>
                    <Typography color={'black'} fontWeight={'bold'} fontSize={16} my={2} gutterBottom align="center">
                        <font size={10}>&darr;</font>
                    </Typography>
                    <Typography color={'black'} fontWeight={'bold'} fontSize={16} gutterBottom align="center">
                        <img src={locationImage} style={{ height: 15 }} alt="" /> 000 park, Benin, Edo
                    </Typography>
                </Grid> */}

                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={2} paddingX={5} _alignItems="center" mb={3}>
                        <Grid item xs={6}>
                            <Button
                                style={{ borderRadius: 20 }}
                                disableElevation
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                onClick={() => navigate('/driver/trip/' + id + '/requests')}
                            >
                                View Travellers
                            </Button>
                        </Grid>
                        <Grid item xs={6} align={'right'}>
                            {tripSchedule ? (
                                <Button
                                    style={{ borderRadius: 20 }}
                                    disableElevation
                                    // disabled={isSubmitting}
                                    // fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="error"
                                    onClick={() => {
                                        setModalAction('cancel');
                                        handleOpen();
                                    }}
                                >
                                    Cancel Trip
                                </Button>
                            ) : (
                                <></>
                            )}
                        </Grid>
                    </Grid>
                    <div className="myContainer">
                        {fetching ? (
                            <Grid align="center" m={3}>
                                <h3>Fetching Trip Info</h3>
                            </Grid>
                        ) : tripSchedule ? (
                            <Grid container spacing={2} paddingX={5}>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <BookInfoItem
                                        data={{
                                            title: 'Vehicle Type',
                                            description: `${tripSchedule?.vehicle?.name} ${tripSchedule?.vehicle?.carModel}`,
                                            image: vehicleImage
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <BookInfoItem
                                        data={{
                                            title: 'End Point',
                                            description: `${tripSchedule?.endPoint}, ${tripSchedule?.toLocation?.name ?? ''}`,
                                            image: endPointImage
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <BookInfoItem data={{ title: 'Price per seat', description: `--`, image: seatPriceImage }} />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <BookInfoItem
                                        data={{
                                            title: 'Time of departure',
                                            description: `${tripSchedule?.estimatedDeparture
                                                .toString()
                                                .split('T')[1]
                                                .replaceAll('.000Z', '')}`,
                                            image: timeImage
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <BookInfoItem
                                        data={{
                                            title: 'Date of departure',
                                            description: `${Configs.dateConvert(tripSchedule?.estimatedDeparture)}`,
                                            image: calenderImage
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <BookInfoItem
                                        data={{
                                            title: 'Time of Arrival',
                                            description: `${tripSchedule?.estimatedArrival
                                                .toString()
                                                .split('T')[1]
                                                .replaceAll('.000Z', '')}`,
                                            image: timeImage
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <BookInfoItem
                                        data={{
                                            title: 'Start point',
                                            description: `${tripSchedule?.startPoint}, ${tripSchedule?.fromLocation?.name ?? ''}`,
                                            image: startPointImage
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <BookInfoItem
                                        data={{
                                            title: 'Date of arrival',
                                            description: `${Configs.dateConvert(tripSchedule?.estimatedArrival)}`,
                                            image: calenderImage
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <BookInfoItem
                                        data={{
                                            title: 'Trip Status',
                                            description: tripSchedule?.trip?.status ?? '',
                                            image: vehicleImage
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid align="center" m={3}>
                                <h3>Trip Not Found</h3>
                            </Grid>
                        )}
                    </div>
                </Grid>
            </Grid>
            {/* <Grid mx={3} container mt={3}>
                <Grid item md={6} sm={6} xs={6}>
                    <div className="myContainer">
                        <Grid container direction={'row'} paddingTop={1} alignItems="center" justifyContent="start" spacing={1}>
                            <Grid item>
                                <img src={samplePic} alt="" className="tripItemPic" />
                            </Grid>
                            <Grid item>
                                <Grid>
                                    <Typography
                                        variant="caption"
                                        color={'black'}
                                        fontSize="14px"
                                        fontWeight={'bold'}
                                        // paddingLeft={3}
                                        // textAlign={matchDownSM ? 'center' : 'inherit'}
                                    >
                                        {'Shehu Musa'}
                                    </Typography>
                                </Grid>
                                <Grid>
                                    <Rating
                                        // name="simple-controlled"
                                        disabled={false}
                                        value={4}
                                        onChange={(event, newValue) => {
                                            // setValue(newValue);
                                        }}
                                        name="size-small"
                                        defaultValue={2}
                                        size="small"
                                        readOnly
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Typography color={'blue'} mt={1} gutterBottom fontSize={12} fontWeight={'normal'}>
                            Review
                        </Typography>
                        <Typography color={'grey'} gutterBottom fontSize={12} mt={3} fontWeight={'normal'}>
                            Acceptance Rate <font color="red">80%</font>
                        </Typography>
                    </div>
                </Grid>
            </Grid> */}
            <Grid container justifyContent={'end'} spacing={2} paddingX={5} my={5} pt={3}>
                {tripSchedule || (tripSchedule?.trip && tripSchedule?.trip.status.toLowerCase() != 'completed') ? (
                    tripSchedule?.trip && tripSchedule?.trip.status.toLowerCase() == 'completed' ? (
                        <></>
                    ) : (
                        <Button
                            style={{ borderRadius: 10 }}
                            disableElevation
                            // disabled={isSubmitting}
                            // fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                setModalAction(!tripSchedule?.trip ? 'start' : 'complete');
                                handleOpen();
                            }}
                        >
                            {/* {tripSchedule?.status.toLowerCase() == 'scheduled' ? 'Start Trip' : 'Complete Trip'} */}
                            {!tripSchedule?.trip ? 'Start Trip' : 'Complete Trip'}
                        </Button>
                    )
                ) : (
                    <></>
                )}
            </Grid>
        </Grid>
    );
};

export default DriverSingleTrip;
