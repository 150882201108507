function Storage() {
    this.save = (key, value) => {
        return localStorage.setItem(key, value);
    };
    this.get = (key) => {
        return localStorage.getItem(key);
    };
    this.remove = (key) => {
        return localStorage.removeItem(key);
    };
}

const storage = new Storage();
export { storage };
