import { Typography, Button, Grid, TextField, Accordion, AccordionDetails, AccordionSummary, CardContent } from '@mui/material';
import { IconArrowDown, IconMenu, IconUser } from '@tabler/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import LandingTop from './component/LandingTop';
import landingImageOne from '../../assets/images/landing_image_1.svg';
import landingMakeBooking from '../../assets/images/landing_make_book.svg';
import landingAvailableBuss from '../../assets/images/landing_available_busses.svg';
import landingDepaturePoint from '../../assets/images/landing_depature_point.svg';
import newsIm1 from '../../assets/images/benefits_image.png';
import TripItemCard from 'ui-component/cards/TripItem';
import { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import LandingFooter from './component/LandingFooter';
import { TripService } from 'services/trip_service';
import { useEffect } from 'react';

const LandingPage = () => {
    const sampleTrip = [
        {
            from: 'Kano',
            to: 'Kaduna',
            date: '12 Apr',
            time: '09:00AM',
            customer: 'Shehu Musa',
            rating: 5
        },
        {
            from: 'Jigawa',
            to: 'Kaduna',
            date: '22 Mar',
            time: '06:00AM',
            customer: 'IB Musa',
            rating: 3
        },
        {
            from: 'Kano',
            to: 'Adamawa',
            date: '16 Jan',
            time: '07:30AM',
            customer: 'Abdul Abba',
            rating: 5
        },
        {
            from: 'Jigawa',
            to: 'Gombe',
            date: '09 Feb',
            time: '09:00AM',
            customer: 'Musa Mamman',
            rating: 2
        },
        {
            from: 'Kano',
            to: 'Kaduna',
            date: '12 Apr',
            time: '09:00AM',
            customer: 'Shehu Musa',
            rating: 5
        },
        {
            from: 'Jigawa',
            to: 'Kaduna',
            date: '22 Mar',
            time: '06:00AM',
            customer: 'IB Musa',
            rating: 3
        },
        {
            from: 'Kano',
            to: 'Adamawa',
            date: '16 Jan',
            time: '07:30AM',
            customer: 'Abdul Abba',
            rating: 5
        },
        {
            from: 'Jigawa',
            to: 'Gombe',
            date: '09 Feb',
            time: '09:00AM',
            customer: 'Musa Mamman',
            rating: 2
        }
    ];
    const [expanded, setExpanded] = useState('panel1');
    const [takingAction, setTakingAction] = useState(false);
    const [schedules, setSchedules] = useState([]);
    const [params, setParams] = useState({ page: 1 });
    const [pagination, setPagination] = useState({
        page: 1,
        pages: 1,
        prev: 0,
        next: 0,
        total: 1
    });

    useEffect(() => {
        fetchSchedules(params);
    }, []);

    const fetchSchedules = async (params) => {
        setTakingAction(true);
        const scheduleResponse = await TripService.schedules(params);
        setTakingAction();
        if (!scheduleResponse.success) {
            return;
        }

        setSchedules(scheduleResponse.data?.schedules ?? []);
        // setPagination(scheduleResponse.pagination);
    };
    return (
        <div className="landingBg">
            <div className="landingContainer">
                <LandingTop />
                <Grid container py={5}>
                    <Grid item xs={12} md={4} p={5}>
                        <Typography color={'white'} gutterBottom fontWeight={'normal'} fontSize={50}>
                            Book Private Trips <font color={'#286BCE'}>Easily</font>
                        </Typography>
                        <Typography color={'grey'} gutterBottom fontWeight={'normal'} fontSize={16}>
                            We offer fast and reliable means of private transportation where you have the flexibility to decide where to go
                            and what vehicle to be in. Dive in and get started
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8} align="right">
                        <img src={landingImageOne} alt="" />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2} my={2}>
                            <Grid item xs={6} align={'right'}>
                                <Button
                                    style={{ borderRadius: 20, paddingRight: 30, paddingLeft: 30 }}
                                    disableElevation
                                    // disabled={isSubmitting}
                                    // fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    // onClick={() => setStep(2)}
                                >
                                    Become a Driver &rarr;
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    style={{ borderRadius: 20, paddingRight: 30, paddingLeft: 30 }}
                                    disableElevation
                                    // disabled={isSubmitting}
                                    // fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    // onClick={() => setStep(2)}
                                >
                                    Become a Traveler &rarr;
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Grid container px={5} mx={5} mt={5} mb={5} direction={'column'}>
                <Grid>
                    <Typography color={'black'} gutterBottom fontWeight={'bold'} fontSize={20}>
                        Find Trips
                    </Typography>
                </Grid>
                <Grid>
                    <TextField placeholder="Search for a trip" onChange={(e) => setParams({ ...params, search: e.target.value })} />
                    <Button
                        style={{ borderRadius: 20, margin: 3, paddingRight: 30, paddingLeft: 30, marginLeft: 10 }}
                        ml={2}
                        disableElevation
                        disabled={takingAction}
                        // fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="secondary"
                        onClick={() => fetchSchedules(params)}
                    >
                        {takingAction ? 'Loading...' : 'Search'}
                    </Button>
                </Grid>
                <Grid container mt={3} _paddingX={10} sx={{ px: { md: 10 } }}>
                    <Typography color={'grey'} gutterBottom fontWeight={'normal'} fontSize={13}>
                        Popular Destinations
                    </Typography>

                    {/* <Typography color={'grey'} gutterBottom fontWeight={'normal'} fontSize={13}>
                        FILTER HERE
                    </Typography> */}
                    <Grid container spacing={3} mt={3} alignItems="center">
                        {takingAction ? (
                            <Grid container align={'center'}>
                                <h3>Fetching...</h3>
                            </Grid>
                        ) : (
                            schedules.map((item, key) => {
                                return (
                                    <Grid item lg={3} md={3} sm={6} xs={12} mb={3}>
                                        <TripItemCard data={item} />
                                    </Grid>
                                );
                            })
                        )}
                    </Grid>
                    <Grid container spacing={5} mt={3} alignItems="center">
                        {[
                            {
                                title: 'Make bookings at your comfort',
                                description: 'Book trips at  your own comfort at any given time of yours',
                                image: landingMakeBooking
                            },
                            {
                                title: 'Browse list of available buses',
                                description: 'Check available vehicles for your journey to and fro',
                                image: landingAvailableBuss
                            },
                            {
                                title: 'View list of  departure points',
                                description: 'This includes details such as the departure and destination pointsand time of journey',
                                image: landingDepaturePoint
                            }
                        ].map((item, key) => {
                            return (
                                <Grid item lg={4} md={4} xs={12} mb={3} px={1} align={'center'} sx={{ px: { md: 10 } }}>
                                    <Grid>
                                        <img src={item?.image} alt="" className="landingItemImage" />
                                    </Grid>
                                    <Grid>
                                        <Typography color={'black'} gutterBottom fontWeight={'bold'} fontSize={22}>
                                            {item?.title}
                                        </Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography color={'grey'} gutterBottom fontWeight={'normal'} fontSize={13}>
                                            {item?.description}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
            <div className="benefitSection">
                <Grid container my={3}>
                    <Grid item xs={12} align="center">
                        <Typography color={'white'} align="center" gutterBottom fontWeight={'bold'} fontSize={22}>
                            Benefits of Private Traveler
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ px: { md: 15 } }}>
                        <ul>
                            <li>
                                <Typography color={'white'} align="left" gutterBottom fontWeight={'normal'} fontSize={16} mb={3}>
                                    Facilitate safe and convinient travel for private individuals within Nigeria
                                </Typography>
                            </li>
                            <li>
                                <Typography color={'white'} align="left" gutterBottom fontWeight={'normal'} fontSize={16} mb={3}>
                                    Make bookings at your comfort to ease travelling
                                </Typography>
                            </li>
                            <li>
                                <Typography color={'white'} align="left" gutterBottom fontWeight={'normal'} fontSize={16} mb={3}>
                                    Make bookings at your comfort to ease travelling
                                </Typography>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ px: { md: 15 } }}>
                        <ul>
                            <li>
                                <Typography color={'white'} align="left" gutterBottom fontWeight={'normal'} fontSize={16} mb={3}>
                                    Facilitate safe and convinient travel for private individuals within Nigeria
                                </Typography>
                            </li>
                            <li>
                                <Typography color={'white'} align="left" gutterBottom fontWeight={'normal'} fontSize={16} mb={3}>
                                    Make bookings at your comfort to ease travelling
                                </Typography>
                            </li>
                            <li>
                                <Typography color={'white'} align="left" gutterBottom fontWeight={'normal'} fontSize={16} mb={3}>
                                    Make bookings at your comfort to ease travelling
                                </Typography>
                            </li>
                        </ul>
                    </Grid>
                </Grid>
            </div>
            <Grid container sx={{ px: { md: 15 }, mt: { md: 15, xs: 10 } }}>
                <Grid align="center" container justifyContent={'center'}>
                    <Typography color={'black'} align="center" gutterBottom fontWeight={'bold'} fontSize={22}>
                        Booking is made easier with a click of a button
                    </Typography>
                </Grid>
                <Grid align="center" container justifyContent={'center'}>
                    <Typography color={'grey'} align="center" gutterBottom fontWeight={'normal'} fontSize={18}>
                        Sign up with Private Traveler today and make your trip a safe and convenient one
                    </Typography>
                </Grid>
                <Grid container align="center" justifyContent={'center'} mt={2}>
                    <Button
                        style={{ borderRadius: 20, margin: 3, paddingRight: 30, paddingLeft: 30 }}
                        disableElevation
                        // disabled={isSubmitting}
                        // fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="secondary"
                    >
                        Become a Traveler &nbsp; &rarr;
                    </Button>
                </Grid>
            </Grid>

            <Grid container sx={{ px: { md: 15 }, mt: { md: 10, xs: 10 } }}>
                <Grid item xs={12} align="center" container justifyContent={'center'}>
                    <Typography color={'black'} align="center" gutterBottom fontWeight={'bold'} fontSize={30}>
                        News & FAQ
                    </Typography>
                </Grid>
                <Grid item md={6} p={3}>
                    <Grid container spacing={2} direction={'row'}>
                        {[1, 2].map((item) => (
                            <Grid item md={6}>
                                <div className="newsCard">
                                    <img src={newsIm1} alt="" />
                                    <Grid p={1}>
                                        <Typography fontSize={12} fontWeight={'normal'} color={'#286BCE'} mb={1}>
                                            23rd April 2024
                                        </Typography>
                                        <Typography sx={{ width: '100%', flexShrink: 0 }} fontSize={18} fontWeight={'bold'} color={'black'}>
                                            The Birth Of Private Traveler App
                                        </Typography>
                                        <Typography mt={1} fontSize={14} fontWeight={'normal'} color={'grey'}>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis congue sagittis ligula, ut porta…
                                        </Typography>
                                    </Grid>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid item md={6}>
                    <Accordion expanded={expanded === 'panel1'} onChange={() => setExpanded('panel1')}>
                        <AccordionSummary expandIcon={<IconArrowDown />} aria-controls="panel1bh-content" id="panel1bh-header">
                            <Typography sx={{ width: '100%', flexShrink: 0 }} fontSize={22} fontWeight={'bold'} color={'black'}>
                                How to Book A Ride?
                            </Typography>
                            {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
                                dolore magna aliquyam erat, sed diam voluptua.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={() => setExpanded('panel2')}>
                        <AccordionSummary expandIcon={<IconArrowDown />} aria-controls="panel1bh-content" id="panel1bh-header">
                            <Typography sx={{ width: '100%', flexShrink: 0 }} fontSize={22} fontWeight={'bold'} color={'black'}>
                                How to Download Our App?
                            </Typography>
                            {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
                                dolore magna aliquyam erat, sed diam voluptua.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={() => setExpanded('panel3')}>
                        <AccordionSummary expandIcon={<IconArrowDown />} aria-controls="panel1bh-content" id="panel1bh-header">
                            <Typography sx={{ width: '100%', flexShrink: 0 }} fontSize={22} fontWeight={'bold'} color={'black'}>
                                How to Contact Us?
                            </Typography>
                            {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
                                dolore magna aliquyam erat, sed diam voluptua.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel4'} onChange={() => setExpanded('panel4')}>
                        <AccordionSummary expandIcon={<IconArrowDown />} aria-controls="panel1bh-content" id="panel1bh-header">
                            <Typography sx={{ width: '100%', flexShrink: 0 }} fontSize={22} fontWeight={'bold'} color={'black'}>
                                How To Create A Car Owner Account
                            </Typography>
                            {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
                                dolore magna aliquyam erat, sed diam voluptua.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel5'} onChange={() => setExpanded('panel5')}>
                        <AccordionSummary expandIcon={<IconArrowDown />} aria-controls="panel1bh-content" id="panel1bh-header">
                            <Typography sx={{ width: '100%', flexShrink: 0 }} fontSize={22} fontWeight={'bold'} color={'black'}>
                                What we have to offer
                            </Typography>
                            {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
                                dolore magna aliquyam erat, sed diam voluptua.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>

            <Grid container sx={{ px: { md: 35 }, mt: { md: 10, xs: 10 } }}>
                <Grid>
                    <Typography mb={2} sx={{ width: '100%', flexShrink: 0 }} fontSize={20} fontWeight={'normal'} color={'black'}>
                        Tell Us something
                    </Typography>
                </Grid>
                <div className="newsCard">
                    <Grid container p={3}>
                        <Grid item xs={12} md={6} p={2}>
                            <TextField fullWidth placeholder="Name" />
                        </Grid>
                        <Grid item xs={12} md={6} p={2}>
                            <TextField fullWidth placeholder="email" />
                        </Grid>
                        <Grid item xs={12} md={12} p={2}>
                            <TextField fullWidth placeholder="Type your message" multiline rows={5} />
                        </Grid>
                        <Grid item xs={12} md={12} p={2} align="right">
                            <Button
                                style={{ borderRadius: 20, margin: 3, paddingRight: 30, paddingLeft: 30 }}
                                disableElevation
                                // disabled={isSubmitting}
                                // fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                            >
                                Send message
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid container mt={5}>
                <LandingFooter />
            </Grid>
        </div>
    );
};

export default LandingPage;
