import { Typography, Button, Grid, TextField, Accordion, AccordionDetails, AccordionSummary, CardContent } from '@mui/material';
import { IconArrowDown, IconGps, IconLocation, IconMail, IconMenu, IconPhone, IconUser } from '@tabler/icons';
import React from 'react';
import appStoreImage from '../../../assets/images/appstore.png';
import playStoreImage from '../../../assets/images/playstore.png';
import TripItemCard from 'ui-component/cards/TripItem';
import { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';

const LandingFooter = () => {
    return (
        <div className="landingFooter">
            <Grid container spacing={5} sx={{ px: { md: 10, xs: 5 } }} py={5}>
                <Grid item lg={4} md={4} xs={12} mb={3} px={1} align={'center'} sx={{ px: { md: 10 } }}>
                    <Grid>
                        <Typography color={'white'} gutterBottom fontWeight={'bold'} fontSize={22}>
                            Download Our App
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography color={'grey'} gutterBottom fontWeight={'normal'} fontSize={13}>
                            Download our app on your mobile platforms today
                        </Typography>
                    </Grid>
                    <Grid mt={2}>
                        <img src={appStoreImage} alt="" />
                    </Grid>
                    <Grid mt={1}>
                        <img src={playStoreImage} alt="" />
                    </Grid>
                </Grid>
                <Grid item lg={4} md={4} xs={12} mb={3} px={1} align={'center'} sx={{ px: { md: 10 } }}>
                    <Grid>
                        <Typography color={'white'} gutterBottom fontWeight={'bold'} fontSize={22}>
                            Newsletter
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography color={'grey'} gutterBottom fontWeight={'normal'} fontSize={13}>
                            Enter your email address here to subscribe to our newsletter
                        </Typography>
                    </Grid>
                    <Grid>
                        <TextField placeholder="" />
                    </Grid>
                </Grid>
                <Grid item lg={4} md={4} xs={12} mb={3} px={1} align={'center'} sx={{ px: { md: 10 } }}>
                    <Grid>
                        <Typography color={'white'} gutterBottom fontWeight={'bold'} fontSize={22}>
                            Contact Us
                        </Typography>
                    </Grid>
                    <Grid container spacing={1} justifyContent={'center'} mb={1}>
                        <Grid item>
                            <IconGps color="grey" size={20} />
                        </Grid>
                        <Grid item>
                            <Typography color={'grey'} gutterBottom fontWeight={'normal'} fontSize={13}>
                                Low cost, Bwari Abuja, Nigeria
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} justifyContent={'center'} mb={1}>
                        <Grid item>
                            <IconPhone color="grey" size={20} />
                        </Grid>
                        <Grid item>
                            <Typography color={'grey'} gutterBottom fontWeight={'normal'} fontSize={13}>
                                234 802 488 932
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} justifyContent={'center'} mb={1}>
                        <Grid item>
                            <IconMail color="grey" size={20} />
                        </Grid>
                        <Grid item>
                            <Typography color={'grey'} gutterBottom fontWeight={'normal'} fontSize={13}>
                                Privatetraveler@gmail.com
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div style={{ backgroundColor: 'black' }}>
                <Grid p={3}>
                    <Typography color={'white'} gutterBottom fontWeight={'normal'} align="center" fontSize={16}>
                        Copyright &nbsp;&nbsp;&nbsp; 2024 Company. All right resevered
                    </Typography>
                </Grid>
            </div>
        </div>
    );
};

export default LandingFooter;
