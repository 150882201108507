import axios from 'axios';
import { baseUrl } from 'constants/constants';

const token = localStorage.getItem('token');
const prefix = 'admin';
let AdminSettingsService = {
    newLocation: async (data) => {
        try {
            const resp = await axios.post(baseUrl + `/${prefix}/locations`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            // console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Succesful',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    getAllLocations: async (data) => {
        try {
            const resp = await axios.get(baseUrl + `/${prefix}/locations`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Succesful',
                data: resp?.data?.data,
                pagination: resp?.data?.pagination,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    getAll: async (data) => {
        try {
            const resp = await axios.get(baseUrl + `/${prefix}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Succesful',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    getSingle: async (id) => {
        try {
            const resp = await axios.get(baseUrl + `/${prefix}/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Succesful',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    update: async (id, data) => {
        try {
            const resp = await axios.put(baseUrl + `/${prefix}/${id}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Succesful',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    createRole: async (data) => {
        try {
            const resp = await axios.post(baseUrl + `/${prefix}/config/roles`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Succesful',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    allPermissins: async (data) => {
        try {
            const resp = await axios.get(baseUrl + `/${prefix}/config/permissions`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            // console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Succesful',
                data: resp?.data?.data,
                pagination: resp?.data?.pagination,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    allRoles: async (data) => {
        try {
            const resp = await axios.get(baseUrl + `/${prefix}/config/roles`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            // console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Succesful',
                data: resp?.data?.data,
                pagination: resp?.data?.pagination,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    updateRole: async (id, data) => {
        try {
            const resp = await axios.put(baseUrl + `/${prefix}/config/roles/${id}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Succesful',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    deleteRole: async (id) => {
        try {
            const resp = await axios.delete(baseUrl + `/${prefix}/config/roles/${id}/permissions/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Succesful',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    }
};

export { AdminSettingsService };
