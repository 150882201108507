// assets
import { IconDashboard, IconWallet, IconCar } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconWallet, IconCar };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
// TODO sidenav items list
const prefix = '/traveller';
const travellerMenu = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: `${prefix}`,
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'my-trips',
            title: 'My Trips',
            type: 'item',
            url: prefix + '/my-trips',
            icon: icons.IconCar,
            breadcrumbs: false
        },
        {
            id: 'wallet',
            title: 'Wallet',
            type: 'item',
            url: prefix + '/wallet',
            icon: icons.IconWallet,
            breadcrumbs: false
        }
    ]
};

export default travellerMenu;
