import axios from 'axios';
import { util } from 'constants/api.constant';
import { baseUrl } from 'constants/constants';

// const token = localStorage.getItem('token')
let AuthService = {
    login: async (data) => {
        // let data = JSON.stringify(body);
        try {
            const resp = await axios.post(baseUrl + '/login', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successufl',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    signup: async (data) => {
        // let data = JSON.stringify(body);
        // alert(JSON.stringify(data));
        try {
            const resp = await axios.post(baseUrl + '/signup', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successufl',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    verifyEmail: async (data) => {
        const utility = await util;
        const token = localStorage.getItem('authToken');
        try {
            const resp = await axios.post(baseUrl + '/verify-email', data, {
                headers:
                    //  {
                    //     ...utility?.getAuthorizedOnboardingHeaders()
                    //     // 'Content-Type': 'application/json',
                    //     // Authorization:
                    //     //     'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2OWQ5YzE5YTc2MDQ0ZmVkNzg3MDhmNiIsImlhdCI6MTcyMTYwNTE0NzY5NywiZXhwIjoxNzIxNjA1MjM0MDk3fQ.bifGJTmqc6NZAILMLJEUpz_BYbzFPJBxV7CNjWZqgFA'
                    // }
                    {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successufl',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    resendOtp: async (data) => {
        const token = localStorage.getItem('authToken');
        try {
            const resp = await axios.post(baseUrl + '/resend-otp', data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successufl',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    verifyIdentity: async (data) => {
        const utility = await util;
        const token = localStorage.getItem('authToken');
        try {
            const resp = await axios.post(baseUrl + '/verify-nin', data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successufl',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    setProfilePic: async (data) => {
        const token = localStorage.getItem('authToken');
        try {
            const resp = await axios.post(baseUrl + '/upload-picture', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successufl',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    setPin: async (data) => {
        const token = localStorage.getItem('authToken');
        try {
            const resp = await axios.put(baseUrl + '/set-pin', data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successufl',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    uploadDriverLicense: async (data) => {
        const token = localStorage.getItem('authToken');
        try {
            const resp = await axios.post(baseUrl + '/driver/upload-license', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successufl',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    },
    addVehicle: async (data) => {
        const token = localStorage.getItem('authToken');
        try {
            const resp = await axios.post(baseUrl + '/driver/add-vehicle', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(resp);

            return {
                success: true,
                message: resp?.data?.message ?? 'Successufl',
                data: resp?.data?.data,
                token: resp?.data?.token
            };
        } catch (e) {
            return {
                success: false,
                message: e?.response?.data?.message ?? e?.message
            };
        }
    }
};

export { AuthService };
