import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
// import accountReducer from '../state/slices/account_slice';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer
    // account: accountReducer
});

export default reducer;
