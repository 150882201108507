import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Divider, Grid, Stack, TextField, Typography, useMediaQuery } from '@mui/material';

// project imports
import AuthWrapper1 from '../AuthWrapper1';
import AuthCardWrapper from '../AuthCardWrapper';
import AuthLogin from '../auth-forms/AuthLogin';
import SuccessIcon from '../../../../assets/images/icons/success_icon.svg';

// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const SuccessRegistration = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const navigate = useNavigate();
    return (
        <div>
            <Grid item xs={12} md={6}>
                <Grid
                    direction="column"
                    container
                    spacing={2}
                    alignItems="center"
                    style={{ height: '100vh' }}
                    justifyContent="center"
                    // sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}
                >
                    <Grid>
                        <Typography color={'black'} gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
                            You are all set
                        </Typography>
                    </Grid>
                    <Grid mt={3}>
                        <img src={SuccessIcon} alt="" style={{ height: 120 }} />
                    </Grid>
                    <Grid pt={5}>
                        <Button
                            style={{ borderRadius: 20, paddingRight: 100, paddingLeft: 100 }}
                            disableElevation
                            // disabled={isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                localStorage.removeItem('authLogin');
                                navigate('/login');
                            }}
                        >
                            Finish
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default SuccessRegistration;
