import adminMenu from './adminMenu';
import adminOther from './adminOther';
import driverMenu from './driverMenu';
import driverOther from './driverOther';
import travellerMenu from './travellerMenu';
import travellerOther from './travellerOther';

// ==============================|| MENU ITEMS ||============================== //
const menuItems = {
    items: [], // accountType == 'traveller' ? [travellerMenu, travellerOther] : [driverMenu, driverOther], // utilities, other]
    traveller: [travellerMenu, travellerOther],
    driver: [driverMenu, driverOther], // utilities, other]
    admin: [adminMenu, adminOther] // utilities, other]
};

export default menuItems;
