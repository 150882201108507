import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Divider, Grid, Stack, TextField, Typography, useMediaQuery } from '@mui/material';

// project imports
import OTPInput from 'ui-component/OtpField';
import { useState } from 'react';
import { AuthService } from 'services/auth_service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const SetPin = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const [verifiData, setVerifiData] = useState({ pin: '' });
    const [submitting, setSubmitting] = useState(false);

    const handleVerify = async () => {
        if (verifiData?.pin == '') {
            toast.error('Fields cannot be empty');
            return;
        }
        if (verifiData?.pin.length < 4) {
            toast.error('Pin must b 4 in length');
            return;
        }
        setSubmitting(true);
        const verResponse = await AuthService.setPin(verifiData);
        setSubmitting(false);
        if (!verResponse.success) {
            toast.error(verResponse.message);
            return;
        }
        toast.success(verResponse.message);
        navigate('/success-registration');
    };
    return (
        <div>
            <ToastContainer />
            <Grid item xs={12} md={6}>
                <Grid
                    direction="column"
                    container
                    spacing={2}
                    style={{ height: '100vh' }}
                    alignItems="center"
                    justifyContent="center"
                    // sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}
                >
                    <Grid>
                        <Typography color={theme.palette.secondary.main} gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
                            Please enter transaction pin
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography
                            variant="caption"
                            color={theme.palette.secondary.main}
                            fontSize="16px"
                            textAlign={matchDownSM ? 'center' : 'inherit'}
                        >
                            This pin would be used for transactions through your wallet
                        </Typography>
                    </Grid>
                    <Grid mt={2}>
                        <Typography variant="caption" fontSize="16px" fontWeight={'bold'} textAlign={matchDownSM ? 'center' : 'inherit'}>
                            Transaction pin
                        </Typography>
                    </Grid>
                    <Grid mt={1}>
                        {/* <TextField fullWidth label="fullWidth" id="fullWidth" /> */}
                        <OTPInput length={4} onChange={(v, i) => setVerifiData({ ...verifiData, pin: v })} />
                    </Grid>
                    <Grid pt={5}>
                        <Button
                            style={{ borderRadius: 10, paddingRight: 100, paddingLeft: 100 }}
                            disableElevation
                            disabled={submitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="secondary"
                            onClick={handleVerify}
                        >
                            {submitting ? 'Updating' : 'Next'}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default SetPin;
