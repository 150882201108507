// assets
import { IconDashboard, IconWallet, IconCar, IconCalendar, IconUser, IconUsers, IconLocation, IconReportMoney } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconWallet, IconCar, IconUsers, IconUser, IconCalendar, IconLocation, IconReportMoney };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
// TODO sidenav items list
const prefix = '/admin';
const adminMenu = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: `${prefix}`,
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'drivers',
            title: 'Drivers',
            type: 'item',
            url: prefix + '/drivers',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
        {
            id: 'admins',
            title: 'Admins',
            type: 'item',
            url: prefix + '/admins',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        {
            id: 'transactions',
            title: 'Transactions',
            type: 'item',
            url: prefix + '/transactions',
            icon: icons.IconReportMoney,
            breadcrumbs: false
        }
        // {
        //     id: 'dirver-request',
        //     title: 'Driver Requests',
        //     type: 'item',
        //     url: prefix + '/driver-requests ',
        //     icon: icons.IconUser,
        //     breadcrumbs: false
        // }
        // {
        //     id: 'locations',
        //     title: 'Locations',
        //     type: 'item',
        //     url: prefix + '/locations ',
        //     icon: icons.IconLocation,
        //     breadcrumbs: false
        // }
    ]
};

export default adminMenu;
