import { useEffect, useState } from 'react';

// material-ui
import {
    Button,
    Grid,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Paper,
    Table,
    Modal,
    Box,
    Pagination,
    Select,
    MenuItem,
    Switch,
    FormControl,
    InputLabel
} from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
// ==============================|| DEFAULT USERDASHBOARD ||============================== //
import approveIcon from '../../../assets/images/approve_icon.svg';
import rejectIcon from '../../../assets/images/reject_icon.svg';
import samplePic from '../../../assets/images/sample_pic.png';
import { Stack } from '@mui/system';
import { Link } from 'react-router-dom';
import { IconEdit, IconRecycle, IconTrash } from '@tabler/icons';
import { AdminSettingsService } from 'services/admin/settings_service';
import { ToastContainer, toast } from 'react-toastify';
const Roles = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: '2px solid #fff',
        borderRadius: 4,
        boxShadow: 24,
        p: 2
    };
    const [modalAction, setModalAction] = useState('create');
    const [open, setOpen] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [takingAcion, setTakingAction] = useState(false);
    const [selectedRole, setSelectedRole] = useState({});
    const [roles, setRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [rolePayload, setRolePayload] = useState({
        name: '',
        permissions: []
    });
    const [params, setParams] = useState({
        page: 1
    });
    const [pagination, setPagination] = useState({
        page: 1,
        pages: 1,
        prev: 0,
        next: 0,
        total: 1
    });
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setModalAction('');
        setOpen(false);
    };
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
        getPermissions();
        getRoles(params);
    }, []);

    const getRoles = async (params) => {
        setFetching(true);
        const rolesResponse = await AdminSettingsService.allRoles(params);
        setFetching(false);
        if (!rolesResponse.success) {
            return;
        }
        setRoles(rolesResponse.data);
        setPagination(rolesResponse.pagination);
    };
    const getPermissions = async (params) => {
        // setFetching(true);
        const rolesResponse = await AdminSettingsService.allPermissins(params);
        // setFetching(false);
        if (!rolesResponse.success) {
            return;
        }
        setPermissions(rolesResponse.data);
        // setPagination(rolesResponse.pagination);
    };

    const newRole = async () => {
        // alert(JSON.stringify(rolePayload));
        if (rolePayload.name == '' || rolePayload.permission.length == 0) {
            toast.error('Fields Cannot be empty');
            return;
        }
        setTakingAction(true);
        const userResponse = await AdminSettingsService.createRole(rolePayload);
        setTakingAction(false);
        if (!userResponse.success) {
            toast.error(userResponse.message);
            return;
        }
        toast.success(userResponse.message);
        getRoles(params);
        handleClose();
    };
    const updateRole = async () => {
        setTakingAction(true);
        const userResponse = await AdminSettingsService.updateRole(selectedRole?._id, selectedRole);
        setTakingAction(false);
        if (!userResponse.success) {
            toast.error(userResponse.message);
            return;
        }
        toast.success(userResponse.message);
        getRoles(params);
        handleClose();
    };

    const deleteRole = async () => {
        setTakingAction(true);
        const userResponse = await AdminSettingsService.deleteRole(selectedRole?._id);
        setTakingAction(false);
        if (!userResponse.success) {
            toast.error(userResponse.message);
            return;
        }
        toast.success(userResponse.message);
        getRoles(params);
        handleClose();
    };
    return (
        <Grid container spacing={gridSpacing}>
            <ToastContainer />
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                {modalAction == 'create' ? (
                    <Box sx={style}>
                        <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                            Create Role
                        </Typography>

                        <Grid>
                            <Grid mt={5}>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    id="fullWidth"
                                    type="text"
                                    onChange={(e) => setRolePayload({ ...rolePayload, name: e.target.value })}
                                />
                            </Grid>
                            <Grid mt={5}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Permission</InputLabel>
                                    <Select
                                        value={rolePayload.permissions[0]}
                                        label={'Permission'}
                                        fullWidth
                                        onChange={(e) => setRolePayload({ ...rolePayload, permission: [[e.target.value]] })}
                                    >
                                        {permissions.map((val, key) => (
                                            <MenuItem value={val?._id} selected>
                                                {val?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid pt={5} align="center">
                                <Button
                                    style={{ borderRadius: 20, paddingRight: 100, paddingLeft: 100 }}
                                    disableElevation
                                    disabled={takingAcion}
                                    // fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    onClick={newRole}
                                >
                                    {takingAcion ? 'Creating...' : ' Create Role'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                ) : (
                    <Box sx={style}>
                        <Typography id="keep-mounted-modal-title" variant="h2" component="h2">
                            Are you sure you want to {modalAction}?
                        </Typography>

                        <Grid container spacing={1} mt={3}>
                            <Grid pt={5} item xs={6} align="center">
                                <Button
                                    style={{ borderRadius: 20, paddingRight: 100, paddingLeft: 100 }}
                                    disableElevation
                                    disabled={takingAcion}
                                    // fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => (modalAction == 'delete' ? deleteRole() : updateRole())}
                                >
                                    {takingAcion ? 'Taking Action' : 'Yes'}
                                </Button>
                            </Grid>
                            <Grid pt={5} item xs={6} align="center">
                                <Button
                                    style={{ borderRadius: 20, paddingRight: 100, paddingLeft: 100 }}
                                    disableElevation
                                    // disabled={isSubmitting}
                                    // fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => handleClose()}
                                >
                                    No
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </Modal>
            <Grid container spacing={2} mt={5} ml={2} paddingX={1} alignItems="center" justifyContent={'start'}>
                <Grid align="left" container justifyContent="end" alignItems={'center'} my={2} mx={2}>
                    {/* <Grid item xs={4}>
                        <TextField placeholder="Search Stop" />
                    </Grid> */}
                    <Grid item xs={8} align="left">
                        <Typography color={'black'} fontSize={20} gutterBottom align="left">
                            Roles and Permission
                        </Typography>
                    </Grid>
                    <Grid item xs={2} align={'right'}>
                        {/* <Typography
                            color={'blue'}
                            gutterBottom
                            onClick={() => {
                                setModalAction('create');
                                handleOpen();
                            }}
                            className="hover"
                        >
                            Create Permission &nbsp;&nbsp;
                        </Typography> */}
                    </Grid>
                    <Grid item xs={2} align={'right'}>
                        <Typography
                            color={'blue'}
                            gutterBottom
                            onClick={() => {
                                setModalAction('create');
                                handleOpen();
                            }}
                            className="hover"
                        >
                            Create Role
                        </Typography>
                    </Grid>
                </Grid>
                <div className="myContainer">
                    {fetching ? (
                        <Grid container align={'center'}>
                            <h3>Fetching Roles</h3>
                        </Grid>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {/* <TableCell>Image</TableCell> */}
                                        <TableCell>Name</TableCell>
                                        <TableCell>Read</TableCell>
                                        <TableCell>Write</TableCell>
                                        <TableCell align="left">Level</TableCell>
                                        {/* <TableCell align="left">Status</TableCell> */}
                                        <TableCell align="left">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {roles.map((row) => {
                                        return (
                                            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                {/* <TableCell align="left">
                                                <img src={samplePic} alt="" className="tripItemPic" />
                                            </TableCell> */}
                                                <TableCell align="left">{row.name}</TableCell>
                                                <TableCell align="left">
                                                    <Switch onChange={(v) => console.log(v)} />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Switch checked={true} onChange={(v) => console.log(v)} />
                                                </TableCell>
                                                <TableCell align="left">{row.level}</TableCell>
                                                <TableCell component="th" scope="row">
                                                    {/* <IconEdit
                                                        color="skyblue"
                                                        className="hover"
                                                        onClick={() => {
                                                            setModalAction('update');
                                                            setSelectedRole(row);
                                                            handleOpen();
                                                        }}
                                                    />
                                                    &nbsp;&nbsp; */}
                                                    <IconTrash
                                                        color="red"
                                                        className="hover"
                                                        onClick={() => {
                                                            setSelectedRole(row);
                                                            setModalAction('delete');
                                                            handleOpen();
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </div>
                {/* <Grid container mt={3} align={'right'} justifyContent={'end'}>
                    <Stack spacing={2}>
                        <Pagination count={10} color="secondary" />
                    </Stack>
                </Grid> */}
            </Grid>
        </Grid>
    );
};

export default Roles;
